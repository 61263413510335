import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppState } from '@app/store/selectors/app.selectors';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  appState$ = this.store.select(selectAppState);
  toggle: boolean = false;
  session_info: SessionInfo
  @Input() hide_menu = false
  @Input() show_cart = false
  @Input() home_address = "/"

  total_cart_items = 0
  private subscriptions: Subscription[] = []

  constructor(
    private store: Store,
    public coreService: CoreService,
    public functionsService: FunctionsService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))

    this.subscriptions.push(
      this.appState$.subscribe(ls => {
        if (ls.data.cache.alacarte_session && !ls.data.cache.alacarte_session.paid) {
          let total_items = 0
          for (const item of ls.data.cache.alacarte_session.cart.items) {
            total_items += item.quantity
          }
          this.total_cart_items = total_items
        }
      })
    )
  }

  ngOnInit(): void {
    if (!this.home_address) {
      this.home_address = "/"
    }
  }

  toggleHamburger(event: any) {
    this.toggle = !this.toggle;
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e: any) {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (window.pageYOffset > 100) {
        element.classList.add("show");
      } else {
        element.classList.remove("show");
      }
    }
  }

  onActivate() {
    if (!this.functionsService.scrollToTop(".navbar-wrapper")) {
      if (!this.functionsService.scrollToTop(".qr-content-container")) {
        if (!this.functionsService.scrollToTop(".preview-main-wrapper")) {
          this.functionsService.scrollToTop(".sidebar-contents")
        }
      }
    }
  }
}
