import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { QrGeneratorFacilityTypes } from 'shared-library';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { QR_GENERATOR_FACILITY, QR_GENERATOR_FACILITYForm } from '@app/store/models/qr-generator-facility.interface';

@Component({
    selector: 'app-shared-qr-customize-amenities-icon',
    templateUrl: './shared-qr-customize-amenities-icon.component.html',
    styleUrls: ['./shared-qr-customize-amenities-icon.component.scss']
})
export class SharedQrCustomizeAmenitiesIconComponent implements OnInit {


    store: QR_GENERATOR_FACILITY[] = [
        { type: QrGeneratorFacilityTypes.wifi, name: "WiFi" },
        { type: QrGeneratorFacilityTypes.seat, name: "Seats" },
        { type: QrGeneratorFacilityTypes.wheelchair, name: "Disability Friendly" },
        { type: QrGeneratorFacilityTypes.restrooms, name: "Rest Rooms" },
        { type: QrGeneratorFacilityTypes.loadingbay, name: "Loading Bay" },
        { type: QrGeneratorFacilityTypes.pets, name: "Pets Friendly" },
        { type: QrGeneratorFacilityTypes.parking, name: "Parking" },
        { type: QrGeneratorFacilityTypes.train, name: "Train Station" },
        { type: QrGeneratorFacilityTypes.taxi, name: "Taxi" },
        { type: QrGeneratorFacilityTypes.restingarea, name: "Resting Area" },
        { type: QrGeneratorFacilityTypes.coffee, name: "Coffee" },
        { type: QrGeneratorFacilityTypes.bar, name: "Bar" },
        { type: QrGeneratorFacilityTypes.dining, name: "Dining" },
    ]

    facility_types = {
        wifi: QrGeneratorFacilityTypes.wifi,
        seat: QrGeneratorFacilityTypes.seat,
        wheelchair: QrGeneratorFacilityTypes.wheelchair,
        restrooms: QrGeneratorFacilityTypes.restrooms,
        loadingbay: QrGeneratorFacilityTypes.loadingbay,
        pets: QrGeneratorFacilityTypes.pets,
        parking: QrGeneratorFacilityTypes.parking,
        train: QrGeneratorFacilityTypes.train,
        taxi: QrGeneratorFacilityTypes.taxi,
        restingarea: QrGeneratorFacilityTypes.restingarea,
        coffee: QrGeneratorFacilityTypes.coffee,
        bar: QrGeneratorFacilityTypes.bar,
        dining: QrGeneratorFacilityTypes.dining,
    }

    private subscriptions: Subscription[] = []
    actives: QrGeneratorFacilityTypes[] = []

    @Input() data_preview: QR_GENERATOR_FACILITY[] = null // only active ones should be passed
    @Input() data: FormArray<FormGroup<QR_GENERATOR_FACILITYForm>> = null;

    constructor(
        private formbuilder: FormBuilder,
        private functionService: FunctionsService
    ) { }

    ngOnInit(): void {

        if (this.data) {
            for (let index = 0; index < this.data.value.length; index++) {
                this.actives.push(this.data.value[index].type)
            }
        }

        if (this.data_preview) {
            this.actives = this.data_preview.map(dp => dp.type)
        }

        this.actives = this.functionService.array_unique(this.actives)
        this.functionService.initializeTooltip()
    }

    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe())
    }

    findIfActive(type: QrGeneratorFacilityTypes, s: QR_GENERATOR_FACILITY[] = null): QR_GENERATOR_FACILITY {
        s = s || this.store
        return s.find(d => d.type === type)
    }

    pushPop(type: QrGeneratorFacilityTypes) {
        let record = this.store.find(d => d.type === type)
        let active_index = this.data.value.findIndex(d => d.type === type)
        if (active_index > -1) {
            this.data.removeAt(active_index)
            this.actives = this.actives.filter(active => active !== type)
        } else {
            this.data.push(this.formbuilder.group({
                type: [record.type],
                name: [record.name],
            }))
            this.actives.push(type)
        }
    }
}
