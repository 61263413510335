import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UpgradeRequiredViewTypes } from '@app/store/enums/upgrade-required-view-types.enum';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

@Component({
  selector: 'app-upgrade-required',
  templateUrl: './upgrade-required.component.html',
  styleUrls: ['./upgrade-required.component.scss']
})
export class UpgradeRequiredComponent implements OnInit, OnDestroy {

  session_info: SessionInfo
  @Input() inline_class = "my-4";
  private subscriptions: Subscription[] = []
  @Input() view_type = UpgradeRequiredViewTypes.FULL_PAGE;
  @Input() message = "Please upgrade to use this feature";

  view_types = {
    INLINE: UpgradeRequiredViewTypes.INLINE,
    FULL_PAGE: UpgradeRequiredViewTypes.FULL_PAGE
  }

  constructor(
    private router: Router,
    public coreService: CoreService,
    private functionService: FunctionsService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe())
  }

  gotoPricing() {
    this.functionService.closeAllModals()
    this.router.navigateByUrl("/pricing")
  }
}
