<div class="preview-wrapper animated fadeIn" *ngIf="data?.videos_data">

    <div class="top-header" [ngStyle]="{'background-color': data.videos_data.colors.secondary_color}">
        <img loading="lazy" *ngIf="data.videos_data.logo.show;" [src]="data.videos_data.logo.url" height="26"
            class="mb-3">
        <h6 class="mb-0" [ngStyle]="{'color': data.videos_data.colors.text_color}">{{data.videos_data.company_name}}
        </h6>
    </div>

    <div class="preview-body">

        <ng-container *ngFor="let video of data.videos_data.videos">
            <ng-container *ngIf="video.highlight; else noHighlight">
                <div class="preview-header" [ngStyle]="{'background-color': data.videos_data.colors.primary_color}">
                    <div class="youtube-wrapper">
                        <iframe [src]="video.video_embed_url | safeUrl" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div class="youtube-caption">
                        <b
                            [ngStyle]="{'color': data.videos_data.colors.text_color}">{{data.videos_data.headings.main}}</b>
                        <p [ngStyle]="{'color': data.videos_data.colors.text_color}">{{data.videos_data.headings.meta}}
                        </p>
                        <a [href]="functions.forceUrl(data.videos_data.cta.url)" target="_blank"
                            *ngIf="data.videos_data.cta.show" class="btn btn-sm mt-3"
                            [ngStyle]="{'background-color': data.videos_data.colors.secondary_color, 'color': data.videos_data.colors.text_color}">
                            {{data.videos_data.cta.title == 'Custom' ? data.videos_data.cta.custom_title :
                            data.videos_data.cta.title}}
                        </a>
                    </div>
                </div>
            </ng-container>
            <ng-template #noHighlight>
                <div class="video-wrapper">
                    <div class="youtube-wrapper">
                        <iframe [src]="video.video_embed_url | safeUrl" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div class="video-caption">
                        <p [innerHtml]="video.title"></p>
                    </div>
                </div>
            </ng-template>
        </ng-container>


        <!-- Social Media Section -->
        <ng-container *ngIf="data.videos_data.socials.length">
            <app-shared-qr-customize-social-media-links [socials]="data.videos_data.socials"
                [event_socials]="event_socials_obs.asObservable()">
            </app-shared-qr-customize-social-media-links>
        </ng-container>
    </div>
    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.videos_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.videos_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.videos_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.videos_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.videos_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.videos_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>