import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shared-why-choose-us',
    templateUrl: './shared-why-choose-us.component.html',
    styleUrls: ['./shared-why-choose-us.component.scss']
})
export class SharedWhyChooseUsComponent implements OnInit {

    contents = [
        {
            id: "01",
            title: "Unique Digital Branding",
            text: "At BrandQRcodes.com, we're dedicated to helping your brand shine online. Our platform enables you to create QR code pages that reflect your unique style, ensuring your digital presence stands out from the rest. ",
        },
        {
            id: "02",
            title: "Easy-to-Use Interface",
            text: "Our platform is designed with simplicity in mind. You don't need to be a tech expert to create professional and aesthetically pleasing QR code pages. Just a few clicks and you'll have a visually striking landing page accessible via QR code.",
        },
        {
            id: "03",
            title: "Affordable Pricing",
            text: "We understand the importance of affordability. While we offer top-notch features, we also ensure that our pricing is competitive and accessible to businesses of all sizes. And now, with our discount codes, you can save even more!",
        },
        {
            id: "04",
            title: "Versatile Plans",
            text: "Choose from a range of plans that suit your needs, whether you're a startup or an established enterprise. Our Basic and PRO plans offer advanced analytics, collaboration options, and more, so you can tailor your experience to your exact requirements.",
        },
        {
            id: "05",
            title: "Engaging Customer Support",
            text: "We're not just a platform; we're your partners in digital branding. Our dedicated support team is here to assist you every step of the way, providing solutions to your queries and ensuring you have a seamless experience.",
        },
        {
            id: "06",
            title: "Constant Innovation",
            text: "We're committed to staying at the forefront of digital branding. BrandQRcodes.com continuously evolves to offer the latest features and technologies, so your brand can keep up with the ever-changing digital landscape.",
        }
    ]
    constructor() { }

    ngOnInit(): void {
    }

}
