import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, DynamicQrPageThemeType, QrGeneratorSOCIALS, SocialsInterfaceTypes } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { IAppConfig } from '@app/store/models/config.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-qr-preview-vcard-pro',
  templateUrl: './shared-qr-preview-vcard-pro.component.html',
  styleUrls: [
    './shared-qr-preview-vcard-pro.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType1.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType2.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType3.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType4.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType5.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType6.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType7.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType8.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType9.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType10.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType11.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType12.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType13.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType14.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType15.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType16.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType17.component.scss',
    './shared-qr-preview-vcard-pro-ThemeType18.component.scss',
    '../shared-qr-preview.component.scss'
  ]
})
export class SharedQrPreviewVcardProComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  ThemeTypes = {
    ThemeType1: DynamicQrPageThemeType.ThemeType1,
    ThemeType2: DynamicQrPageThemeType.ThemeType2,
    ThemeType3: DynamicQrPageThemeType.ThemeType3,
    ThemeType4: DynamicQrPageThemeType.ThemeType4,
    ThemeType5: DynamicQrPageThemeType.ThemeType5,
    ThemeType6: DynamicQrPageThemeType.ThemeType6,
    ThemeType7: DynamicQrPageThemeType.ThemeType7,
    ThemeType8: DynamicQrPageThemeType.ThemeType8,
    ThemeType9: DynamicQrPageThemeType.ThemeType9,
    ThemeType10: DynamicQrPageThemeType.ThemeType10,
    ThemeType11: DynamicQrPageThemeType.ThemeType11,
    ThemeType12: DynamicQrPageThemeType.ThemeType12,
    ThemeType13: DynamicQrPageThemeType.ThemeType13,
    ThemeType14: DynamicQrPageThemeType.ThemeType14,
    ThemeType15: DynamicQrPageThemeType.ThemeType15,
    ThemeType16: DynamicQrPageThemeType.ThemeType16,
    ThemeType17: DynamicQrPageThemeType.ThemeType17,
    ThemeType18: DynamicQrPageThemeType.ThemeType18,
  }

  private deepAddStyleToQuillOutputCounter: number;
  private last_summary_check = { color: '', summary: '', type: DynamicQrPageThemeType.ThemeType1 };

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    public qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.VCARD_PRO)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.vcardpro_data) {
          this.event_additional_settings_obs.next({
            text_color: d.vcardpro_data.colors.text_color,
            tooltip: ""
          })

          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.vcardpro_data = this.qrGeneratorService.initVCardProCategoryData()
          let social_templates = this.qrGeneratorService.getSocialTemplates()
          this.data.vcardpro_data.designation = "Designer"
          this.data.vcardpro_data.company = "Sarah Degrasse"
          this.data.vcardpro_data.website = "urllist.me/sarah"
          this.data.vcardpro_data.email = "help@brandqrcodes.com"
          this.data.vcardpro_data.mobile = "+0123456789"
          this.data.vcardpro_data.full_name = "Sarah DeGrasse"
          this.data.vcardpro_data.location.address = "San Francisco, CA, USA"
          this.data.vcardpro_data.summary = "I am available for freelance or full-time designer gigs. I have over 4 years of visual design experience in the fintech industry. Open to discussing any opportunities."
          this.data.vcardpro_data.colors.text_color = "#FFFFFF"
          this.data.vcardpro_data.colors.primary_color = "#A5D3C4"
          this.data.vcardpro_data.colors.secondary_color = "#1B5B46"
          this.data.vcardpro_data.logo.show = true
          this.data.vcardpro_data.theme = {
            type: DynamicQrPageThemeType.ThemeType2,
            preview_url: `${environment.app_url_full}assets/qr-generator/vcard-pro-style-2.png`
          };
          let position = 1
          this.data.vcardpro_data.share_on = true
          this.data.vcardpro_data.savable = true
          let type: SocialsInterfaceTypes = SocialsInterfaceTypes.website
          let template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: "Visit us online",
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: this.data.vcardpro_data.website,
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.linkedin
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "Add us on LinkedIn",
            value: "",
            href: "www.linkedin.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.instagram
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: "www.instagram.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.youtube
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: "www.youtube.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.facebook
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: "www.facebook.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.twitter
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: "www.twitter.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.tiktok
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "",
            value: "",
            href: "www.tiktok.com",
            position: position++,
            favicon: template.favicon
          })
          type = SocialsInterfaceTypes.whatsapp
          template = social_templates.find(st => st.type == type)
          this.data.vcardpro_data.socials.push({
            title: template.name,
            type,
            email: "",
            mobile: "",
            name: template.name,
            custom_title: "",
            meta_title: "Text us on WhatsApp",
            value: "",
            href: "www.whatsapp.com",
            position: position++,
            favicon: template.favicon
          })
          setTimeout(() => {
            this.event_socials_obs.next(this.data.vcardpro_data.socials)
          }, 300)
          return
        }
        this.data = d
        if (this.data.vcardpro_data) {
          if (
            this.last_summary_check.type !== this.data.vcardpro_data.theme.type ||
            this.last_summary_check.summary !== this.data.vcardpro_data.summary ||
            this.last_summary_check.color !== this.data.vcardpro_data.colors.text_color
          ) {
            this.last_summary_check.summary = this.data.vcardpro_data.summary
            this.last_summary_check.color = this.data.vcardpro_data.colors.text_color
            this.deepAddStyleToQuillOutput()
          }
          setTimeout(() => {
            this.event_socials_obs.next(this.data.vcardpro_data.socials)
          }, 300)
        }
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  private deepAddStyleToQuillOutput() {
    if (![
      DynamicQrPageThemeType.ThemeType1
    ].includes(this.data.vcardpro_data.theme.type)) {
      clearTimeout(this.deepAddStyleToQuillOutputCounter)
      this.deepAddStyleToQuillOutputCounter = setTimeout(() => {
        const tempDiv = document.getElementById('ql-editor')
        if (tempDiv) {
          let textAlign = [
            DynamicQrPageThemeType.ThemeType4,
            DynamicQrPageThemeType.ThemeType11,
            DynamicQrPageThemeType.ThemeType13,
            DynamicQrPageThemeType.ThemeType14,
            DynamicQrPageThemeType.ThemeType15,
            DynamicQrPageThemeType.ThemeType17,
          ].includes(this.data.vcardpro_data.theme.type) ? 'left' : 'center';

          if ([
            DynamicQrPageThemeType.ThemeType16,
          ].includes(this.data.vcardpro_data.theme.type)) {
            textAlign = 'right';
          }

          const color = [
            DynamicQrPageThemeType.ThemeType4,
            DynamicQrPageThemeType.ThemeType7,
            DynamicQrPageThemeType.ThemeType10,
            DynamicQrPageThemeType.ThemeType14,
          ].includes(this.data.vcardpro_data.theme.type) ? this.data.vcardpro_data.colors.secondary_color : this.data.vcardpro_data.colors.text_color;
          tempDiv.childNodes.forEach((node: HTMLElement) => {

            if (node.nodeType === Node.TEXT_NODE) {
              const parentEl = node.parentNode as HTMLElement;
              parentEl.style.textAlign = textAlign;
              parentEl.style.color = color;
            } else {
              node.style.textAlign = textAlign;
              node.style.color = color;
            }
          });
        }
      })
    }
  }
}
