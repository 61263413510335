import { Store } from '@ngrx/store';
import { AppState } from '@app/store/states/app.states';
import { selectAppState } from '@app/store/selectors/app.selectors';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SharedBounceModalContentTypes } from '@app/store/enums/shared-bounce-modal-content-types';
import { Subscription } from 'rxjs';
import { PromotionalDiscounts } from '@app/store/models/promotional-discounts.interface';

declare const ouibounce: any;

@Component({
    selector: 'app-shared-bounce-modal',
    templateUrl: './shared-bounce-modal.component.html',
    styleUrls: ['./shared-bounce-modal.component.scss']
})
export class SharedBounceModalComponent implements OnInit {

    bounce_modal_props: any;
    @ViewChild('bounce_modal_container') bounce_modal_container: ElementRef;

    @Input() content = SharedBounceModalContentTypes.SIGNUP
    content_types = {
        SIGNUP: SharedBounceModalContentTypes.SIGNUP,
        PROMOTION: SharedBounceModalContentTypes.PROMOTION,
    }

    promo_code: PromotionalDiscounts

    app_state: AppState
    private subscriptions: Subscription[] = []
    appState$ = this.store.select(selectAppState);

    constructor(
        private store: Store,
    ) {
        this.subscriptions.push(this.appState$.subscribe(ls => { this.app_state = ls; this.setContentType() }))
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe())
    }

    private setContentType() {
        if (!this.app_state) return
        if (this.app_state.data.cache.promo_codes.length) {
            this.content = SharedBounceModalContentTypes.PROMOTION
            this.promo_code = this.app_state.data.cache.promo_codes[0]
            return
        }
    }

    ngAfterViewInit() {
        if (this.bounce_modal_container) {
            this.bounce_modal_props = ouibounce(this.bounce_modal_container.nativeElement, { aggressive: true });
            // this.bounce_modal_props.fire()
        }
    }

    closeBounceModal() {
        if (this.bounce_modal_container) { this.bounce_modal_container.nativeElement.style.display = 'none'; }
    }

}
