<div class="preview-wrapper animated fadeIn" *ngIf="data?.long_plain_text_data">
    <img loading="lazy" *ngIf="data.long_plain_text_data.banner_url" [src]="data.long_plain_text_data.banner_url"
        width="100%">

    <div class="preview-body">
        <div class="preview-contents">
            <div [innerHTML]="data.long_plain_text_data.text"></div>
        </div>
    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.long_plain_text_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.long_plain_text_data.share_on">
            <div class="share-button">
                <app-share-to-social [data]="social_data" [text_color]="'white'"></app-share-to-social>
            </div>
        </div>
    </div>
</div>