import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent implements OnInit {

  @Input() square_color = ""
  @Input() type: "default" | "square" = "default"
  @Input() bg_color: "white" | "primary" | "secondary" | "green" | "" = "white"

  square_style = {}

  constructor() { }

  ngOnInit(): void {
    if (this.square_color) {
      this.bg_color = ""
      this.square_style = {
        stroke: this.square_color
      }
    }
  }

}
