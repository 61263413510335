import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-shared-how-it-works',
    templateUrl: './shared-how-it-works.component.html',
    styleUrls: ['./shared-how-it-works.component.scss']
})
export class SharedHowItWorksComponent implements OnInit {

    @Input() content: "discount" | "referral";
    constructor() { }

    ngOnInit(): void {
    }

}
