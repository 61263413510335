import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { PopupGeneratorNavigator } from '@app/store/models/popup-generator-navigator.interface';
import { PopupCategories } from 'shared-library';

@Component({
  selector: 'app-shared-popup-preview-successpage',
  templateUrl: './shared-popup-preview-successpage.component.html',
  styleUrls: ['./shared-popup-preview-successpage.component.scss']
})
export class SharedPopupPreviewSuccesspageComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: PopupGeneratorNavigator
  private subscriptions: Subscription[] = []

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    public readonly qrGeneratorService: UserQrGeneratorService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.session_info) {
        if (this.create_mode) {
          this.data = this.session_info.popup_generating.drafts.find(d => d.selected_category === PopupCategories.SUCCESS_PAGE)
        } else {
          this.data = this.session_info.popup_generating.updating
        }

        if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data.successpage_data = JSON.parse(JSON.stringify(this.qrGeneratorService.initSuccessCategoryData()))
          this.data.successpage_data.headings.main = "Successful!"
          this.data.successpage_data.headings.meta = "Your application was successfully sent."
          this.data.successpage_data.cta.title = "Continue"
          this.data.successpage_data.colors.text_color = "#606060"
          this.data.successpage_data.colors.primary_color = "#FFFFFF"
          this.data.successpage_data.colors.secondary_color = "#23B559"
        }
      }
    }))
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
