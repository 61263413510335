import { Component, Inject, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';
import { QrGeneratorNavigatorCategories, QrGeneratorSOCIALS, today, QrGeneratorFacilityTypes } from 'shared-library';

@Component({
  selector: 'app-shared-qr-preview-event',
  templateUrl: './shared-qr-preview-event.component.html',
  styleUrls: ['./shared-qr-preview-event.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewEventComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.EVENT)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        if (d.event_data) {
          this.event_additional_settings_obs.next({
            text_color: d.event_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }

        this.data = d
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.event_data.headings.main = "Figma meetup, March 2025"
          this.data.event_data.headings.meta = "Join us as we dive into design systems with the BrandQRCodes team. Experienced designers will share how they scaled an internal design system used by 800 collaborators across multiple countries."
          this.data.event_data.event.duration.from = today()
          this.data.event_data.event.duration.to = today()
          this.data.event_data.event.duration.all_day = true
          this.data.event_data.location.address = "Hamburg, Germany"
          this.data.event_data.event.venue = "BRANDQRCODES Co-working space"
          this.data.event_data.event.about = "Guest speakers: Hamayoon Nayel - Head of design, BRANDQRCODES - Liam Chuck - Product Designer, BRANDQRCODES"
          this.data.event_data.event.organizer = "Figma Community, Hamburg"
          this.data.event_data.event.contact.name = "Amir Nanjani"
          this.data.event_data.event.contact.mobile = "+123 456 78"
          this.data.event_data.event.contact.email = "help@brandqrcodes.com"
          this.data.event_data.event.contact.website = "brandqrcodes.com"
          this.data.event_data.share_on = true
          this.data.event_data.facilities = [
            { type: QrGeneratorFacilityTypes.wifi, name: "WiFi" },
            { type: QrGeneratorFacilityTypes.seat, name: "Seats" },
            { type: QrGeneratorFacilityTypes.wheelchair, name: "Disability Friendly" },
            { type: QrGeneratorFacilityTypes.restrooms, name: "Rest Rooms" },
            { type: QrGeneratorFacilityTypes.loadingbay, name: "Loading Bay" },
            { type: QrGeneratorFacilityTypes.pets, name: "Pets Friendly" },
            { type: QrGeneratorFacilityTypes.parking, name: "Parking" },
            { type: QrGeneratorFacilityTypes.train, name: "Train Station" },
            { type: QrGeneratorFacilityTypes.taxi, name: "Taxi" },
            { type: QrGeneratorFacilityTypes.restingarea, name: "Resting Area" },
            { type: QrGeneratorFacilityTypes.coffee, name: "Coffee" },
            { type: QrGeneratorFacilityTypes.dining, name: "Dining" }
          ]
          this.data.event_data.colors.primary_color = "#9b59b6"
          this.data.event_data.colors.secondary_color = "#27ae60"
        }

        if (this.data.event_data)
          setTimeout(() => this.event_socials_obs.next(this.data.event_data.socials), 300)
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
