import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, QrGeneratorSOCIALS } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-ratings',
  templateUrl: './shared-qr-preview-ratings.component.html',
  styleUrls: ['./shared-qr-preview-ratings.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewRatingsComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")
  rating_sent = false

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    public coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.RATING)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.rating_data) {
          this.event_additional_settings_obs.next({
            text_color: d.rating_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.rating_data = this.qrGeneratorService.initRatingCategoryData()
          this.data.rating_data.headings.main = "What do you think of the new croissant flavors?"
          this.data.rating_data.company_name = "Grabben’s Bakery"
          this.data.rating_data.contact.website = "brandqrcodes.com"
          this.event_socials_obs.next(this.data.rating_data.socials)
          return
        }
        this.data = d
        if (this.data.rating_data) {
          setTimeout(() => this.event_socials_obs.next(this.data.rating_data.socials), 300)
        }
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }


}
