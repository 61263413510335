<div class="d-flex flex-row w-100" *ngIf="end_date">
    <div class="pclb-time flex-fill">
        <div>{{ days_left }}</div>
        <small class="text-muted">{{functionService.pluralize('day', +days_left)}}</small>
    </div>
    <div class="pclb-seperator flex-fill">:</div>
    <div class="pclb-time flex-fill">
        <div>{{ hours_left }}</div>
        <small class="text-muted">{{functionService.pluralize('hour', +hours_left)}}</small>
    </div>
    <div class="pclb-seperator flex-fill">:</div>
    <div class="pclb-time flex-fill">
        <div>{{ timeRemaining$ | async | date:'mm' }}</div>
        <small class="text-muted">{{functionService.pluralize('min', +(timeRemaining$ | async | date:'mm'))}}</small>
    </div>
    <div class="pclb-seperator flex-fill">:</div>
    <div class="pclb-time flex-fill">
        <div>{{ timeRemaining$ | async | date:'ss' }}</div>
        <small class="text-muted">{{functionService.pluralize('sec', +(timeRemaining$ | async | date:'ss'))}}</small>
    </div>
</div>