<div class="preview-wrapper animated fadeIn" *ngIf="data?.mp3_data">

    <div class="preview-header" [ngStyle]="{'background-color': data.mp3_data.colors.primary_color}">
        <div class="download-button">
            <i *ngIf="data.mp3_data.mp3.downloadable && data.mp3_data.mp3.url" (click)="downloadAudio()"
                [ngStyle]="{'color': data.mp3_data.colors.text_color}" class="bi bi-download bi-md mouse-pointer">
            </i>
        </div>

        <div class="d-flex flex-row-reverse bd-highlight banner-container">
            <div class="ms-3" *ngIf="data.mp3_data.show_qr_code">
                <div class="share-button" data-bs-custom-class="qr-preview-tooltip" data-bs-toggle="tooltip"
                    data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-html="true"
                    title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                    <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.mp3_data.colors.text_color}"></i>
                </div>
            </div>
            <div *ngIf="data.mp3_data.share_on">
                <div class="share-button">
                    <app-share-to-social [data]="social_data" [text_color]="data.mp3_data.colors.text_color"
                        [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
                </div>
            </div>
        </div>

    </div>
    <div class="preview-body">
        <ng-container *ngIf="!supported_browser; else supportedBrowser">
            <p>Unsupported browser, please switch browser and try again</p>
        </ng-container>
        <ng-template #supportedBrowser>
            <div class="mp3-contents" *ngIf="audio">
                <div class="audio-img">
                    <img loading="lazy" *ngIf="data.mp3_data.mp3.show_thumbnail" [src]="data.mp3_data.mp3.thumbnail"
                        width="100%" class="mb-3">
                </div>
                <b *ngIf="data.mp3_data.headings.main">{{data.mp3_data.headings.main}}</b>
                <small *ngIf="data.mp3_data.headings.meta">{{data.mp3_data.headings.meta}}</small>

                <ng-container *ngIf="data.mp3_data.mp3.url">
                    <div class="progress mt-3">
                        <div class="progress-bar"
                            [ngStyle]="{'width': audio.progress + '%', 'background-color': data.mp3_data.colors.secondary_color}"
                            role="progressbar" [attr.aria-valuenow]="audio.progress" aria-valuemin="0"
                            aria-valuemax="100"></div>
                    </div>
                    <small>{{functions.convertSeconds(audio.duration)}}</small>

                    <div class="text-center mt-3">
                        <span class="mouse-pointer" *ngIf="!audio.playing" (click)="playSound()">
                            <svg viewBox="0 0 486 486" width="50">
                                <g id="Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Welcome" transform="translate(-2027.000000, -2071.000000)"
                                        [attr.fill]="data.mp3_data.colors.secondary_color" fill-rule="nonzero">
                                        <g id="play" transform="translate(2027.000000, 2071.000000)">
                                            <path
                                                d="M242.872,0 C108.732,0 0.004,108.736 0.004,242.864 C0.004,377.004 108.732,485.74 242.872,485.74 C377.008,485.74 485.736,377.004 485.736,242.864 C485.736,108.736 377.008,0 242.872,0 Z M338.412,263.94 L204.052,356.672 C187.276,368.26 173.468,360.92 173.468,340.356 L173.468,145.38 C173.468,124.824 187.276,117.48 204.052,129.068 L338.372,221.8 C355.136,233.384 355.176,252.348 338.412,263.94 Z"
                                                id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>

                        <span class="mouse-pointer" *ngIf="audio.playing" (click)="playSound()">
                            <svg viewBox="0 0 486 486" width="50">
                                <g id="Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Welcome" transform="translate(-2647.000000, -2079.000000)"
                                        [attr.fill]="data.mp3_data.colors.secondary_color" fill-rule="nonzero">
                                        <g id="pause-button" transform="translate(2647.000000, 2079.000000)">
                                            <path
                                                d="M414.845253,71.1664884 C319.94043,-23.7062492 166.083439,-23.738075 71.1786168,71.1664884 C-23.7262056,166.049835 -23.7262056,319.906405 71.1786168,414.821577 C166.062222,509.726141 319.94043,509.726141 414.845253,414.821577 C509.718249,319.917014 509.718249,166.060443 414.845253,71.1664884 Z M212.718986,336.339132 C212.718986,351.817084 200.168974,364.345844 184.701589,364.345844 C169.234203,364.345844 156.6948,351.817084 156.6948,336.339132 L156.6948,149.670151 C156.6948,134.213417 169.234203,121.663439 184.701589,121.663439 C200.168974,121.663439 212.718986,134.213417 212.718986,149.670151 L212.718986,336.339132 L212.718986,336.339132 Z M329.307852,336.339132 C329.307852,351.817084 316.757841,364.345844 301.301064,364.345844 C285.844287,364.345844 273.294275,351.817084 273.294275,336.339132 L273.294275,149.670151 C273.294275,134.213417 285.844287,121.663439 301.301064,121.663439 C316.757841,121.663439 329.307852,134.213417 329.307852,149.670151 L329.307852,336.339132 Z"
                                                id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </ng-container>

                <a class="btn btn-block mt-4" *ngIf="data.mp3_data.cta.show" target="_blank"
                    [href]="functions.forceUrl(data.mp3_data.cta.url)"
                    [ngStyle]="{'border-color': data.mp3_data.colors.secondary_color, 'color': data.mp3_data.colors.secondary_color}">
                    {{data.mp3_data.cta.title == 'Custom' ? data.mp3_data.cta.custom_title : data.mp3_data.cta.title}}
                </a>
            </div>
        </ng-template>

        <div class="text-center mt-3">
            <a [href]="functions.forceUrl(data.mp3_data.website)" target="_blank"
                [ngStyle]="{'color': data.mp3_data.colors.secondary_color}">{{data.mp3_data.website}}</a>
        </div>
    </div>
</div>