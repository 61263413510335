import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { CoreService } from '@app/modules/core/services/core.service';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { IAppConfig } from '@app/store/models/config.interface';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-qr-preview-long-plain-text',
  templateUrl: './shared-qr-preview-long-plain-text.component.html',
  styleUrls: ['./shared-qr-preview-long-plain-text.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewLongPlainTextComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  social_data = this.qrGeneratorService.initSocialHelper()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.long_plain_text_data = this.qrGeneratorService.initLongPlainTextCategoryData()
          this.data.long_plain_text_data.banner_url = `${environment.app_url_full}assets/banner/skin-care.png`,
            this.data.long_plain_text_data.text = `<div><h5>SoLé Veil Illuminating Primer</h5><p><span>Get that lit-from-within glow with SoLé Veil Illuminating Primer!</span></p><p>This luxurious, lightweight primer is infused with light-reflecting pearls that blur imperfections and create a radiant, dewy canvas for your makeup.</p><p><strong>Key Benefits:</strong></p><ul><li><strong>Instant Radiance:</strong> Light-reflecting pearls even skin tone and add a subtle, natural-looking glow.</li><li><strong>Hydrating Formula:</strong> Hyaluronic acid plumps and hydrates skin, leaving it feeling soft and smooth.</li><li><strong>Long-lasting Wear:</strong> Helps your makeup stay put for all-day wear, without feeling heavy or greasy.</li><li><strong>Suitable for All Skin Types:</strong> Oil-free and non-comedogenic formula won't clog pores.</li></ul><p><br></p><p><strong>Available Shades:</strong></p><ul><li><strong>Daylight:</strong> A sheer, universal shade with a pearlescent finish.</li><li><strong>Sunlit:</strong> A warm, golden shade that adds a touch of sunkissed radiance.</li><li><strong>Moonlit:</strong> A cool, pearlescent shade perfect for a dewy, ethereal glow.</li></ul><p><br></p><p><strong>How to Use:</strong></p><p>Apply a thin, even layer to your face after moisturizer and before foundation. Use fingertips or a brush to blend seamlessly.</p><p><span>SoLé Veil Illuminating Primer is the perfect way to achieve a flawless, glowing complexion. Get yours today!</span></p><p><strong>Additional Information:</strong></p><ul><li>Vegan and cruelty-free</li><li>Paraben-free</li><li>Dermatologist-tested</li><li>1 fl oz / 30 ml</li></ul></div>`
          return
        }
        this.data = d
      }

    }))
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }
}
