import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { environment } from 'src/environments/environment';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-pdf',
  templateUrl: './shared-qr-preview-pdf.component.html',
  styleUrls: ['./shared-qr-preview-pdf.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewPdfComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category == QrGeneratorNavigatorCategories.PDF)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        if (d.pdf_data) {
          this.event_additional_settings_obs.next({
            text_color: d.pdf_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.pdf_data.company_name = "BRANDQRCODES"
          this.data.pdf_data.headings.main = "Barker Bakery"
          this.data.pdf_data.website = "brandqrcodes.com"
          this.data.pdf_data.colors.primary_color = "#6F6578"
          this.data.pdf_data.colors.secondary_color = "#FD7606"
          this.data.pdf_data.headings.meta = "Italian sandwiches your mother would serve herself."
          this.data.pdf_data.pdf.url = `${environment.files_url}assets/public/brandqrcodes-com-restaurant-menu-qr-code.pdf`
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
