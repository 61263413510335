import { Component, Input, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { SocialHelper } from '@app/store/models/social-helper.interface';
import { Subscription, Observable } from 'rxjs';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

@Component({
  selector: 'app-share-to-social',
  templateUrl: './share-to-social.component.html',
  styleUrls: ['./share-to-social.component.scss']
})
export class ShareToSocialComponent implements OnInit {

  tooltip: string


  @Input() size = 16
  @Input() _class = ""
  @Input() data: SocialHelper
  @Input() text_color = "#777777"
  is_sharable_by_navigator = false

  private subscriptions: Subscription[] = []
  @Input() event_additional_settings: Observable<SocialHelperAdditionalSettings> = null;

  constructor(
    private functionService: FunctionsService
  ) {
    this.is_sharable_by_navigator = navigator.share ? true : false
  }

  ngOnInit(): void {
    this.data.title = this.data.title ?? ""
    this.tooltip = `Share ${this.data.url}`
    if (this.event_additional_settings) {
      this.subscriptions.push(this.event_additional_settings.subscribe((d) => {
        if (d.text_color)
          this.text_color = d.text_color
        this.tooltip = d.tooltip
      }))
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  async shareWithNavigator() {
    try {
      await navigator.share({
        title: this.data.description,
        text: `${this.data.title} - ${this.data.url}`
      })
    } catch (error) {
      this.functionService.logToConsole(error)
    }
  }

}
