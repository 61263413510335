import * as Highcharts from 'highcharts';
import { countries, PublicUserDetails } from 'shared-library';
import { Observable, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { StatsVisits } from '@app/store/models/stats-visits.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { TeamInterfaceStatsChartAggregate } from '@app/store/models/team-interface-stats-chart-aggregate.interface';

@Component({
  selector: 'app-shared-pie',
  templateUrl: './shared-pie.component.html',
  styleUrls: ['./shared-pie.component.scss']
})
export class SharedPieComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = []

  session_info: SessionInfo

  percentages: VisitPercentage[] = []
  @Input() console_id: string = "link-details-top-";

  sentence_ = "from"
  inited = false

  @Input() version: 'v1' | 'v2' = 'v1';
  @Input() stats_description = "visit";
  link_data: TeamInterfaceStatsChartAggregate[] = []
  @Input() pie_data_type: 'countries' | 'days' | 'devices' | 'os' = 'countries';

  @Input() user_details: PublicUserDetails = null;
  @Input() event_user_details: Observable<PublicUserDetails>;
  @Input() init_link_data: StatsVisits = null
  @Input() event_link_data: Observable<StatsVisits>;

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
    }))
  }

  ngOnInit(): void {
    this.console_id += this.pie_data_type
    if ('days' === this.pie_data_type) {
      this.sentence_ = "on"
    }
    if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
    const statsVisit: StatsVisits = this.init_link_data ? this.init_link_data : this.user_details.current_organization_details.current_team_details.stats.visits
    this.link_data = statsVisit[this.pie_data_type]
    if (this.event_user_details) {
      this.subscriptions.push(this.event_user_details.subscribe(ead => {
        this.user_details = ead
        if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
      }))
    }
    if (this.event_link_data) {
      this.subscriptions.push(this.event_link_data.subscribe(link_data => {
        this.init_link_data = link_data
        if (this.init_link_data) this.link_data = this.init_link_data[this.pie_data_type]
        if (this.inited) {
          this.ngAfterViewInit()
        }
      }))
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!document.getElementById(this.console_id)) {
        return
      }
      this.extractPercentage()
      switch (this.version.toLowerCase()) {
        case 'v1':
          this.initPie()
          break;
        default:
          this.initPieV2()
          break;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  private extractPercentage(): void {
    let merged: VisitPercentage[] = []
    for (let i = 0; i < this.link_data.length; i++) {
      let item_percentage = this.link_data[i]
      let name = item_percentage.key
      if (this.pie_data_type === 'countries') {
        let _finder = countries.find(c => c.country_code.toLowerCase() === name.toLowerCase())
        if (_finder) name = _finder.name
      }
      let tempIndex = merged.findIndex((c) => c.name == name);
      if (tempIndex > -1) {
        merged[tempIndex].total += item_percentage.counter;
      } else {
        merged.push({
          name,
          percentage: 0,
          total: item_percentage.counter,
        });
      }
    }
    let summer = (r: VisitPercentage[]) => {
      let t = 0;
      for (let i = 0; i < r.length; i++) {
        t += r[i].total;
      }
      return t;
    };
    if (merged.length) {
      let total = summer(merged);
      for (let i = 0; i < merged.length; i++) {
        merged[i].percentage = this.functions.percentageCalculatorV2(merged[i].total, total);
      }
    }
    this.percentages = merged.filter(m => m.percentage)
    this.percentages = this.percentages.slice(0, 7)
  }

  private initPie(): void {
    this.inited = true;
    (Highcharts as any).chart(this.console_id, {
      lang: {
        noData: `No recent ${this.stats_description}`,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: 'Lato'
        }
      },
      colors: this.qrGeneratorService.chartColorRangescolors(),
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: `<b>{point.percentage:.1f} %</b> ${this.stats_description}s recorded`,
        padding: 14,
        borderRadius: 20,
        backgroundColor: 'white',
        borderColor: 'none'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: `<b>{point.percentage:.1f} %</b> ${this.stats_description}s ${this.sentence_} <b>{point.name}</b>`
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: '',
        colorByPoint: true,
        data: this.percentages.map(p => {
          return {
            name: p.name,
            y: p.percentage,
          }
        })
      }]
    });
  }

  private initPieV2(): void {
    this.inited = true;
    (Highcharts as any).chart(this.console_id, {
      lang: {
        noData: `No recent ${this.stats_description}`,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: 'Lato'
        }
      },
      colors: this.qrGeneratorService.chartColorRangescolors(),
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: `<b>{point.percentage:.1f} %</b> ${this.stats_description}s recorded`,
        padding: 14,
        borderRadius: 20,
        backgroundColor: 'white',
        borderColor: 'none'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: `<b>{point.percentage:.1f} %</b> ${this.stats_description}s ${this.sentence_} <b>{point.name}</b>`
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: '',
        colorByPoint: true,
        data: this.percentages.map(p => {
          return {
            name: p.name,
            y: p.percentage,
          }
        })
      }]
    });
  }


}

export interface VisitPercentage {
  name: string;
  total: number;
  percentage: number;
}
