import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomInputComponent),
    multi: true
};

@Component({
    selector: 'app-custom-input',
    templateUrl: './custom-input.component.html',
    styleUrls: ['./custom-input.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CustomInputComponent implements OnInit {

    ori_set = false
    ori_value = ""
    input_value = ""
    is_valid = true

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.input_value;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (!this.ori_set) {
            this.ori_set = true
            this.ori_value = this.input_value
        }
        if (v !== this.input_value) {
            this.input_value = v;
            this.onChangeCallback(v);
        }
    }


    edit_mode_on = false
    @Input() label = ""
    @Input() min = 0
    @Input() max = 100
    @Input() placeholder = ""
    @Input() input_type = "text"
    @Input() required = false
    @Input() read_only = false
    @Input() report_attempt_instead = false
    @Output() _change = new EventEmitter<string>()
    @Output() _change_clicked = new EventEmitter<boolean>()

    constructor(
        private functions: FunctionsService
    ) { }

    ngOnInit(): void {
        if (!this.placeholder && this.label) this.placeholder = this.label
    }

    tellParent() {
        setTimeout(() => {
            this._change.emit(this.value)
        }, 100)
    }

    acceptEdit() {
        if (this.required) {
            if (!`${this.input_value}`.trim()) {
                this.is_valid = false
                return
            }
            if ('email' == this.input_type && !this.functions.isEmail(this.input_value)) {
                this.is_valid = false
                return
            }
            if ('number' == this.input_type) {
                this.input_value = this.input_value || "0"
                let v = +this.input_value
                if (v < this.min || v > this.max) {
                    this.is_valid = false
                    return
                }
            }
        }

        this.ori_value = this.input_value
        // this.value = this.input_value

        this.is_valid = true
        this.edit_mode_on = !this.edit_mode_on
        this.tellParent()
    }

    cancelEdit() {
        this.is_valid = true
        this.edit_mode_on = !this.edit_mode_on
        if (this.ori_set) {
            this.value = this.ori_value
        }
    }

    attemptChange() {
        if (this.report_attempt_instead) {
            this._change_clicked.emit(true)
            return
        }
        this.edit_mode_on = !this.edit_mode_on
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.input_value) {
            this.input_value = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

}
