import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-vacancy',
  templateUrl: './shared-qr-preview-vacancy.component.html',
  styleUrls: ['./shared-qr-preview-vacancy.component.scss']
})
export class SharedQrPreviewVacancyComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  demo_file = {
    file: null,
    applied: false
  }

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  steps = 0 // 0 for showing JD, 1 for filling application forms, 2 for Thanks for your application

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category == QrGeneratorNavigatorCategories.VACANCY)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        this.resetSteps()
        if (d.vacancy_data) {
          this.event_additional_settings_obs.next({
            text_color: d.vacancy_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.vacancy_data = this.qrGeneratorService.initVacancyCategoryData()
          this.data.vacancy_data.headings.main = "Project manager - Growth"
          this.data.vacancy_data.headings.meta = "BRANDQRCODES is looking to hire a junior project manager to join its Growth team. Read the description below and proceed to apply if it suits you."
          this.data.vacancy_data.company_name = "BRANDQRCODES"
          this.data.vacancy_data.colors.primary_color = "#75cfb8"
          this.data.vacancy_data.colors.secondary_color = "#03506f"
          this.data.vacancy_data.application.descriptions = "<b>You will play an important role, such as</b><br>"
          this.data.vacancy_data.application.descriptions += "<ul><li>Project-based role focusing on driving the growth of BRANDQRCODES globally</li>"
          this.data.vacancy_data.application.descriptions += "<li>Be part of the team that shapes Shopee through robust and exciting special projects</li>"
          this.data.vacancy_data.application.descriptions += "<li>Drive high-impact projects, in a fast-paced environment in nation-wide campaigns, partnerships and major online & offline marketing initiatives</li>"
          this.data.vacancy_data.application.descriptions += "<li>Work on ad-hoc e-commerce projects and assignments with the most efficient execution.</li>"
          this.data.vacancy_data.application.descriptions += "<li>Create and execute dynamic project plans</li>"
          this.data.vacancy_data.application.descriptions += "<li>Assist in reviewing and analyzing key performance metrics</li>"
          this.data.vacancy_data.application.descriptions += "<li>Assist in data analysis and performance reporting of projects</li>"
          this.data.vacancy_data.application.descriptions += "<li>Any other tasks assigned by the management from time to time</li></ul>"
          this.data.vacancy_data.application.summary = "BRANDQRCODES is a software company based in Hamburg, Germany. The company specialises in building software that improves the processes involved in the growth of small and large businesses."
          this.data.vacancy_data.contact.name = "Sarah"
          this.data.vacancy_data.contact.email = "help@brandqrcodes.com"
          this.data.vacancy_data.contact.mobile = "012 345 6789"
          this.data.vacancy_data.contact.website = "brandqrcodes.com"
          this.data.vacancy_data.application.off_site = true
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  startSubmit() {
    if (!this.steps) {
      this.steps++
      return
    }
    if (this.demo_file.file && this.data.vacancy_data.application.contact.name && this.data.vacancy_data.application.contact.email) {
      this.steps++
      this.demo_file.applied = true
      return
    }
  }

  resetSteps() {
    this.steps = 0
    this.demo_file.file = null
    this.demo_file.applied = false
  }

  fileChangedEvent(event: any) {
    if (event.target.files.length) {
      this.demo_file.file = event.target.files[0]
    }
  }


}
