<!-- Bounce modal -->
<div class="ouibounce-modal" #bounce_modal_container style="display: none;">
    <div class="underlay"></div>
    <div class="ouibounce-modal-parent">
        <ng-container *ngIf="content === content_types.SIGNUP">
            <div class="ouibounce-modal-signup">
                <div class="ouibounce-modal-title d-flex flex-column w-50 justify-content-between">
                    <h5 class="p-2 mt-4">
                        Create stunning <span class="text-primary fw-bolder d-block">WEB PAGES</span> instantly
                        accessible
                        through
                        QR codes.
                    </h5>
                    <div class="omt-image"></div>
                </div>
                <div class="ouibounce-modal-body d-flex justify-content-center flex-column">
                    <div class="fw-bold fs-4 text-secondary">WAIT!</div>
                    <div class="fs-6 text-secondary fw-light">
                        Don't miss out on the magic of QR codes!
                    </div>
                    <button class="btn btn-secondary my-2 btn-sm" routerLink="/auth/signup">CREATE FREE
                        ACCOUNT
                        <i class="bi bi-arrow-right ms-2"></i>
                    </button>
                    <small>
                        <a class="link-here" (click)="closeBounceModal()">NO THANKS</a>
                    </small>
                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="content === content_types.PROMOTION">
            <ng-container *ngIf="promo_code">
                <div class="ouibounce-modal-promotion">
                    <div>
                        <i class="bi bi-x-lg fs-4 mouse-pointer" (click)="closeBounceModal()"></i>
                    </div>
                    <div class="fs-2 fw-light">ONLY FOR YOU</div>
                    <div class="fs-4">UP TO <span class="fw-bold fs-1">{{promo_code.percentage}}%</span> OFF YOUR
                        FIRST
                        PURCHASE
                    </div>
                    <div>
                        <button class="btn btn-success my-3" routerLink="/promotion/discounts">GET YOUR
                            {{promo_code.percentage}}% OFF</button>
                    </div>
                    <a (click)="closeBounceModal()">NO THANKS</a>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>