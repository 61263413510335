<div class="mb-3" [class.processing]="file_helper.uploading">
    <label class="form-label img-label" *ngIf="maintain_aspect_ratio">Image <i class="bi bi-info-circle f-sm ms-1"
            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top"
            title="Upload an image from your computer. Image must be at least {{resolution}} in .jpg or .png">
        </i></label>
    <span *ngIf="!maintain_aspect_ratio"></span>
    <app-inline-loader *ngIf="file_helper.uploading" bg_color="secondary"></app-inline-loader>
    <ng-content></ng-content>
    <div class="img-main-wrapper">
        <div class="img-wrapper" [class.logo-img]="!banner_mode">
            <img loading="lazy" [src]="data || '/assets/picture.png'">
            <i class="bi bi-x-circle-fill closer" (click)="deleteImage()" *ngIf="data && optional"></i>
        </div>
        <button type="button" class="btn btn-secondary btn-sm" (click)="openCanvas('uploadImg' + instance_id)">
            <svg width="18" height="18" fill="currentColor" class="bi bi-arrow-repeat me-1" viewBox="0 0 16 16">
                <path
                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                <path fill-rule="evenodd"
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
            </svg> Change
        </button>
    </div>
</div>

<!-- UPLOAD PICTURE MODAL -->
<div class="offcanvas offcanvas-end" tabindex="-1" [id]="'uploadImg' + instance_id">
    <div class="offcanvas-header">
        <h5>Upload Image</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <div class="uploader">
            <input [id]="'file-upload' + instance_id" #myFileInput (change)="fileChangeEvent($event);" type="file"
                name="fileUpload" accept="image/*">

            <div *ngIf="imageChangedEvent; else noFilePickedYet">
                <div class="crop-options text-end">
                    <button (click)="finishImageUpload(true)" class="btn btn-secondary btn-sm">
                        <i class="bi bi-x"></i>
                    </button>
                    <button (click)="finishImageUpload()" class="btn btn-secondary btn-sm">
                        <i class="bi bi-check2"></i>
                    </button>
                </div>
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintain_aspect_ratio"
                    [aspectRatio]="banner_mode ? 3.2 / 1.8 : 1 / 1" format="png" (imageCropped)="imageCropped($event)"
                    (loadImageFailed)="functions.showErrorMessage('Please select a different image file'); imageChangedEvent = null">
                </image-cropper>
            </div>

            <ng-template #noFilePickedYet>
                <label [for]="'file-upload' + instance_id" appDragndrop (onFileDropped)="fileChangeEvent($event)"
                    [id]="'file-drag' + instance_id">
                    <div class="py-3">
                        <img loading="lazy" src="assets/image-upload.png">
                    </div>
                    <p *ngIf="maintain_aspect_ratio">Drag and drop image here<br>Image must be at least
                        {{resolution}} in .jpg or .png</p>
                    <p *ngIf="!maintain_aspect_ratio">Drag and drop logo here or</p>
                    <span [id]="'file-upload-btn' + instance_id" class="btn btn-secondary">Select a file</span>
                </label>
            </ng-template>
        </div>
    </div>
</div>