import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { CoreService } from '@app/modules/core/services/core.service';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { QrGeneratorNavigatorCategories, DynamicQrPageThemeType, SupportedLanguage } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { IAppConfig } from '@app/store/models/config.interface';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shared-qr-preview-pet',
  templateUrl: './shared-qr-preview-pet.component.html',
  styleUrls: [
    './shared-qr-preview-pet.component.scss',
    './shared-qr-preview-pet-ThemeType1.component.scss',
    './shared-qr-preview-pet-ThemeType2.component.scss',
    './shared-qr-preview-pet-ThemeType3.component.scss',
    './shared-qr-preview-pet-ThemeType4.component.scss',
    './shared-qr-preview-pet-ThemeType5.component.scss',
    './shared-qr-preview-pet-ThemeType6.component.scss',
    './shared-qr-preview-pet-ThemeType7.component.scss',
    './shared-qr-preview-pet-ThemeType8.component.scss',
    './shared-qr-preview-pet-ThemeType9.component.scss',
    './shared-qr-preview-pet-ThemeType11.component.scss',
    './shared-qr-preview-pet-ThemeType12.component.scss',
    '../shared-qr-preview.component.scss'
  ]
})
export class SharedQrPreviewPetComponent implements OnInit {


  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  ThemeTypes = {
    ThemeType1: DynamicQrPageThemeType.ThemeType1,
    ThemeType2: DynamicQrPageThemeType.ThemeType2,
    ThemeType3: DynamicQrPageThemeType.ThemeType3,
    ThemeType4: DynamicQrPageThemeType.ThemeType4,
    ThemeType5: DynamicQrPageThemeType.ThemeType5,
    ThemeType6: DynamicQrPageThemeType.ThemeType6,
    ThemeType7: DynamicQrPageThemeType.ThemeType7,
    ThemeType8: DynamicQrPageThemeType.ThemeType8,
    ThemeType9: DynamicQrPageThemeType.ThemeType9,
    ThemeType10: DynamicQrPageThemeType.ThemeType10,
    ThemeType11: DynamicQrPageThemeType.ThemeType11,
    ThemeType12: DynamicQrPageThemeType.ThemeType12,
  }

  private slideInterval: any
  private deepAddStyleToQuillOutputCounter: number;
  private last_summary_check = { color: '', summary: '', type: DynamicQrPageThemeType.ThemeType1 };
  selected_current_language_obs: Subject<SupportedLanguage> = new Subject<SupportedLanguage>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    public translateService: TranslateService,
    public qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.PET)
      } else {
        d = this.session_info.qr_generating.updating
      }
      if (d) {
        if (d.pet_data) {
          this.event_additional_settings_obs.next({
            text_color: d.pet_data.colors.text_color,
            tooltip: ""
          })

          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.pet_data = this.qrGeneratorService.initPetCategoryData()
          this.data.pet_data.share_on = false
          this.data.pet_data.show_qr_code = false
          this.data.pet_data.owner_details.savable = false
          this.data.pet_data.owner_details.name = "John Doe"
          this.data.pet_data.owner_details.website = "brandqrcodes.com"
          this.data.pet_data.owner_details.email = "help@brandqrcodes.com"
          this.data.pet_data.owner_details.mobile = "+0123456789"
          this.data.pet_data.pet_name = "Bailey"
          this.data.pet_data.headings.main = "Breed"
          this.data.pet_data.headings.meta = "Jack Russell Terrier"
          this.data.pet_data.owner_details.location.address = "San Francisco, CA, USA"
          this.data.pet_data.summary = "<i>Meet Bailey, the mischievous and lovable canine companion. Playful, loyal, and full of heart.</i>"
          this.data.pet_data.colors.primary_color = "#242333"
          this.data.pet_data.colors.secondary_color = "#E7A14F"
          this.data.pet_data.other_information.push({
            icon: "bi bi-prescription2",
            key: "Vaccinated",
            value: "Yes",
          })
          this.data.pet_data.other_information.push({
            icon: "bi bi-palette",
            key: "Color",
            value: "Brown",
          })
          this.data.pet_data.other_information.push({
            icon: "bi bi-gender-ambiguous",
            key: "Gender",
            value: "Female",
          })
          this.data.pet_data.gallery = {
            show: true,
            images: {
              grid: true,
              deleted: [],
              list: [
                `${environment.files_url}assets/resources/samples/pictures/gallery/7.jpg`,
                `${environment.files_url}assets/resources/samples/pictures/gallery/8.jpg`,
                `${environment.files_url}assets/resources/samples/pictures/gallery/9.jpg`,
                `${environment.files_url}assets/resources/samples/pictures/gallery/10.jpg`,
              ].map((url, position) => {
                return {
                  url,
                  size: "",
                  name: "",
                  position
                }
              })
            }
          }
          this.slideTemplate()
          return
        }
        this.data = d
        this.slideTemplate()
        if (this.data.pet_data) {
          if (this.data.pet_data.language) {
            this.translateService.use(this.data.pet_data.language.default)
            this.selected_current_language_obs.next(this.data.pet_data.language.default)
          }
          if (
            this.last_summary_check.type !== this.data.pet_data.theme.type ||
            this.last_summary_check.summary !== this.data.pet_data.summary ||
            this.last_summary_check.color !== this.data.pet_data.colors.text_color
          ) {
            this.last_summary_check.summary = this.data.pet_data.summary
            this.last_summary_check.color = this.data.pet_data.colors.text_color
            this.deepAddStyleToQuillOutput()
          }
        }
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  private slideTemplate() {
    const _keys = Object.keys(this.ThemeTypes)
    if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
      if (this.create_mode) {
        let _i = 1
        this.slideInterval = setInterval(() => {
          if (_i >= _keys.length) _i = 0
          this.data.pet_data.theme.type = this.ThemeTypes[_keys[_i]]
          _i++
        }, 2000)
        this.data.pet_data.theme.type = this.ThemeTypes[_keys[0]]
        return
      }
    }
    clearInterval(this.slideInterval)
  }

  private deepAddStyleToQuillOutput() {
    clearTimeout(this.deepAddStyleToQuillOutputCounter)
    this.deepAddStyleToQuillOutputCounter = setTimeout(() => {
      const tempDiv = document.getElementById('ql-editor')
      if (tempDiv) {
        let textAlign = [
          DynamicQrPageThemeType.ThemeType3,
          DynamicQrPageThemeType.ThemeType4,
          DynamicQrPageThemeType.ThemeType9,
          DynamicQrPageThemeType.ThemeType10,
        ].includes(this.data.pet_data.theme.type) ? 'left' : 'center';

        const color = [
          DynamicQrPageThemeType.ThemeType1,
          DynamicQrPageThemeType.ThemeType3,
          DynamicQrPageThemeType.ThemeType4,
          DynamicQrPageThemeType.ThemeType5,
          DynamicQrPageThemeType.ThemeType6,
          DynamicQrPageThemeType.ThemeType8,
          DynamicQrPageThemeType.ThemeType11,
          DynamicQrPageThemeType.ThemeType12,
        ].includes(this.data.pet_data.theme.type) ? this.data.pet_data.colors.primary_color : this.data.pet_data.colors.text_color;
        tempDiv.childNodes.forEach((node: HTMLElement) => {

          if (node.nodeType === Node.TEXT_NODE) {
            const parentEl = node.parentNode as HTMLElement;
            parentEl.style.textAlign = textAlign;
            parentEl.style.color = color;
          } else {
            node.style.textAlign = textAlign;
            node.style.color = color;
          }
        });
      }
    })
  }

}
