import { Subscription, Observable } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopupCategories, QrGeneratorNavigatorCategories } from 'shared-library';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';

@Component({
  selector: 'app-shared-qr-preview',
  templateUrl: './shared-qr-preview.component.html',
  styleUrls: ['./shared-qr-preview.component.scss']
})
export class SharedQrPreviewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = []

  @Input() show_demo = false
  @Input() popup_page: PopupCategories = null
  @Input() _page: QrGeneratorNavigatorCategories = null
  @Input() popup_event_page: Observable<PopupCategories> = null
  @Input() event_page: Observable<QrGeneratorNavigatorCategories> = null
  all_categories = {
    ...this.qrGeneratorService.getAllCategoriesObj(),
    // Popup categories
    LIGHTBOX: PopupCategories.LIGHTBOX,
    SLIDE_IN: PopupCategories.SLIDE_IN,
    BIG_SIDE_BAR: PopupCategories.BIG_SIDE_BAR,
    SUCCESS_PAGE: PopupCategories.SUCCESS_PAGE,
  }

  constructor(
    private qrGeneratorService: UserQrGeneratorService
  ) { }

  ngOnInit(): void {
    if (this.event_page) this.subscriptions.push(this.event_page.subscribe((page) => this._page = page))
    if (this.popup_event_page) this.subscriptions.push(this.popup_event_page.subscribe((page) => this.popup_page = page))
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  getDemoURL(): DemoURLObj {
    if (!this._page || !this.show_demo) return null;

    if (this._page === QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT) {
      return { url: "https://brandqrcodes.com/qrs/mqs8FOFNO", preview_img: "/assets/demo/long-plain-text_mqs8FOFNO.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.MP3) {
      return { url: "https://brandqrcodes.com/qrs/rZWJ4_lZg", preview_img: "/assets/demo/mp3_rZWJ4_lZg.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.APP) {
      return { url: "https://brandqrcodes.com/qrs/lA7EdzOnF", preview_img: "/assets/demo/app_lA7EdzOnF.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.VACANCY) {
      return { url: "https://brandqrcodes.com/qrs/Xc8OASd_R", preview_img: "/assets/demo/vacancy_Xc8OASd_R.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.EVENT) {
      return { url: "https://brandqrcodes.com/qrs/NcWIcJkwT", preview_img: "/assets/demo/event_NcWIcJkwT.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.VIDEOS) {
      return { url: "https://brandqrcodes.com/qrs/sw8DUeIL7", preview_img: "/assets/demo/youtube-video_sw8DUeIL7.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.RATING) {
      return { url: "https://brandqrcodes.com/qrs/mS-TZAPym", preview_img: "/assets/demo/rating_mS-TZAPym.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.FEEDBACK) {
      return { url: "https://brandqrcodes.com/qrs/MM_9okdma", preview_img: "/assets/demo/feedback_MM_9okdma.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.COUPONS) {
      return { url: "https://brandqrcodes.com/qrs/FyLIwnzqe", preview_img: "/assets/demo/coupon_FyLIwnzqe.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.SOCIAL_MEDIA) {
      return { url: "https://brandqrcodes.com/qrs/waldendental", preview_img: "/assets/demo/social-media_waldendental.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.BUSINESS_PAGE) {
      return { url: "https://brandqrcodes.com/qrs/vUY_8Vd7b", preview_img: "/assets/demo/business-page_vUY_8Vd7b.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.PET) {
      return { url: "https://brandqrcodes.com/qrs/ogSDL3ILd", preview_img: "/assets/demo/pet_ogSDL3ILd.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.VCARD_PRO) {
      return { url: "https://brandqrcodes.com/qrs/0a08cbc33fbe408fa41e5453c62e41ab", preview_img: "/assets/demo/vcard-pro_0a08cbc33fbe408fa41e5453c62e41ab.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.PDF) {
      return { url: "https://brandqrcodes.com/qrs/2YRclYUCo", preview_img: "/assets/demo/pdf_2YRclYUCo.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.RESTAURANT_MENU) {
      return { url: "https://brandqrcodes.com/qrs/k4_s2DhAy", preview_img: "/assets/demo/restaurant-menu_k4_s2DhAy.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.IMAGES) {
      return { url: "https://brandqrcodes.com/qrs/tB7eFG66R", preview_img: "/assets/demo/images_tB7eFG66R.jpeg" }
    }

    if (this._page === QrGeneratorNavigatorCategories.MEMORIES) {
      return { url: "https://brandqrcodes.com/qrs/PO3JjKk6K", preview_img: "/assets/demo/memories_PO3JjKk6K.jpeg" }
    }

    return null
  }


}

interface DemoURLObj {
  url: string;
  preview_img: string;
}
