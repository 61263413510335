import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

    @Input() total: number;
    @Input() page: number = 1;
    @Input() totalPages: number;
    @Input() current: number = 1;
    @Output() next_page = new EventEmitter<number>()

    constructor(
        public readonly functionService: FunctionsService
    ) {

    }

    ngOnInit(): void {
    }

    getPageRange(): number[] {
        let max = 5
        let response: number[] = []
        if (this.page <= 3) {
            for (let index = 1; index <= max && index <= this.totalPages; index++) {
                response.push(index)
            }
            return response
        }
        for (let index = this.page > 2 ? this.page - 2 : this.page; index <= this.totalPages && response.length <= max; index++) {
            response.push(index)
        }
        return response
    }

    async changePage(next_page: number) {
        this.next_page.emit(next_page)
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000)
    }

}
