<div class="preview-wrapper animated fadeIn">
    <br>
    <br>
    <br>
    <br>
    <br>
    <img loading="lazy" src="assets/website.png" class="mb-3" width="50%">
    <br>
    <a class="btn btn-block btn-secondary mt-3 btn-sm" [class.disable-div]="!functionService.is_valid_url(url_to_show)"
        [href]="url_to_show" target="_blank">
        <i class="bi bi-send-check me-2"></i>
        Visit URL
    </a>
</div>