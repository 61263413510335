import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { AppState } from '@app/store/states/app.states';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';
import { selectAppState } from '@app/store/selectors/app.selectors';
import { CoreService } from '@app/modules/core/services/core.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { QrGeneratorNavigatorCategories, QrGeneratorNavigatorRestaurantMenuDataSectionItem, QrGeneratorNavigatorRestaurantMenuDataSectionItemSubOption, QrGeneratorSOCIALS } from 'shared-library';


@Component({
  selector: 'app-shared-qr-preview-restaurant-menu',
  templateUrl: './shared-qr-preview-restaurant-menu.component.html',
  styleUrls: ['./shared-qr-preview-restaurant-menu.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewRestaurantMenuComponent implements OnInit, AfterViewInit {

  product: QrGeneratorNavigatorRestaurantMenuDataSectionItem
  create_mode = !window.location.href.includes("edit")
  active_view: 'products' | 'product' | 'summary' = 'products';
  potential_cart_entry: { item_id: number; thumbnail: string; title: string; quantity: number; unit_price: number; descriptions: string[]; subs: Record<string, number[]> }
  cart: Array<typeof this.potential_cart_entry> = [];

  @ViewChild('previewWrapper') previewWrapper: ElementRef;
  @ViewChild('scrollspyContainer') scrollspyContainer: ElementRef;

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  app_state: AppState
  appState$ = this.store.select(selectAppState);

  constructor(
    private store: Store,
    private renderer: Renderer2,
    public coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.RESTAURANT_MENU)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.restaurant_menu_data) {
          this.event_additional_settings_obs.next({
            text_color: d.restaurant_menu_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip();
        }

        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead

          this.data = JSON.parse(JSON.stringify(d))
          this.data.restaurant_menu_data = this.qrGeneratorService.initRestaurantMenu(this.app_state?.currency)
          this.data.restaurant_menu_data.colors.text_color = "#FFFFFF"
          this.data.restaurant_menu_data.colors.primary_color = "#E98300"
          this.data.restaurant_menu_data.colors.secondary_color = "#F95700FF"
          this.data.restaurant_menu_data.headings.main = "Sizzle & Spice Eatery"
          this.data.restaurant_menu_data.headings.meta = "At Sizzle & Spice Eatery, we do more than just serve food; we ignite your taste buds with flavor."

          this.data.restaurant_menu_data.menu.sections = [
            {
              id: 0,
              show: true,
              headings: {
                main: "Food",
                meta: "Main dishes like sizzling platters, spicy specialties, and hearty bowls."
              },
              items: [
                {
                  id: 5,
                  price: 12.50,
                  show: true,
                  allergens: [
                    "Mustard",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Peanuts",
                    "Tomato",
                    "Molluscs",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/sizzling-chicken-platter.jpg`,
                  headings: {
                    main: "Sizzling Chicken Platter",
                    meta: "Marinated chicken served sizzling with seasonal vegetables."
                  },
                  subs: [
                    {
                      id: this.functions.randomNumber(),
                      title: 'Size',
                      show: true,
                      is_variant: true,
                      is_required: true,
                      is_multi_select: false,
                      options: [{
                        id: this.functions.randomNumber(),
                        price: 10.50,
                        title: 'Small',
                      },
                      {
                        id: this.functions.randomNumber(),
                        price: 12.50,
                        title: 'Regular',
                      },
                      {
                        id: this.functions.randomNumber(),
                        price: 14.00,
                        title: 'Large',
                      }]
                    },
                    {
                      id: this.functions.randomNumber(),
                      title: 'Side dish',
                      show: true,
                      is_variant: false,
                      is_required: false,
                      is_multi_select: false,
                      options: [{
                        id: this.functions.randomNumber(),
                        price: 0,
                        title: 'Bread',
                      },
                      {
                        id: this.functions.randomNumber(),
                        price: 0,
                        title: 'Rice',
                      }]
                    }
                  ]
                },
                {
                  id: 6,
                  price: 13.00,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Tomato",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/spicy-beef-curry.jpg`,
                  headings: {
                    main: "Spicy Beef Curry",
                    meta: "Tender beef in a rich, spicy curry sauce."
                  },
                  subs: []
                },
                {
                  id: 7,
                  price: 10.50,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Tomato",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/fusion-noodle-bowl.jpg`,
                  headings: {
                    main: "Fusion Noodle Bowl",
                    meta: "Noodles topped with mixed veggies and your choice of protein."
                  },
                  subs: []
                },
                {
                  id: 8,
                  price: 11.00,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Tomato",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/loaded-rice-bowl.jpg`,
                  headings: {
                    main: "Loaded Rice Bowl",
                    meta: "Rice topped with grilled chicken, fresh salsa, and creamy sauce."
                  },
                  subs: []
                },
              ]
            },
            {
              id: 1,
              show: true,
              headings: {
                main: "Snacks",
                meta: "Small bites and appetisers perfect for sharing or starting your meal."
              },
              items: [
                {
                  id: 7,
                  price: 6.00,
                  show: true,
                  allergens: [
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Tomato",
                    "Tomato",
                    "Mustard",
                    "Peanuts",
                    "Molluscs",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/crispy-spiced-wings.jpg`,
                  headings: {
                    main: "Crispy Spiced Wings",
                    meta: "Chicken wings tossed in a house-made spicy seasoning."
                  },
                  subs: []
                },
                {
                  id: 8,
                  price: 4.50,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/chili-garlic-fries.jpg`,
                  headings: {
                    main: "Chili Garlic Fries",
                    meta: "Golden fries drizzled with chili and garlic sauce."
                  },
                  subs: []
                },
                {
                  id: 9,
                  price: 5.00,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/cheese-stuffed-jalapenos.jpg`,
                  headings: {
                    main: "Cheese-Stuffed Jalapeños",
                    meta: "Jalapeños filled with creamy cheese and lightly fried."
                  },
                  subs: []
                },
                {
                  id: 10,
                  price: 4.00,
                  show: true,
                  allergens: [
                    "Tomato",
                    "Milk",
                    "Wheat",
                    "Gluten",
                    "Sesame",
                    "Celery",
                    "Walnut",
                    "Peanuts",
                    "Mustard",
                    "Molluscs",
                    "Crustaceans",
                    "Brazil Nuts",
                  ],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/mini-samosa-trio.jpg`,
                  headings: {
                    main: "Mini Samosa Trio",
                    meta: "A mix of potato, chicken, and veggie samosas."
                  },
                  subs: []
                },
              ]
            },
            {
              id: 2,
              show: true,
              headings: {
                main: "Deserts",
                meta: "Sweet treats with a twist, like brownies and churros."
              },
              items: [
                {
                  id: 9,
                  price: 5.50,
                  show: true,
                  allergens: [],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/molten-lava-brownie.jpg`,
                  headings: {
                    main: "Molten Lava Brownie",
                    meta: "Warm brownie with a molten chocolate centre, served sizzling."
                  },
                  subs: []
                },
                {
                  id: 10,
                  price: 4.50,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/churros-with-spiced-chocolate-dip.jpg`,
                  headings: {
                    main: "Churros with Spiced Chocolate Dip",
                    meta: "Crispy churros with a dark chocolate dip."
                  },
                  subs: []
                },
                {
                  id: 11,
                  price: 4.00,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/mango-coconut-pudding.jpg`,
                  headings: {
                    main: "Mango Coconut Pudding",
                    meta: "Creamy mango and coconut pudding with a hint of lime."
                  },
                  subs: []
                },
                {
                  id: 12,
                  price: 3.50,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/cinnamon-sugar-donut-holes.jpg`,
                  headings: {
                    main: "Cinnamon Sugar Donut Holes",
                    meta: "Bite-sized donuts rolled in cinnamon sugar."
                  },
                  subs: []
                },
              ]
            },
            {
              id: 3,
              show: true,
              headings: {
                main: "Beverages",
                meta: "Refreshing drinks, including mocktails, fresh juices, and house-made sodas."
              },
              items: [
                {
                  id: 9,
                  price: 3.50,
                  show: true,
                  allergens: [],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/mango-mint-cooler.jpg`,
                  headings: {
                    main: "Mango Mint Cooler",
                    meta: "Refreshing blend of mango juice, fresh mint, and a hint of lime."
                  },
                  subs: []
                },
                {
                  id: 10,
                  price: 4.00,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/spicy-ginger-mocktail.jpg`,
                  headings: {
                    main: "Spicy Ginger Mocktail",
                    meta: "A zesty drink with ginger, lime, and a touch of spice."
                  },
                  subs: []
                },
                {
                  id: 11,
                  price: 3.50,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/watermelon-basil-fizz.jpg`,
                  headings: {
                    main: "Watermelon Basil Fizz",
                    meta: "Sparkling watermelon juice with a hint of basil."
                  },
                  subs: []
                },
                {
                  id: 12,
                  price: 3.00,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/house-made-iced-tea.jpg`,
                  headings: {
                    main: "House-Made Iced Tea",
                    meta: "Iced tea brewed fresh daily with seasonal fruit infusions."
                  },
                  subs: []
                },
              ]
            },
            {
              id: 4,
              show: true,
              headings: {
                main: "Grill Specials",
                meta: "Deliciously grilled items, including lamb chops, BBQ chicken skewers, and herb-crusted salmon."
              },
              items: [
                {
                  id: 9,
                  price: 16.50,
                  show: true,
                  allergens: [],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/grilled-lamb-chops.jpg`,
                  headings: {
                    main: "Grilled Lamb Chops",
                    meta: "Marinated and grilled to perfection, served with a side of mint yogurt"
                  },
                  subs: []
                },
                {
                  id: 10,
                  price: 12.00,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/bbq-chicken-skewers.jpg`,
                  headings: {
                    main: "BBQ Chicken Skewers",
                    meta: "Tender chicken skewers with a smoky BBQ glaze"
                  },
                  subs: []
                },
                {
                  id: 11,
                  price: 14.50,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/herb-crusted-salmon.jpg`,
                  headings: {
                    main: "Herb-Crusted Salmon",
                    meta: "Salmon fillet grilled with fresh herbs, served with a lemon butter sauce"
                  },
                  subs: []
                },
                {
                  id: 12,
                  price: 10.00,
                  show: true,
                  allergens: ["Milk"],
                  thumbnail: `${environment.app_url_full}assets/qr-generator/vegetable-kebab-platter.jpg`,
                  headings: {
                    main: "Vegetable Kebab Platter",
                    meta: "Assorted grilled veggies on skewers, served with a garlic dip"
                  },
                  subs: []
                },
              ]
            }
          ]
          return
        }
        this.data = d
        if (this.previewWrapper?.nativeElement) {
          this.functions.updateCheckboxColor(this.previewWrapper.nativeElement, d.restaurant_menu_data.colors.secondary_color)
        }
        if (this.product && this.active_view === 'product') {
          let _found = false;
          outer: for (const section of this.data.restaurant_menu_data.menu.sections) {
            for (const item of section.items) {
              if (item.id === this.product.id) {
                this.viewItem(item)
                _found = true
                break outer
              }
            }
          }
          if (!_found) this.changeView('products')
        }
      }
    }));

    this.subscriptions.push(this.appState$.subscribe(ls => this.app_state = ls))

  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngAfterViewInit(): void {
    this.initCustomScrollSpy()
    this.functions.updateCheckboxColor(this.previewWrapper.nativeElement, this.data.restaurant_menu_data.colors.secondary_color)
  }

  private initCustomScrollSpy(): void {
    if (!this.scrollspyContainer) {
      this.functions.logToConsole("Not initialised")
      return
    }

    const getVisibleHeight = (element: HTMLElement, container: HTMLElement): number => {
      let scrollTop = container.scrollTop;
      let scrollBot = scrollTop + 560;
      // let scrollBot = scrollTop + container.clientHeight;
      let containerRect = container.getBoundingClientRect();
      let eleRect = element.getBoundingClientRect();
      let rect = {
        top: eleRect.top - containerRect.top,
        left: eleRect.left - containerRect.left,
        right: eleRect.right - containerRect.right,
        bottom: eleRect.bottom - containerRect.bottom
      };
      let eleTop = rect.top + scrollTop;
      let eleBot = eleTop + element.offsetHeight;
      let visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
      let visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

      return visibleBot - visibleTop;
    }

    const activeClassName = 'active';
    const container = this.scrollspyContainer.nativeElement;
    const sections = container.querySelectorAll('[data-spy="scroll"]') as HTMLElement[];
    window.addEventListener('scroll', () => {
      sections.forEach(section => {
        const iphoneContainer = document.querySelector('.iphonex__case') as unknown as HTMLElement;
        if (!iphoneContainer) return
        const containerHeight = iphoneContainer.clientHeight;
        const visiblePageHeight = getVisibleHeight(section, iphoneContainer);
        const targetId = section.getAttribute('data-target');
        const targetElement = document.getElementById(targetId);
        if (!targetElement) return;
        const percentageVisible = this.functions.percentageCalculatorV2(visiblePageHeight, containerHeight)
        if (percentageVisible >= 40) {
          this.renderer.addClass(targetElement, activeClassName);
          targetElement.style.color = this.data.restaurant_menu_data.colors.text_color
          targetElement.style.backgroundColor = this.data.restaurant_menu_data.colors.primary_color
          return
        }
        this.renderer.removeClass(targetElement, activeClassName);
        targetElement.style.color = "#2A2A2A"
        targetElement.style.backgroundColor = "#f5f5f5"
      });
    }, true);
  }

  scrollToElement(id: string) {
    const targetElement = this.previewWrapper.nativeElement.querySelector('[data-target="' + id + '"]');
    if (!targetElement) return
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
    this.functions.updateCheckboxColor(null, "")
  }

  cartTotal(): number {
    let total = 0
    for (const cart_item of this.cart) {
      total += (cart_item.unit_price * cart_item.quantity)
    }
    return total
  }

  calculateCartItems(): number {
    let _i = 0;
    for (const c of this.cart) _i += c.quantity
    return _i
  }

  viewItem(item: QrGeneratorNavigatorRestaurantMenuDataSectionItem) {
    const is_refreshing = item.id === this.product?.id
    this.product = item;
    this.potential_cart_entry = {
      item_id: item.id,
      unit_price: item.price,
      title: item.headings.main,
      thumbnail: item.thumbnail,
      quantity: is_refreshing ? this.potential_cart_entry.quantity : 1,
      descriptions: is_refreshing ? this.potential_cart_entry.descriptions : [],
      subs: {}
    }
    for (const sub of item.subs) {
      this.potential_cart_entry.subs[`${sub.id}`] = [];
      if (sub.is_required && sub.show && !sub.is_multi_select && sub.options.length) {
        this.potential_cart_entry.subs[`${sub.id}`].push(sub.options[0].id)
        this.potential_cart_entry.descriptions = [sub.options[0].title]
      }
    }
    this.changeView('product')
  }

  changeView(view: typeof this.active_view) {
    this.active_view = view;
    this.functions.initializeTooltip()
    this.functions.updateCheckboxColor(this.previewWrapper.nativeElement, this.data.restaurant_menu_data.colors.secondary_color)
    setTimeout(() => {
      if (view === 'products') {
        this.initCustomScrollSpy()
        if (this.product) {
          const product_dom = document.getElementById(`menu-item${this.product.id}`)
          if (product_dom) {
            product_dom.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            return
          }
        }
      }
      const view_element = this.previewWrapper.nativeElement?.querySelector('section')
      if (view_element) {
        view_element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100)
  }

  pushPopOption({ id: optionId, title }: QrGeneratorNavigatorRestaurantMenuDataSectionItemSubOption, options: number[]) {
    const index = options.findIndex(option => option === optionId);
    if (index === -1) {
      options.push(optionId);
      this.potential_cart_entry.descriptions.push(title);
      return
    }
    options.splice(index, 1);
    this.potential_cart_entry.descriptions.splice(this.potential_cart_entry.descriptions.findIndex(d => d === title), 1);
  }

  reCalculatePrice() {
    if (!this.product) return
    let total = 0
    for (const sub of this.product.subs) {
      for (const option of sub.options) {
        if (sub.options.length && this.potential_cart_entry.subs[sub.id.toString()].includes(option.id)) {
          total += option.price
        }
      }
    }
    this.potential_cart_entry.unit_price = (total || this.product.price)
  }

  addToCart() {

    const isEqual = (cart: typeof this.potential_cart_entry) => {

      const cart_cloned: typeof this.potential_cart_entry = JSON.parse(JSON.stringify(cart));
      const potential_cart_cloned = JSON.parse(JSON.stringify(this.potential_cart_entry));
      for (const key in cart_cloned.subs) {
        if (Object.prototype.hasOwnProperty.call(cart_cloned.subs, key)) {
          cart_cloned.subs[key].sort()
        }
      }
      for (const key in potential_cart_cloned.subs) {
        if (Object.prototype.hasOwnProperty.call(potential_cart_cloned.subs, key)) {
          potential_cart_cloned.subs[key].sort()
        }
      }
      return cart.item_id === this.potential_cart_entry.item_id && JSON.stringify(cart.subs) === JSON.stringify(this.potential_cart_entry.subs)
    }

    let found = false;
    for (const cart of this.cart) {
      if (isEqual(cart)) {
        cart.quantity += this.potential_cart_entry.quantity
        found = true
        break
      }
    }
    if (!found) {
      // Validate at least one required option is selected
      for (const required_subs of this.product.subs.filter(sub => sub.show && sub.is_required && sub.is_multi_select && sub.options.length)) {
        if (!this.potential_cart_entry.subs[required_subs.id.toString()].length) {
          this.functions.showErrorMessage(`${required_subs.title} is required to add to cart.`)
          return
        }
      }
      this.cart.push(JSON.parse(JSON.stringify(this.potential_cart_entry)))
    }

    this.changeView('products');
  }

  gotoProduct(productId: number) {
    for (const section of this.data.restaurant_menu_data.menu.sections) {
      for (const item of section.items) {
        if (item.id === productId) {
          this.viewItem(item)
          break
        }
      }
    }
  }

}
