import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IAppConfig } from '@app/store/models/config.interface';
import { PopupDisplayConfiguration } from '@app/store/models/popup-display-configuration.interface';
import { PopupGeneratorBigSideBar } from '@app/store/models/popup-generator-big-sidebar.interface';
import { PopupGeneratorLightbox } from '@app/store/models/popup-generator-lightbox.interface';
import { PopupGeneratorNavigatorInput } from '@app/store/models/popup-generator-navigator-input.interface';
import { PopupGeneratorSlidein } from '@app/store/models/popup-generator-slidein.interface';
import { PopupGeneratorSuccesspage } from '@app/store/models/popup-generator-success-page.interface';
import { PreparedPopupGeneratedRecordInterface } from '@app/store/models/prepared-popup-generated-record.interface';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { PopupGeneratedStatsImpressionTypes, PopupCategories, PopupGeneratorNavigatorInputTypes, Statuses } from 'shared-library';
import { APP_CONFIG } from 'src/app/modules/core/config/config';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';

declare const bootstrap: any


@Component({
  selector: 'app-shared-popup',
  templateUrl: './shared-popup.component.html',
  styleUrls: ['./shared-popup.component.scss']
})
export class SharedPopupComponent implements OnInit, OnDestroy {

  modal_element: any
  private subscriptions: Subscription[] = []

  popup_categories = {
    SLIDE_IN: PopupCategories.SLIDE_IN,
    LIGHTBOX: PopupCategories.LIGHTBOX,
    SUCCESS_PAGE: PopupCategories.SUCCESS_PAGE,
    BIG_SIDE_BAR: PopupCategories.BIG_SIDE_BAR,
  }

  form_data: UntypedFormGroup
  @Input() preview_mode = false
  @Input() popup: PreparedPopupGeneratedRecordInterface;
  @Input() event_popup: Observable<PreparedPopupGeneratedRecordInterface>;

  timer: any
  slidein_data: PopupGeneratorSlidein
  lightbox_data: PopupGeneratorLightbox
  bigsidebar_data: PopupGeneratorBigSideBar;
  successpage_data: PopupGeneratorSuccesspage;
  visitor_info = this.qrGeneratorService.visitInfo()

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    public readonly functions: FunctionsService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    public readonly qrGeneratorService: UserQrGeneratorService
  ) { }

  ngOnInit(): void {
    if (this.event_popup) {
      this.subscriptions.push(this.event_popup.subscribe(ed => {
        this.popup = ed
        this.slidein_data = null
        this.lightbox_data = null
        this.bigsidebar_data = null
        this.successpage_data = null
        this.displayResolver()
      }))
    }
    this.displayResolver()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  // @HostListener('window:scroll', ['$event'])
  // scrollHandler(event: any) {
  //     console.debug("Scroll Event");
  // }

  private displayResolver() {
    if (!this.popup) return

    const setFormGroup = (inputs: PopupGeneratorNavigatorInput[]) => {
      for (const input of inputs) {
        const validators = [input.required ? Validators.required : Validators.nullValidator]
        if (input.type === PopupGeneratorNavigatorInputTypes.URL) {
          validators.push(Validators.pattern(this.functions.urlRegex()))
        }
        if (input.type === PopupGeneratorNavigatorInputTypes.EMAIL) {
          validators.push(Validators.email)
        }
        this.form_data.addControl(`${input.id}`, this.formBuilder.control(input.value, validators))
      }
    }

    let config: PopupDisplayConfiguration
    this.form_data = this.formBuilder.group({})
    if (this.popup.popup_category === PopupCategories.LIGHTBOX) {
      this.lightbox_data = this.popup.data as PopupGeneratorLightbox
      if (this.lightbox_data.form.enable) {
        setFormGroup(this.lightbox_data.form.inputs)
      }
      config = this.lightbox_data.config
    }

    if (this.popup.popup_category === PopupCategories.SLIDE_IN) {
      this.slidein_data = this.popup.data as PopupGeneratorSlidein
      if (this.slidein_data.form.enable) {
        setFormGroup(this.slidein_data.form.inputs)
      }
      config = this.slidein_data.config
    }

    if (this.popup.popup_category === PopupCategories.BIG_SIDE_BAR) {
      this.bigsidebar_data = this.popup.data as PopupGeneratorBigSideBar
      if (this.bigsidebar_data.form.enable) {
        setFormGroup(this.bigsidebar_data.form.inputs)
      }
      config = this.bigsidebar_data.config
    }

    if (this.popup.popup_category === PopupCategories.SUCCESS_PAGE) {
      this.successpage_data = this.popup.data as PopupGeneratorSuccesspage
    }

    if (this.preview_mode || this.popup.popup_category === PopupCategories.SUCCESS_PAGE || !config) {
      this.displayHandler()
      return
    }

    if (this.popup?.status !== Statuses.ACTIVE) return
    if (config.date.enable) {
      if (!moment().isBetween(moment(config.date.start_date), moment(config.date.end_date), "days", "[]")) {
        return
      }
    }

    if (config.days_n_hours.enable) {
      let passed = false
      const today = moment().format("YYYY-MM-DD")
      outer: for (const period of config.days_n_hours.periods.filter(p => p.opened)) {
        for (const hour of period.hours) {
          if (moment().isBetween(moment(`${today} ${hour.from}:00`), moment(`${today} ${hour.to}:00`), "seconds", "[]")) {
            passed = true
            break outer
          }
        }
      }
      if (!passed) return
    }

    if (config.countries.enable) {
      if (!config.countries.countries.find(country => country === this.visitor_info.country_code)) {
        return
      }
    }

    let delay = 0
    if (config.time_delay.enable) {
      delay = config.time_delay.seconds * 1000
    }

    if (config.page_scroll.enable) {
      //  (scroll)="scrollHandler($event)"
    }
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.displayHandler(), delay)
  }

  private displayHandler() {
    if (!this.popup) return
    if (this.popup.popup_category !== PopupCategories.BIG_SIDE_BAR) {
      setTimeout(() => this.openModal(this.popup.popup_category))
      return
    }
    setTimeout(() => this.openCanvas(this.popup.popup_category))
  }

  async openModal(id: string) {
    this.modal_element = new bootstrap.Modal(document.getElementById(id), {
      keyboard: false
    })

    this.logImpression(PopupGeneratedStatsImpressionTypes.SHOWN)
    this.modal_element.show()
  }

  async openCanvas(id: string) {
    this.modal_element = new bootstrap.Offcanvas(document.getElementById(id), {
      keyboard: false
    })
    this.logImpression(PopupGeneratedStatsImpressionTypes.SHOWN)
    this.modal_element.show()
  }

  async close(log_i = true) {
    this.modal_element?.hide()
    if (log_i) {
      this.logImpression(PopupGeneratedStatsImpressionTypes.CLOSED)
    }
  }

  private async logImpression(impression: PopupGeneratedStatsImpressionTypes) {
    if (this.preview_mode) return
    try {
      await this.httpClient.patch(`${this.config.apiEndpoints.popups.data}${this.popup.short_id}`, {
        impression,
        visit_info: this.visitor_info,
        country: this.visitor_info.country,
        country_code: this.visitor_info.country_code,
      }, { headers: { "skip-message": "true" } }).toPromise()
    } catch (error) {
      this.functions.logToConsole(error)
    }
  }

  comeFromRight(): boolean {
    return (this.popup?.data as PopupGeneratorBigSideBar)?.right_to_view
  }

  async submitData() {
    this.functions.logToConsole(this.form_data.value)
    this.form_data.markAllAsTouched()
    if (!this.form_data.valid) return
    this.close(false)
    try {
      let inputs: PopupGeneratorNavigatorInput[] = []
      for (const input of (this.popup.data as PopupGeneratorBigSideBar).form.inputs) {
        input.value = this.form_data.value[input.id] || ""
        inputs.push(input)
      }
      await this.httpClient.post(`${this.config.apiEndpoints.popups.data}${this.popup.short_id}`, {
        inputs,
        visit_info: this.visitor_info,
        country: this.visitor_info.country,
        country_code: this.visitor_info.country_code,
      }, { headers: { "skip-message": "true" } }).toPromise()
    } catch (error) {
      this.functions.logToConsole(error)
    }
  }
}
