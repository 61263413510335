import * as Highcharts from "highcharts";
import { countries, PublicUserDetails } from 'shared-library';
import { Subscription, Observable } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { StatsVisits } from '@app/store/models/stats-visits.interface';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { TeamInterfaceStatsChartAggregate } from '@app/store/models/team-interface-stats-chart-aggregate.interface';

// import *  as  countries from '../../helpers/json/countries.json';

// import HC_exporting from 'highcharts/modules/exporting';

declare const require: any;
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/data")(Highcharts);
require("src/app/modules/shared/services/worldmap")(Highcharts);

// HC_exporting(Highcharts);

interface MAP_DATA {
  z: number; //visits
  code3: string;
  color: string;
  country_name: string;
}

@Component({
  selector: 'app-shared-map',
  templateUrl: './shared-map.component.html',
  styleUrls: ['./shared-map.component.scss']
})
export class SharedMapComponent implements OnInit, OnDestroy {

  private inited = false

  total_count = 0
  session_info: SessionInfo
  private subscriptions: Subscription[] = []

  @Input() bar_background: string = "#fff";
  @Input() console_id: string = "visit-map-container";
  link_data: TeamInterfaceStatsChartAggregate[] = []

  @Input() stats_description = "VISIT";
  @Input() user_details: PublicUserDetails = null;
  @Input() event_user_details: Observable<PublicUserDetails>;
  @Input() init_link_data: StatsVisits = null
  @Input() event_link_data: Observable<StatsVisits>;

  constructor(
    public coreService: CoreService,
    public functionService: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))
  }

  ngOnInit(): void {
    if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
    const statsVisit = this.init_link_data || this.user_details.current_organization_details.current_team_details.stats.visits
    this.total_count = this.user_details.current_organization_details.current_team_details.stats.qrs.visits
    if (this.init_link_data) {
      this.total_count = this.init_link_data.countries.reduce((a, b) => a + b.counter, 0)
    }
    this.link_data = statsVisit.countries
    if (this.event_user_details) {
      this.subscriptions.push(this.event_user_details.subscribe(ead => {
        this.user_details = ead
        if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
      }))
    }
    if (this.event_link_data) {
      this.subscriptions.push(this.event_link_data.subscribe(link_data => {
        this.init_link_data = link_data
        if (this.init_link_data) {
          this.link_data = this.init_link_data.countries
          this.total_count = this.link_data.reduce((a, b) => a + b.counter, 0)
        }
        if (this.inited) {
          this.ngAfterViewInit()
        }
      }))
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._initMap();
    })
  }

  private extractData(): MAP_DATA[] {
    let ret: MAP_DATA[] = [];
    this.link_data.forEach(country => {
      let findISO = countries.find(x => x.country_code.toLowerCase() === country.key.toLowerCase());
      if (findISO) {
        let i = ret.findIndex(x => x.code3 === findISO.country_code)
        if (i > -1) {
          ret[i].z += country.counter;
        } else {
          ret.push({
            z: country.counter,
            country_name: findISO.name,
            code3: findISO.country_code,
            color: this.qrGeneratorService.chartColorRangescolors()[Math.floor(Math.random() * this.qrGeneratorService.chartColorRangescolors().length)],
          });
        }
      }
    });
    return ret;
  }

  private getMapOptions() {
    if (!document.getElementById(this.console_id)) return null
    return {
      chart: {
        borderWidth: 0,
        map: "custom/world",
        backgroundColor: this.bar_background,
        style: {
          fontFamily: 'Lato'
        }
      },
      title: {
        text: ""
      },

      subtitle: {
        text: ""
      },

      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        padding: 14,
        borderRadius: 20,
        backgroundColor: 'white',
        borderColor: 'none'
      },
      mapNavigation: {
        enabled: false
        // buttonOptions: {
        //   verticalAlign: 'bottom'
        // }
      },

      series: [
        {
          name: "Countries",
          color: "#37375e",
          borderColor: "#37375e",
          borderWidth: 0.2,
          enableMouseTracking: false
        },
        {
          type: "mapbubble",
          name: "Clicks",
          joinBy: ["iso-a2", "code3"],
          data: this.extractData(),
          minSize: 4,
          maxSize: "12%",
          tooltip: {
            pointFormat: "{point.country_name}: {point.z}"
          }
        }
      ]
    };
  }

  _initMap(): void {
    const map_options = this.getMapOptions()
    if (map_options) {
      this.inited = true;
      (Highcharts as any).mapChart(this.console_id, this.getMapOptions());
    }
  }

}
