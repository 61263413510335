<div class="preview-wrapper animated fadeIn" *ngIf="data?.vcard_data">
    <div class="info text-center pt-0">
        <!-- <div class="user-avatar mb-1" *ngIf="data.vcard_data.show_profile_url">
            <img loading="lazy" [src]="data.vcard_data.profile_url">
        </div> -->
        <b>{{data.vcard_data.full_name}}</b>
        <small>{{data.vcard_data.designation}}</small>
    </div>

    <div class="info" *ngIf="data.vcard_data.email">
        <small>Email</small>
        <p>{{data.vcard_data.email}}</p>
    </div>
    <div class="info" *ngIf="data.vcard_data.mobile">
        <small>Phone Number</small>
        <p>{{data.vcard_data.mobile}}</p>
    </div>
    <div class="info" *ngIf="data.vcard_data.company_name">
        <small>Company</small>
        <p>{{data.vcard_data.company_name}}</p>
    </div>
    <div class="info" *ngIf="data.vcard_data.address">
        <small>Address</small>
        <p>{{data.vcard_data.address}}</p>
    </div>
    <div class="info" *ngIf="data.vcard_data.website">
        <small>Website</small>
        <a [href]="functions.forceUrl(data.vcard_data.website)">{{data.vcard_data.website}}</a>
    </div>
    <button class="btn btn-primary btn-block mt-3">Save to contacts</button>
</div>