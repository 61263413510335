import { Component, Inject, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorFacilityTypes, QrGeneratorNavigatorCategories, QrGeneratorSOCIALS, SocialsInterfaceTypes } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-business-page',
  templateUrl: './shared-qr-preview-business-page.component.html',
  styleUrls: ['./shared-qr-preview-business-page.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewBusinessPageComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.create_mode) {
        this.data = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.BUSINESS_PAGE)
      } else {
        this.data = this.session_info.qr_generating.updating
      }
      this.functions.initializeTooltip()

      if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
        // show demo data instead
        let social_templates = this.qrGeneratorService.getSocialTemplates()
        this.data.businesspage_data = this.qrGeneratorService.initBusinessPageCategoryData()
        this.data.businesspage_data.website = "brandqrcodes.com"
        this.data.businesspage_data.company = "Zenith Fitness Studios"
        this.data.businesspage_data.website = "urllist.me/sarah"
        this.data.businesspage_data.email = "help@brandqrcodes.com"
        this.data.businesspage_data.mobile = "01234567891"
        this.data.businesspage_data.full_name = "Sarah"
        this.data.businesspage_data.cta.title = "VIEW MENU"
        this.data.businesspage_data.share_on = true
        this.data.businesspage_data.headings.main = "Zenith Fitness Studios"
        this.data.businesspage_data.headings.meta = "Elevate your well-being with personalized workouts, mindful practices, and a supportive community."
        this.data.businesspage_data.location.address = "Alberta T0J, Canada"
        this.data.businesspage_data.opening_hours[0].opened = true
        this.data.businesspage_data.opening_hours[0].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[1].opened = true
        this.data.businesspage_data.opening_hours[1].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[2].opened = true
        this.data.businesspage_data.opening_hours[2].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[3].opened = true
        this.data.businesspage_data.opening_hours[3].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[4].opened = true
        this.data.businesspage_data.opening_hours[4].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[5].opened = true
        this.data.businesspage_data.opening_hours[5].hours = [{ from: "06:00", to: "22:00", position: 1 }]
        this.data.businesspage_data.opening_hours[6].opened = true
        this.data.businesspage_data.opening_hours[6].hours = [{ from: "06:00", to: "22:00", position: 1 }]

        this.data.businesspage_data.facilities = [
          { type: QrGeneratorFacilityTypes.wifi, name: "WiFi" },
          { type: QrGeneratorFacilityTypes.seat, name: "Seats" },
          { type: QrGeneratorFacilityTypes.wheelchair, name: "Disability Friendly" },
          { type: QrGeneratorFacilityTypes.restrooms, name: "Rest Rooms" },
          { type: QrGeneratorFacilityTypes.loadingbay, name: "Loading Bay" },
          { type: QrGeneratorFacilityTypes.pets, name: "Pets Friendly" },
          { type: QrGeneratorFacilityTypes.parking, name: "Parking" },
          { type: QrGeneratorFacilityTypes.train, name: "Train Station" },
          { type: QrGeneratorFacilityTypes.taxi, name: "Taxi" },
          { type: QrGeneratorFacilityTypes.restingarea, name: "Resting Area" },
          { type: QrGeneratorFacilityTypes.coffee, name: "Coffee" },
          { type: QrGeneratorFacilityTypes.dining, name: "Dining" }
        ]

        this.data.businesspage_data.colors.primary_color = "#000000"
        this.data.businesspage_data.colors.secondary_color = "#222f3e"

        let position = 1
        let type = SocialsInterfaceTypes.website
        let template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: "Visit us online",
          type,
          name: template.name,
          custom_title: "",
          meta_title: "",
          value: "",
          email: "",
          mobile: "",
          href: this.data.businesspage_data.website,
          position: position++,
          favicon: template.favicon,
        })

        type = SocialsInterfaceTypes.instagram
        template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: template.name,
          type,
          name: template.name,
          custom_title: "",
          meta_title: "",
          value: "",
          email: "",
          mobile: "",
          href: "www.instagram.com",
          position: position++,
          favicon: template.favicon
        })

        type = SocialsInterfaceTypes.youtube
        template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: template.name,
          type,
          name: template.name,
          custom_title: "",
          meta_title: "",
          value: "",
          email: "",
          mobile: "",
          href: "www.youtube.com",
          position: position++,
          favicon: template.favicon
        })

        type = SocialsInterfaceTypes.facebook
        template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: template.name,
          type,
          name: template.name,
          custom_title: "",
          meta_title: "",
          value: "",
          email: "",
          mobile: "",
          href: "www.facebook.com",
          position: position++,
          favicon: template.favicon
        })

        type = SocialsInterfaceTypes.twitter
        template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: template.name,
          type,
          name: template.name,
          custom_title: "",
          meta_title: "",
          value: "",
          email: "",
          mobile: "",
          href: "www.twitter.com",
          position: position++,
          favicon: template.favicon
        })

        type = SocialsInterfaceTypes.whatsapp
        template = social_templates.find(st => st.type == type)
        this.data.businesspage_data.socials.push({
          title: template.name,
          type,
          name: template.name,
          custom_title: "",
          meta_title: "Text us on WhatsApp",
          value: "",
          email: "",
          mobile: "",
          href: "www.whatsapp.com",
          position: position++,
          favicon: template.favicon
        })
        return
      }
      setTimeout(() => {
        this.event_socials_obs.next(this.data.businesspage_data.socials)
      }, 300)
    }))
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }
}
