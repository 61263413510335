<div class="preview-wrapper animated fadeIn" *ngIf="data?.pdf_data">
    <div class="preview-header" [ngStyle]="{'background-color': data.pdf_data.colors.primary_color}">
        <small [ngStyle]="{'color': data.pdf_data.colors.text_color}">{{data.pdf_data.company_name}}</small><br>
        <h6 [ngStyle]="{'color': data.pdf_data.colors.text_color}">{{data.pdf_data.headings.main}}</h6>
        <div class="mt-n1">
            <p [ngStyle]="{'color': data.pdf_data.colors.text_color}">{{data.pdf_data.headings.meta}}</p>
        </div>
    </div>
    <div class="preview-body">
        <div class="px-3">
            <div class="pdf-wrapper">
                <img loading="lazy" *ngIf="!data.pdf_data.pdf.url; else pdfExists" src="assets/pdf-icon.png"
                    width="60%">
                <ng-template #pdfExists>
                    <iframe [src]="data.pdf_data.pdf.url | safeUrl" frameborder="0"></iframe>
                </ng-template>
            </div>
        </div>
        <a *ngIf="data.pdf_data.pdf.url" download [href]="data.pdf_data.pdf.url" target="_blank"
            class="btn btn-block mt-4"
            [ngStyle]="{'background-color': data.pdf_data.colors.secondary_color, 'color': data.pdf_data.colors.text_color}">
            <i class="bi bi-eye me-2"></i>
            {{data.pdf_data.cta.title == 'Custom' ? data.pdf_data.cta.custom_title : data.pdf_data.cta.title}}
        </a>
        <div class="text-center mt-3">
            <small><a [href]="functions.forceUrl(data.pdf_data.website)"
                    [ngStyle]="{'color': data.pdf_data.colors.secondary_color}"
                    target="_blank">{{data.pdf_data.website}}</a></small>
        </div>
    </div>
    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.pdf_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.pdf_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.pdf_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.pdf_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.pdf_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.pdf_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>