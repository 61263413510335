import { Subject, Subscription } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { CoreService } from '@app/modules/core/services/core.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { UserQrGeneratorService } from '@app/modules/user/services/user-qr-generator.service';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';

@Component({
  selector: 'app-shared-qr-preview-memories',
  templateUrl: './shared-qr-preview-memories.component.html',
  styleUrls: ['./shared-qr-preview-memories.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewMemoriesComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  sample_images = [
    `${environment.app_url_full}assets/qr-generator/memories-1.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-2.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-3.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-4.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-5.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-6.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-7.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-8.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-9.jpg`,
    `${environment.app_url_full}assets/qr-generator/memories-10.jpg`,
  ]

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    const loadImagePlaceholders = () => {
      this.sample_images = [
        `assets/placeholder-image-portrait.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-portrait.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-portrait.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-portrait.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-landscape.png`,
        `assets/placeholder-image-portrait.png`,
      ]
    }

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.MEMORIES)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        if (d.memories_data) {
          this.event_additional_settings_obs.next({
            text_color: d.memories_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }

        this.data = d
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.memories_data = this.qrGeneratorService.initMemoriesCategoryData()
          this.data.memories_data.colors.primary_color = "#BD967F"
          this.data.memories_data.colors.secondary_color = "#BD967F"
          this.data.memories_data.headings.main = "Jane & Joe"
          this.data.memories_data.headings.meta = "Snap photos and share your experience with us."
          this.data.memories_data.website = "your-website.com"
          this.data.memories_data.share_on = true
        } else if (d.active_step === QrGeneratorNavigatorSteps.CUSTOMIZE) {
          loadImagePlaceholders()
        }
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }


}
