import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';

@Component({
  selector: 'app-shared-qr-preview-bitcoins',
  templateUrl: './shared-qr-preview-bitcoins.component.html',
  styleUrls: ['./shared-qr-preview-bitcoins.component.scss']
})
export class SharedQrPreviewBitcoinsComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  constructor(private coreService: CoreService) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.create_mode) {
        this.data = this.session_info?.qr_generating?.drafts?.find(d => d.selected_category === QrGeneratorNavigatorCategories.BITCOIN)
      } else {
        this.data = this.session_info?.qr_generating?.updating
      }
    }))
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }


}
