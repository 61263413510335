<div class="navbar-wrapper" id="navBar">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand landing" [routerLink]="home_address">
        <img loading="lazy" src="assets/logos/logo.png" alt="Brand qr code logo" height="30">
      </a>
      <a class="navbar-brand pricing" routerLink="/user/dashboard">
        <img loading="lazy" src="assets/logos/logo.png" alt="Brand qr code logo" height="30">
      </a>

      <ng-container *ngIf="!coreService.isLoggedIn()">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <div class="icon" (click)="toggleHamburger($event)" [ngClass]="{'active': toggle}">
            <div class="hamburger"></div>
          </div>
        </button>
      </ng-container>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
          <ng-container *ngIf="!hide_menu">
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                aria-current="page" [routerLink]="home_address">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                routerLink="/referral">Affiliate</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                routerLink="/pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                routerLink="/api-documentation">API</a>
            </li>
          </ng-container>
        </ul>
        <div class="btn-wrapper">
          <ng-container *ngIf="!hide_menu">
            <ng-container *ngIf="!coreService.isLoggedIn()">
              <a class="btn btn-secondary-light" routerLink="/auth/signin">Login</a>
              <a class="btn btn-secondary ms-2" routerLink="/auth/signup">Sign Up</a>
            </ng-container>

            <ng-container *ngIf="coreService.isLoggedIn()">
              <a class="btn btn-secondary btn-dashboard" routerLink="/user/dashboard">Dashboard</a>
            </ng-container>
          </ng-container>

          <a class="cart" *ngIf="show_cart" routerLink="/cart">
            <i class="bi bi-cart"></i>
            <span *ngIf="total_cart_items">{{total_cart_items}}</span>
          </a>

        </div>
      </div>
    </nav>
  </div>
</div>

<a (click)="onActivate()" id="back-to-top" title="Back to top">
  <i class="bi bi-chevron-up"></i>
</a>