import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { PopupGeneratorNavigator } from '@app/store/models/popup-generator-navigator.interface';
import { PopupCategories, PopupGeneratorNavigatorInputTypes } from 'shared-library';

@Component({
  selector: 'app-shared-popup-preview-lightbox',
  templateUrl: './shared-popup-preview-lightbox.component.html',
  styleUrls: ['./shared-popup-preview-lightbox.component.scss']
})
export class SharedPopupPreviewLightboxComponent implements OnInit {


  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: PopupGeneratorNavigator
  private subscriptions: Subscription[] = []

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.session_info) {
        if (this.create_mode) {
          this.data = this.session_info.popup_generating.drafts.find(d => d.selected_category === PopupCategories.LIGHTBOX)
        } else {
          this.data = this.session_info.popup_generating.updating
        }

        if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data.lightbox_data = JSON.parse(JSON.stringify(this.qrGeneratorService.initLightboxCategoryData()))
          this.data.lightbox_data.headings.main = "Get Notified!"
          this.data.lightbox_data.headings.meta = "Stay in the loop with all our latest updates and discounts!"
          this.data.lightbox_data.cta.show = false
          this.data.lightbox_data.cta.title = "Sign me up!"
          this.data.lightbox_data.website = "www.yoursite.com"
          this.data.lightbox_data.form.enable = true
          this.data.lightbox_data.form.inputs = [{
            id: 1,
            value: "",
            label: "Email",
            required: true,
            max_length: 120,
            icon: "bi bi-at",
            placeholder: "Enter your email",
            type: PopupGeneratorNavigatorInputTypes.EMAIL
          }]
          this.data.lightbox_data.colors.text_color = "#606060"
          this.data.lightbox_data.colors.primary_color = "#FFFFFF"
          this.data.lightbox_data.colors.secondary_color = "#FEB003"
        }
      }
    }))
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
