import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { APP_CONFIG } from 'src/app/modules/core/config/config';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import * as Highcharts from "highcharts";
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '@app/store/models/config.interface';
import { GetStatsRequestByDurations, PublicUserDetails, QrVisitsStats, QrVisitsStatsFilter, StatsDailyBreakdown, SystemUserTypes } from 'shared-library';

// import HC_exporting from 'highcharts/modules/exporting';

declare const require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
// HC_exporting(Highcharts);

@Component({
  selector: 'app-shared-bar',
  templateUrl: './shared-bar.component.html',
  styleUrls: ['./shared-bar.component.scss']
})
export class SharedBarComponent implements OnInit, OnDestroy {

  processing = false
  session_info: SessionInfo
  feature_upgrade_text = "Please upgrade to use this feature"

  private subscriptions: Subscription[] = []
  bar_view: BarViewTypes = BarViewTypes.cities
  bar_view_showing: GetStatsRequestByDurations = GetStatsRequestByDurations.daily

  bar_view_types = {
    os: BarViewTypes.os,
    cities: BarViewTypes.cities,
    device: BarViewTypes.device,
    browser: BarViewTypes.browser,
    location: BarViewTypes.location,
  }

  bar_types = {
    visitsummarybyday: GetStatsRequestByDurations.daily,
    linksummarycustom: GetStatsRequestByDurations.custom,
    visitsummarybyhour: GetStatsRequestByDurations.hourly,
    visitsummarybyyear: GetStatsRequestByDurations.yearly,
    visitsummarybymonth: GetStatsRequestByDurations.monthly,
  }

  inited = false
  selection_error: string
  stats_data: QrVisitsStats

  year_in_view = moment().year();
  month_in_view = +moment().format("MM"); //first index is 1
  day_in_view = +moment().format("YYYY-MM-DD");
  maxDate: moment.Moment = moment()
  bsValue: { startDate: moment.Moment, endDate: moment.Moment } = { startDate: moment().startOf("month"), endDate: moment() }

  @Input() stats_qr_id = "";
  @Input() user_details: PublicUserDetails = null;
  @Input() event_stats_qr_id: Observable<string>;
  @Input() event_user_details: Observable<PublicUserDetails>;

  options = this.getBarOptions();
  @Input() console_id: string = "app-bar-container";

  filters: QrVisitsStatsFilter = {
    end_date: moment().format("YYYY-MM-DD"),
    by_time: GetStatsRequestByDurations.daily,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD")
  }

  constructor(
    private httpClient: HttpClient,
    public coreService: CoreService,
    public functionService: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))
  }

  ngOnInit(): void {
    if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
    if (this.event_user_details) {
      this.subscriptions.push(this.event_user_details.subscribe(ead => {
        this.user_details = ead
        if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
      }))
    }
    if (this.event_stats_qr_id) {
      this.subscriptions.push(this.event_stats_qr_id.subscribe(stats_qr_id => {
        this.stats_qr_id = stats_qr_id
        this.fetchStatsData()
      }))
    }
    this.fetchStatsData()
  }

  ngAfterViewInit(): void {
    this._prepareBar();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  setDate(event: { startDate: moment.Moment, endDate: moment.Moment } | any = this.bsValue) {
    if (!event?.endDate?.isValid()) return
    this.filters.end_date = event.endDate.format("YYYY-MM-DD")
    this.filters.start_date = event.startDate.format("YYYY-MM-DD")
    this.changeTime(GetStatsRequestByDurations.custom)
  }

  changeTime(by_time: GetStatsRequestByDurations) {
    if (this.filters.by_time === by_time && by_time !== GetStatsRequestByDurations.custom) return
    this.filters.by_time = by_time
    this.bar_view_showing = by_time

    switch (this.filters.by_time) {
      case GetStatsRequestByDurations.custom:
        break;
      case GetStatsRequestByDurations.hourly:
        this.filters.end_date = moment().format("YYYY-MM-DD")
        this.filters.start_date = moment().subtract(1, "day").format("YYYY-MM-DD")
        break;

      case GetStatsRequestByDurations.monthly:
        this.filters.end_date = moment().format("YYYY-MM-DD")
        this.filters.start_date = moment().subtract(1, "year").format("YYYY-MM-DD")
        break;

      case GetStatsRequestByDurations.yearly:
        this.filters.end_date = moment().year().toString()
        this.filters.start_date = moment().subtract(4, "years").year().toString()
        break;

      case GetStatsRequestByDurations.daily:
      default:
        this.filters.end_date = moment().format("YYYY-MM-DD")
        this.filters.start_date = moment().subtract(29, "days").format("YYYY-MM-DD")
        break;
    }
    this.fetchStatsData()
  }

  async fetchStatsData() {
    if (this.processing) return
    this.processing = true
    try {
      let url = this.session_info.user_details.user_category === SystemUserTypes.ADMIN ? this.config.apiEndpoints.user.qr_stats.replace("/{id}", `/${this.user_details.id}`) : this.config.apiEndpoints.user.qr_stats.replace("/{id}", ``)
      if (this.stats_qr_id) url += `/${this.stats_qr_id}`
      const { data } = await lastValueFrom(this.httpClient.get<{ data: QrVisitsStats }>(url, { params: this.filters as any }))
      this.stats_data = data
      this.ngAfterViewInit()
    } catch (error) {
    }
    this.processing = false
  }

  private getBarOptions(): any {
    return {
      lang: {
        noData: 'No recent visit to show',
      },
      chart: {
        type: "column",
        height: 300,
        backgroundColor: "#FFF",
        style: {
          fontFamily: "Lato",
        },
      },
      colors: this.qrGeneratorService.chartColorRangescolors(),
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        allowDecimals: false,
        categories: [
          // 'Jan',
          // 'Feb',
          // 'Mar',
          // 'Apr',
          // 'May',
          // 'Jun',
          // 'Jul',
          // 'Aug',
          // 'Sep',
          // 'Oct',
          // 'Nov',
          // 'Dec'
        ],
        crosshair: true,
        labels: {
          style: {
            color: "#000",
          },
        },
      },
      legend: {
        itemStyle: {
          color: "#000",
        },
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: "Visits",
          style: {
            color: "#000",
          },
        },
        labels: {
          style: {
            color: "#000",
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        padding: 14,
        borderRadius: 20,
        backgroundColor: "white",
        borderColor: "none",
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color}; padding:0">{series.name}: </td>' + '<td style="padding:4px"><b>{point.y:.0f} visit(s)</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        outside: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.4,
          borderWidth: 0,
        },
      },
      series: [
        //   {
        //   name: 'Tokyo',
        //   data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        // }, {
        //   name: 'New York',
        //   data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
        // }, {
        //   name: 'London',
        //   data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]
        // }, {
        //   name: 'Berlin',
        //   data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]
        // }
      ],
    };
  }

  /**
   *
   * @param forWhich
   * @param end_date today if it's empty
   * @param how_many_days_ago 30 if it's empty
   */
  _prepareBar(): void {
    if (!this.stats_data) return

    let category_format = "ddd, Do MMMM YYYY"
    let series_and_categories: BarDataType = null;
    let format = BarSupportedDateFormats.YEARMONTHDAY

    switch (this.bar_view_showing) {
      case GetStatsRequestByDurations.yearly:
        category_format = ""
        format = BarSupportedDateFormats.YEAR
        break;
      case GetStatsRequestByDurations.monthly:
        category_format = "MMMM, YYYY"
        format = BarSupportedDateFormats.YEARMONTH
        break;
      case GetStatsRequestByDurations.hourly:
        category_format = "ddd, hA"
        format = BarSupportedDateFormats.YEARMONTHDAYHOUR
        break;
      default:

        break;
    }

    switch (this.bar_view) {
      case BarViewTypes.location:
        series_and_categories = this.__locationBarExractor(this.stats_data.visits, format, category_format);
        break;
      case BarViewTypes.os:
        series_and_categories = this.__osBarExractor(this.stats_data.visits, format, category_format);
        break;
      case BarViewTypes.device:
        series_and_categories = this.__deviceBarExractor(this.stats_data.visits, format, category_format);
        break;
      case BarViewTypes.browser:
        series_and_categories = this.__browserBarExractor(this.stats_data.visits, format, category_format);
        break;
      case BarViewTypes.cities:
      default:
        series_and_categories = this.__citiesBarExractor(this.stats_data.visits, format, category_format);
        break;
    }

    if (series_and_categories) {
      this.options.series = series_and_categories.series;
      this.options.xAxis.categories = series_and_categories.categories;
    }

    this._initBar();
  }

  _initBar(): void {
    setTimeout(() => {
      if (!document.getElementById(this.console_id)) return
      this.inited = true
      Highcharts.chart(this.console_id, this.options);
    })
  }

  __selectionErrorHandler(e: string, timer: number = 5000): void {
    this.selection_error = e;
    setTimeout(() => {
      this.selection_error = "";
    }, timer);
  }

  private __locationBarExractor(links: StatsDailyBreakdown[], format = BarSupportedDateFormats.YEAR, category_format = ""): BarDataType {
    const extracted: BarDataType = this.padBarSeries({
      series: [],
      categories: this.functionService.array_unique(links.map(link => moment(link.day).format(format))).reverse(),
    }, format);

    let unique_country_names: string[] = []
    links.forEach((day) => unique_country_names = [...unique_country_names, ...day.countries.map((x) => x.name)]);
    unique_country_names = this.functionService.array_unique(unique_country_names)

    for (const cn of unique_country_names) {
      let series: BarDataTypeSeries = {
        name: cn,
        data: extracted.categories.map(x => 0),
      };

      for (let index = 0; index < extracted.categories.length; index++) {
        let filtered_link_year_clicked = links.filter(x => x.day.startsWith(extracted.categories[index]));
        for (let ii = 0; ii < filtered_link_year_clicked.length; ii++) {
          let filtered_link_year_clicked_country = filtered_link_year_clicked[ii].countries.filter(x => x.name === cn);
          if (!filtered_link_year_clicked_country.length) continue; //No clicks in this country today
          series.data[index] += filtered_link_year_clicked_country.map((x) => x.visits.total_visits).reduce((a, b) => a + b, 0);
        }
      }
      extracted.series.push(series);
    }

    if (category_format) {
      extracted.categories = extracted.categories.map(cat => moment(cat).format(category_format))
    }
    return extracted;
  }

  private __osBarExractor(links: StatsDailyBreakdown[], format = BarSupportedDateFormats.YEAR, category_format = ""): BarDataType {
    const extracted: BarDataType = this.padBarSeries({
      series: [],
      categories: this.functionService.array_unique(links.map(link => moment(link.day).format(format))).reverse(),
    }, format);

    let unique_os_names: string[] = []
    links.forEach((day) => unique_os_names = [...unique_os_names, ...day.os.map((x) => x.name)]);
    unique_os_names = this.functionService.array_unique(unique_os_names)

    for (const cn of unique_os_names) {
      let series: BarDataTypeSeries = {
        name: cn,
        data: extracted.categories.map(x => 0),
      };

      for (let index = 0; index < extracted.categories.length; index++) {
        let filtered_link_year_clicked = links.filter(x => x.day.startsWith(extracted.categories[index]));
        for (let ii = 0; ii < filtered_link_year_clicked.length; ii++) {
          let filtered_link_year_clicked_os = filtered_link_year_clicked[ii].os.filter((x) => x.name === cn);
          if (!filtered_link_year_clicked_os.length) continue; //No clicks in this os today
          series.data[index] += filtered_link_year_clicked_os.map((x) => x.visits.total_visits).reduce((a, b) => a + b, 0);
        }
      }
      extracted.series.push(series);
    }

    if (category_format) {
      extracted.categories = extracted.categories.map(cat => moment(cat).format(category_format))
    }

    return extracted;
  }

  private __deviceBarExractor(links: StatsDailyBreakdown[], format = BarSupportedDateFormats.YEAR, category_format = ""): BarDataType {
    const extracted: BarDataType = this.padBarSeries({
      series: [],
      categories: this.functionService.array_unique(links.map(link => moment(link.day).format(format))).reverse(),
    }, format);

    let unique_device_names: string[] = []
    links.forEach((day) => unique_device_names = [...unique_device_names, ...day.devices.map((x) => x.name)]);
    unique_device_names = this.functionService.array_unique(unique_device_names)

    for (const cn of unique_device_names) {
      let series: BarDataTypeSeries = {
        name: cn,
        data: extracted.categories.map(x => 0),
      };

      for (let index = 0; index < extracted.categories.length; index++) {
        let filtered_link_year_clicked = links.filter(x => x.day.startsWith(extracted.categories[index]));
        for (let ii = 0; ii < filtered_link_year_clicked.length; ii++) {
          let filtered_link_year_clicked_device = filtered_link_year_clicked[ii].devices.filter((x) => x.name === cn);
          if (!filtered_link_year_clicked_device.length) continue; //No clicks in this device today
          series.data[index] += filtered_link_year_clicked_device.map((x) => x.visits.total_visits).reduce((a, b) => a + b, 0);
        }
      }
      extracted.series.push(series);
    }

    if (category_format) {
      extracted.categories = extracted.categories.map(cat => moment(cat).format(category_format))
    }

    return extracted;
  }

  private __browserBarExractor(links: StatsDailyBreakdown[], format = BarSupportedDateFormats.YEAR, category_format = ""): BarDataType {
    const extracted: BarDataType = this.padBarSeries({
      series: [],
      categories: this.functionService.array_unique(links.map(link => moment(link.day).format(format))).reverse(),
    }, format);

    let unique_browser_names: string[] = []
    links.forEach((day) => unique_browser_names = [...unique_browser_names, ...day.browsers.map((x) => x.name)]);
    unique_browser_names = this.functionService.array_unique(unique_browser_names)

    for (const cn of unique_browser_names) {
      let series: BarDataTypeSeries = {
        name: cn,
        data: extracted.categories.map(x => 0),
      };

      for (let index = 0; index < extracted.categories.length; index++) {
        let filtered_link_year_clicked = links.filter(x => x.day.startsWith(extracted.categories[index]));
        for (let ii = 0; ii < filtered_link_year_clicked.length; ii++) {
          let filtered_link_year_clicked_browser = filtered_link_year_clicked[ii].browsers.filter((x) => x.name === cn);
          if (!filtered_link_year_clicked_browser.length) continue; //No clicks in this browser today
          series.data[index] += filtered_link_year_clicked_browser.map((x) => x.visits.total_visits).reduce((a, b) => a + b, 0);
        }
      }
      extracted.series.push(series);
    }

    if (category_format) {
      extracted.categories = extracted.categories.map(cat => moment(cat).format(category_format))
    }

    return extracted;
  }

  private __citiesBarExractor(links: StatsDailyBreakdown[], format = BarSupportedDateFormats.YEAR, category_format = ""): BarDataType {
    const extracted: BarDataType = this.padBarSeries({
      series: [],
      categories: this.functionService.array_unique(links.map(link => moment(link.day).format(format))).reverse(),
    }, format);

    let unique_cities_names: string[] = []
    links.forEach((day) => unique_cities_names = [...unique_cities_names, ...day.cities.map((x) => x.name)]);
    unique_cities_names = this.functionService.array_unique(unique_cities_names)

    for (const cn of unique_cities_names) {
      let series: BarDataTypeSeries = {
        name: cn,
        data: extracted.categories.map(x => 0),
      };

      for (let index = 0; index < extracted.categories.length; index++) {
        let filtered_link_year_clicked = links.filter(x => x.day.startsWith(extracted.categories[index]));
        for (let ii = 0; ii < filtered_link_year_clicked.length; ii++) {
          let filtered_link_year_clicked_cities = filtered_link_year_clicked[ii].cities.filter((x) => x.name === cn);
          if (!filtered_link_year_clicked_cities.length) continue; //No clicks in this cities today
          series.data[index] += filtered_link_year_clicked_cities.map((x) => x.visits.total_visits).reduce((a, b) => a + b, 0);
        }
      }
      extracted.series.push(series);
    }

    if (category_format) {
      extracted.categories = extracted.categories.map(cat => moment(cat).format(category_format))
    }

    return extracted;
  }

  private padBarSeries(bar_data_type: BarDataType, format: BarSupportedDateFormats): BarDataType {
    switch (format) {
      case BarSupportedDateFormats.YEAR:
        for (let index = +this.stats_data.start_date; index <= +this.stats_data.end_date; index++) {
          if (bar_data_type.categories.includes(`${index}`)) continue;
          bar_data_type.categories.push(`${index}`);
        }
        bar_data_type.categories.sort()
        return bar_data_type
      case BarSupportedDateFormats.YEARMONTH:
        const _to = moment(this.stats_data.end_date)
        let _from = moment(this.stats_data.start_date).startOf('month')
        while (!_from.isAfter(_to)) {
          if (!bar_data_type.categories.includes(_from.format(format)))
            bar_data_type.categories.push(_from.format(format));
          _from = _from.add(1, "month")
          bar_data_type.categories.sort()
        }
        return bar_data_type
      case BarSupportedDateFormats.YEARMONTHDAY:
        {
          const _to = moment(this.stats_data.end_date)
          let _from = moment(this.stats_data.start_date)
          while (!_from.isAfter(_to)) {
            if (!bar_data_type.categories.includes(_from.format(format)))
              bar_data_type.categories.push(_from.format(format));
            _from = _from.add(1, "day")
            bar_data_type.categories.sort()
          }
          return bar_data_type
        }
      case BarSupportedDateFormats.YEARMONTHDAYHOUR:
        {
          const _to = moment(this.stats_data.end_date)
          let _from = moment(this.stats_data.start_date).startOf("day")
          while (!_from.isAfter(_to)) {
            if (!bar_data_type.categories.includes(_from.format(format)))
              bar_data_type.categories.push(_from.format(format));
            _from = _from.add(1, "hour")
            bar_data_type.categories.sort()
          }
          return bar_data_type
        }
    }
  }

}

enum BarViewTypes {
  os = "os",
  cities = "cities",
  device = "device",
  browser = "browser",
  location = "location",
}

export interface BarDataTypeSeries {
  name: string;
  data: number[];
}
export interface BarDataType {
  categories: string[];
  series: BarDataTypeSeries[];
}

enum BarSupportedDateFormats {
  YEAR = "YYYY",
  YEARMONTH = "YYYY-MM",
  YEARMONTHDAY = "YYYY-MM-DD",
  YEARMONTHDAYHOUR = "YYYY-MM-DD HH",
}
