<div class="footer-wrapper">

  <div class="footer-contents" ngsReveal>
    <div class="container text-center">
      <h1 class="xl">{{footer_text}}</h1>
      <div #ref><ng-content></ng-content></div>
      <a *ngIf=" ! ref.children.length" class="btn btn-secondary btn-lg"
        [routerLink]="coreService.isLoggedIn() ? '/' : '/auth/signup'">
        Get Started for Free
        <i class="bi bi-chevron-right me-2"></i>
      </a>
    </div>
  </div>

  <div class="container footer">
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-5">
        <img loading="lazy" src="assets/logos/logo-sm.png" width="50">
        <h2 class="text-secondary mt-3">Create QR Code <br> with analytics</h2>
      </div>
      <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
        <div class="navs mb-4">
          <ul>
            <li>
              <b>COMPANY</b>
            </li>
            <li>
              <a [routerLink]="session_info?.last_routes.new_home || '/'">Home</a>
            </li>
            <li>
              <a routerLink="/pricing">Pricing</a>
            </li>
            <li>
              <a routerLink="/promotion/discounts">Promotions</a>
            </li>
            <li>
              <a routerLink="/referral">Referral Program</a>
            </li>
            <li>
              <a routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a routerLink="/terms">Terms of Service</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
        <div class="navs">
          <ul>
            <li>
              <b>SUPPORT</b>
            </li>
            <li>
              <a routerLink="/faq">FAQ</a>
            </li>
            <li>
              <a routerLink="/blog">Blog</a>
            </li>
            <li>
              <a routerLink="/support">Support</a>
            </li>
            <li>
              <a routerLink="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
        <div class="navs">
          <ul>
            <li>
              <b>GET IN TOUCH!</b>
            </li>
            <li>
              <a target="_blank" href="mailto:help@brandqrcodes.com">
                {{'help@brandqrcodes.com'}}
              </a>
            </li>
            <li>
              <div class="social-media">
                <a href="https://www.linkedin.com/company/brandqrcodes" class="me-2" target="_blank">
                  <i class="bi bi-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/@brandqrcodes/channels" class="me-2" target="_blank">
                  <i class="bi bi-youtube"></i>
                </a>
                <a href="https://www.facebook.com/brandqrcodes" class="me-2" target="_blank">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/brandqrcodes" class="me-2" target="_blank">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="https://twitter.com/brandqrcodes" class="me-2" target="_blank">
                  <i class="bi bi-twitter"></i>
                </a>
                <a href="https://reddit.com/r/brandqrcodes" class="me-2" target="_blank">
                  <i class="bi bi-reddit"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-12 text-center mt-5">
        <small>
          © {{current_year}} brandQRcodes | All rights reserved
        </small>
      </div>
    </div>
  </div>
</div>