<!-- https://github.com/bradvin/social-share-urls -->
<div class="dropdown share-dropdown" *ngIf="data">
  <ng-container *ngIf="is_sharable_by_navigator">
    <a [title]="tooltip" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top">
      <i (click)="shareWithNavigator()" [ngStyle]="{'color': text_color, 'font-size': size + 'px'}"
        class="bi bi-share {{_class}}"></i>
    </a>
  </ng-container>
  <ng-container *ngIf="!is_sharable_by_navigator">
    <div class="dropstart">
      <a [title]="tooltip" data-bs-toggle="dropdown">
        <i [ngStyle]="{'color': text_color, 'font-size': size + 'px'}" class="bi bi-share feather-share {{_class}}"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-end mt-2" aria-labelledby="actions">
        <a class="dropdown-item">
          <img loading="lazy" src="assets/social-media-logos/facebook.png"> Facebook
        </a>

        <a target="_blank" class="dropdown-item twitter-share-button"
          [href]="'https://twitter.com/intent/tweet?text=' + data.title + ' - ' + data.url">
          <img loading="lazy" src="assets/social-media-logos/twitter.png"> Twitter
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + data.url">
          <img loading="lazy" src="assets/social-media-logos/linkedin.png"> LinkedIn
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://reddit.com/submit?url=' + data.url + '&title=' + data.title">
          <img loading="lazy" src="assets/social-media-logos/reddit.png"> Reddit
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://www.tumblr.com/widgets/share/tool?canonicalUrl=' + data.url + '&title=' + data.title">
          <img loading="lazy" src="assets/social-media-logos/tumblr.png"> Tumblr
        </a>

        <a class="dropdown-item" target="_blank" [href]="'http://pinterest.com/pin/create/link/?url=' + data.url">
          <img loading="lazy" src="assets/social-media-logos/pinterest.png"> Pinterest
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://web.skype.com/share?url=' + data.url + '&text=' + data.title">
          <img loading="lazy" src="assets/social-media-logos/skype.png"> Skype
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://lineit.line.me/share/ui?url=' + data.url + '&text=' + data.title">
          <img loading="lazy" src="assets/social-media-logos/line.png"> Line
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'https://telegram.me/share/url?url=' + data.url + '&text=' + data.title">
          <img loading="lazy" src="assets/social-media-logos/telegram.png"> Telegram
        </a>

        <a class="dropdown-item" target="_blank"
          [href]="'mailto:?subject=' + data.title + '&body=' + data.title + ' - ' + data.url">
          <img loading="lazy" src="assets/social-media-logos/mail.png"> Email
        </a>
      </div>
    </div>
  </ng-container>
</div>