<div class="row g-3" [formGroup]="data">
    <div class="col-sm-12">
        <label class="form-label">Full name<span class="text-danger">*</span></label>
        <input type="text" formControlName="full_name" required class="form-control"
            [class.is-invalid]="data.get('full_name').hasError('required') && data.get('full_name').touched"
            placeholder="Full Name" maxlength="60">
        <p class="input-error" *ngIf="data.get('full_name').hasError('required') && data.get('full_name').touched">
            Full name is required!
        </p>
    </div>
    <div class="col-sm-6">
        <label class="form-label">Email Address<span class="text-danger">*</span></label>
        <input type="email"
            [class.is-invalid]="(data.get('email').hasError('required') || data.get('email').hasError('email')) && data.get('email').touched"
            formControlName="email" required class="form-control" placeholder="Email">
        <ng-container *ngIf="data.get('email').touched">
            <p class="input-error" *ngIf="data.get('email').hasError('required')">
                Email is required!
            </p>
            <p class="input-error" *ngIf="data.get('email').hasError('email')">
                Email is invalid!
            </p>
        </ng-container>
    </div>
    <div class="col-sm-6">
        <label class="form-label">Phone number</label>
        <input type="text" class="form-control"
            [class.is-invalid]="(data.get('mobile').hasError('required') || data.get('mobile').hasError('pattern')) && data.get('mobile').touched"
            formControlName="mobile" placeholder="01234567891 (Optional)">
        <ng-container *ngIf="data.get('mobile').touched">
            <p class="input-error" *ngIf="data.get('mobile').hasError('required')">
                Phone number is required!
            </p>
            <p class="input-error" *ngIf="data.get('mobile').hasError('pattern')">
                Phone number format is invalid!
            </p>
        </ng-container>
    </div>
    <div class="col-sm-12">
        <label class="form-label">Address<span class="text-danger">*</span></label>
        <input type="text" formControlName="address" required class="form-control"
            [class.is-invalid]="data.get('address').hasError('required') && data.get('address').touched"
            placeholder="Full Billing Address">
        <p class="input-error" *ngIf="data.get('address').hasError('required') && data.get('address').touched">
            Billing address is required!
        </p>
    </div>
    <div class="col-sm-6">
        <label class="form-label">City<span class="text-danger">*</span></label>
        <input type="text" formControlName="city" required class="form-control"
            [class.is-invalid]="data.get('city').hasError('required') && data.get('city').touched" placeholder="City">
        <p class="input-error" *ngIf="data.get('city').hasError('required') && data.get('city').touched">
            City is required!
        </p>
    </div>
    <div class="col-sm-6">
        <label class="form-label">Zip / Postal code</label>
        <input type="text" formControlName="zip" class="form-control" placeholder="Zip / Postal Code">
    </div>
    <div class="col-sm-6">
        <label class="form-label">State<span class="text-danger">*</span></label>
        <input type="text" formControlName="state" required class="form-control"
            [class.is-invalid]="data.get('state').hasError('required') && data.get('state').touched"
            placeholder="State / Province">
        <p class="input-error" *ngIf="data.get('state').hasError('required') && data.get('state').touched">
            State / province is required!
        </p>
    </div>
    <div class="col-sm-6">
        <label class="form-label">Country<span class="text-danger">*</span></label>
        <ng-select placeholder="Country" formControlName="country" required
            [class.is-invalid]="data.get('country').hasError('required') && data.get('country').touched"
            appearance="outline" [clearable]="false">
            <ng-option [value]="country.name" *ngFor="let country of functionService.getCountries()">
                {{country.name}}</ng-option>
        </ng-select>
        <p class="input-error" *ngIf="data.get('country').hasError('required') && data.get('country').touched">
            Country is required!
        </p>
    </div>
</div>