import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { QRLogo } from 'shared-library';

@Component({
  selector: 'app-svg-icon',
  template: `<div [class]="class_identifier" [innerHTML]="svgIcon"></div>`,
  styles: [`.d-span-shared-svg {
        width: 35px
    }`],
})
export class SvgIconComponent implements OnInit, OnDestroy {

  class_identifier = "d-span-shared-svg"

  @Input() svg: QRLogo
  @Input() color = "#000000"
  @Input() event_color: Observable<string>;

  timer_cc: any
  svgIcon: SafeHtml
  private subscriptions: Subscription[] = []

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    if (this.event_color) {
      this.subscriptions.push(this.event_color.subscribe(ec => {
        this.color = ec;
        this.changeColor()
      }))
    }
    if (this.svg) {
      if (this.svg.svg) {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(this.svg.svg)
        this.changeColor()
      } else if (this.svg.url) {
        this.subscriptions.push(this.httpClient.get(this.svg.url, { responseType: 'text' }).subscribe(value => {
          this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value)
          this.changeColor()
        }))
      }
    }
  }

  private changeColor() {
    clearTimeout(this.timer_cc)
    this.timer_cc = setTimeout(() => {
      const elemts = Array.from(document.querySelectorAll(`.${this.class_identifier} path,.${this.class_identifier} rect,.${this.class_identifier} polygon,.${this.class_identifier} circle,.${this.class_identifier} g`))
      for (let item of elemts) {
        if (item.hasAttribute("fill")) item.setAttribute("fill", this.color)
      }
    }, 500)
  }
}
