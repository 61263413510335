<div class="upgrade-wrapper animated fadeIn" *ngIf="view_type === view_types.FULL_PAGE">
  <div class="upgrade-contents">
    <img loading="lazy" src="assets/upgrade.png">
    <div class="message">
      <h6 [innerHTML]="message"></h6>
    </div>
    <ng-container
      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
      <button class="btn btn-secondary mt-3" (click)="gotoPricing()">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
          class="bi bi-arrow-up-circle me-1" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
        </svg>
        Upgrade Plan</button>
    </ng-container>
  </div>
</div>

<div class="w-100 animated fadeIn" *ngIf="view_type === view_types.INLINE">
  <div class="banner-wrapper banner-wrapper-info {{inline_class}} d-flex align-items-center">
    <div class="flex-shrink-0">
      <i class="bi bi-arrow-up-circle-fill"></i>
    </div>
    <div class="flex-grow-1 ms-3" [innerHTML]="message"></div>
    <ng-container
      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
      <a (click)="gotoPricing()" class="aright">Get started</a>
    </ng-container>
  </div>
</div>