<div class="preview-wrapper animated fadeIn" *ngIf="data?.coupons_data"
    [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}">
    <div class="preview-body">
        <div class="preview-header text-center">
            <h6 [ngStyle]="{'color': data.coupons_data.colors.text_color}">{{data.coupons_data.company_name}}</h6>
        </div>

        <div class="coupon" *ngIf="!data.coupons_data.coupon.showing; else isShowing">
            <div class="coupon-header">
                <img loading="lazy" [src]="data.coupons_data.banner_url" width="100%">
                <div class="coupon-caption text-truncate" *ngIf="data.coupons_data.coupon.show"
                    [ngStyle]="{'background-color': data.coupons_data.colors.secondary_color, 'color': data.coupons_data.colors.text_color}">
                    <span
                        *ngIf="'Custom' != data.coupons_data.coupon.headline; else isCustom">{{data.coupons_data.coupon.headline}}</span>
                    <ng-template #isCustom>{{data.coupons_data.coupon.custom_headline}}</ng-template>
                </div>
            </div>
            <div class="coupon-body">

                <b>{{data.coupons_data.headings.main}}</b>
                <p>{{data.coupons_data.headings.meta}}</p>

                <button class="btn btn-block mt-3"
                    [ngStyle]="{'color': data.coupons_data.colors.text_color, 'background-color': data.coupons_data.colors.secondary_color}"
                    (click)="data.coupons_data.coupon.showing = true">{{data.coupons_data.ctas.reveal_coupon.text}}</button>

                <div class="circle-wrapper">
                    <span class="circle circle-1"
                        [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}"></span>
                    <span class="circle circle-2"
                        [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}"></span>
                    <span class="circle circle-3"
                        [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}"></span>
                    <span class="circle circle-4"
                        [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}"></span>
                    <span class="circle circle-5"
                        [ngStyle]="{'background-color': data.coupons_data.colors.primary_color}"></span>
                </div>
            </div>
        </div>

        <ng-template #isShowing>
            <div class="coupon-code animated zoomIn">
                <div class="header">
                    <i (click)="toggleCodeReveal()" class="bi bi-x bi-lg"></i>
                </div>
                <small>Coupon Code</small>

                <ng-container
                    *ngIf="data.coupons_data.coupon.expiry_date && !functions.dateFuture(data.coupons_data.coupon.expiry_date); else notExpired">
                    <br>
                    <i class="bi bi-clock f-xl text-danger my-3"></i><br>
                    <p class="text-danger">Valid until
                        <b>{{functions.formatDate(data.coupons_data.coupon.expiry_date)}}</b>
                    </p>
                    <small>Unfortunately, this coupon code is no longer valid as the offer period has passed.</small>
                </ng-container>

                <ng-template #notExpired>
                    <h4 [ngStyle]="{'color': data.coupons_data.colors.secondary_color}">
                        {{data.coupons_data.coupon.code}}</h4>
                    <small *ngIf="data.coupons_data.coupon.expiry_date">
                        <i class="bi bi-clock f-sm me-1"></i> Valid until
                        <b>{{functions.formatDate(data.coupons_data.coupon.expiry_date)}}</b>
                    </small>

                    <div class="my-4" *ngIf="data.coupons_data.coupon.code.trim()">
                        <button class="btn btn-sm btn-round"
                            (click)="localCopyToClipboard(data.coupons_data.coupon.code.trim())"
                            [ngStyle]="{'background-color': data.coupons_data.colors.secondary_color, 'color': data.coupons_data.colors.text_color}">
                            <ng-container *ngIf="!code_copied; else codeCopied">
                                <i class="bi bi-clipboard me-1"></i> Copy code
                            </ng-container>
                            <ng-template #codeCopied>
                                <i class="bi bi-check2 me-1"></i> Copied
                            </ng-template>
                        </button>
                    </div>
                </ng-template>

                <a class="btn btn-block mt-3" [href]="functions.forceUrl(data.coupons_data.ctas.redeem_coupon.url)"
                    target="_blank" *ngIf="data.coupons_data.ctas.redeem_coupon.show"
                    [ngStyle]="{'background-color': data.coupons_data.colors.secondary_color, 'color': data.coupons_data.colors.text_color}">{{data.coupons_data.ctas.redeem_coupon.text}}</a>

                <a class="btn btn-block mt-2" [href]="functions.forceUrl(data.coupons_data.ctas.location.url)"
                    target="_blank" *ngIf="data.coupons_data.ctas.location.show && data.coupons_data.ctas.location.text"
                    [ngStyle]="{'border-color': data.coupons_data.colors.secondary_color, 'color': data.coupons_data.colors.secondary_color}">{{data.coupons_data.ctas.location.text}}</a>

                <div class="pt-3" *ngIf="data.coupons_data.tnc">
                    <small>
                        <a [ngStyle]="{'color': data.coupons_data.colors.secondary_color}" data-bs-toggle="collapse"
                            href="#collapsetc" role="button" aria-expanded="false" aria-controls="collapsetc">
                            Terms & Conditions
                        </a>
                    </small>
                    <div class="collapse" id="collapsetc">
                        <hr>
                        <small class="mt-1">{{data.coupons_data.tnc}}</small>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.coupons_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.coupons_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.coupons_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.coupons_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.coupons_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.coupons_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>