<div [ngSwitch]="content">
    <div class="row my-5" *ngSwitchCase="'discount'">
        <div class="col-md-12 text-center">
            <h1>How It Works</h1>
            <p class="mb-3 fs-5">
                Applying a discount code at BrandQRcodes.com is quick and easy. Here's how:
            </p>
        </div>
        <div class="col-md-3">
            <div class="d-flex justify-content-center text-center flex-column">
                <div>
                    <img loading="lazy" src="assets/landing/_discountStep1.png" width="114" height="114"
                        alt="How Discount Works step 1">
                </div>
                <h6><b>Sign Up</b></h6>
                <p>
                    Getting started is a breeze. Simply sign up for a BrandQRcodes.com account
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="d-flex justify-content-center text-center flex-column">
                <div>
                    <img loading="lazy" src="assets/landing/_discountStep2.png" width="114" height="114"
                        alt="How Discount Works step 2">
                </div>
                <h6><b>Visit Pricing</b></h6>
                <p>
                    Select the plan you want to purchase from our <a routerLink="/pricing">pricing</a> page.
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="d-flex justify-content-center text-center flex-column">
                <div>
                    <img loading="lazy" src="assets/landing/_discountStep3.png" width="114" height="114"
                        alt="How Discount Works step 3">
                </div>
                <h6><b>Apply Discount Code</b></h6>
                <p>During the checkout process, you will see an option to enter your discount code. Enter the
                    discount code and click "<strong>Apply</strong>".
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="d-flex justify-content-center text-center flex-column">
                <div>
                    <img loading="lazy" src="assets/landing/_discountStep4.png" width="114" height="114"
                        alt="How Discount Works step 4">
                </div>
                <h6><b>Complete Checkout</b></h6>
                <p>Your total will be updated to reflect the discounted price.
                </p>
            </div>
        </div>
    </div>

    <div class="row my-5" *ngSwitchCase="'referral'">
        <div class="col-md-12 text-center pt-5">
            <h1>How It Works</h1>
            <div class="row">
                <div class="col-md-3">
                    <div class="d-flex justify-content-center text-center flex-column">
                        <div>
                            <img loading="lazy" src="assets/landing/_referralStep1.png" width="114" height="114"
                                alt="Referral step 1">
                        </div>
                        <h6><b>Sign Up</b></h6>
                        <p>
                            Getting started is a breeze. Simply sign up for a BrandQRcodes.com account, and you'll get
                            a unique referral link when you go to the "Referral" page from your account dashboard.
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="d-flex justify-content-center text-center flex-column">
                        <div>
                            <img loading="lazy" src="assets/landing/_referralStep2.png" width="114" height="114"
                                alt="Referral step 2">
                        </div>
                        <h6><b>Share Your Link</b></h6>
                        <p>
                            Start sharing your referral link with your friends, family, colleagues, and social media
                            followers. Spread the word about our fantastic products and services.
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="d-flex justify-content-center text-center flex-column">
                        <div>
                            <img loading="lazy" src="assets/landing/_referralStep3.png" width="114" height="114"
                                alt="Referral step 3">
                        </div>
                        <h6><b>Earn Commission</b></h6>
                        <p>Every time one of your referred users, you earn a generous 20% commission on the
                            total sale amount. It's that easy!
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="d-flex justify-content-center text-center flex-column">
                        <div>
                            <img loading="lazy" src="assets/landing/_referralStep4.png" width="114" height="114"
                                alt="Referral step 4">
                        </div>
                        <h6><b>One-Year Earnings</b></h6>
                        <p>The best part? You continue to earn that 20% commission on all their purchases for
                            the next one year. This means recurring income without any additional effort on your
                            part.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>