<div class="sorting mb-5" [class.processing]="processing">

    <div class="alert-top">
        <div class="alert alert-danger d-flex align-items-center animated fadeIn" role="alert" *ngIf="selection_error">
            <div class="bi bi-exclamation-circle-fill"></div>
            <div><strong>Wait!</strong> {{ selection_error }}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-10">
            <div class="dashboard-sorting">

                <ng-container>
                    <div class="custom-tooltip">
                        <button class="btn btn-sm" [class.active]="bar_types.visitsummarybyhour === bar_view_showing"
                            [class.disabled]="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyhour) === -1"
                            (click)="changeTime(bar_types.visitsummarybyhour)">
                            <i class="bi bi-clock me-1"></i> Hourly
                        </button>

                        <span class="tooltip-content content-top"
                            *ngIf="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyhour) === -1">
                            <img loading="lazy" src="assets/lock-icon.svg">
                            <span class="tooltip-text">{{ feature_upgrade_text }}</span>
                            <ng-container
                                *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                                <br>
                                <a routerLink="/pricing" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14" fill="currentColor" class="bi bi-arrow-up-circle me-1"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg> Upgrade</a>
                            </ng-container>
                        </span>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="custom-tooltip">
                        <button class="btn btn-sm" [class.active]="bar_types.visitsummarybyday === bar_view_showing"
                            [class.disabled]="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyday) === -1"
                            (click)="changeTime(bar_types.visitsummarybyday)">
                            <i class="bi bi-sun me-1"></i> Daily
                        </button>
                        <span class="tooltip-content content-top"
                            *ngIf="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyday) === -1">
                            <img loading="lazy" src="assets/lock-icon.svg">
                            <span class="tooltip-text">{{ feature_upgrade_text }}</span>
                            <ng-container
                                *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                                <br>
                                <a routerLink="/pricing" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14" fill="currentColor" class="bi bi-arrow-up-circle me-1"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg> Upgrade</a>
                            </ng-container>
                        </span>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="custom-tooltip">
                        <button class="btn btn-sm" [class.active]="bar_types.visitsummarybymonth === bar_view_showing"
                            [class.disabled]="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybymonth) === -1"
                            (click)="changeTime(bar_types.visitsummarybymonth)">
                            <i class="bi bi-calendar-month me-1"></i> Monthly
                        </button>
                        <span class="tooltip-content content-top"
                            *ngIf="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybymonth) === -1">
                            <img loading="lazy" src="assets/lock-icon.svg">
                            <span class="tooltip-text">{{ feature_upgrade_text }}</span>
                            <ng-container
                                *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                                <br>
                                <a routerLink="/pricing" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14" fill="currentColor" class="bi bi-arrow-up-circle me-1"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg> Upgrade</a>
                            </ng-container>
                        </span>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="custom-tooltip">
                        <button class="btn btn-sm" [class.active]="bar_types.visitsummarybyyear === bar_view_showing"
                            [class.disabled]="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyyear) === -1"
                            (click)="changeTime(bar_types.visitsummarybyyear)">
                            <i class="bi bi-calendar-check me-1"></i> Yearly
                        </button>
                        <span class="tooltip-content content-top"
                            *ngIf="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.visitsummarybyyear) === -1">
                            <img loading="lazy" src="assets/lock-icon.svg">
                            <span class="tooltip-text">{{ feature_upgrade_text }}</span>
                            <ng-container
                                *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                                <br>
                                <a routerLink="/pricing" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14" fill="currentColor" class="bi bi-arrow-up-circle me-1"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg> Upgrade</a>
                            </ng-container>
                        </span>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="custom-tooltip">
                        <button class="btn btn-sm"
                            *ngIf="!user_details.current_organization_details.subscription_details.subscription.stats.includes(bar_types.linksummarycustom)"
                            [class.disabled]="user_details.current_organization_details.subscription_details.subscription.stats.indexOf(bar_types.linksummarycustom) === -1">
                            <i class="bi bi-calendar-check me-1"></i> Custom
                        </button>

                        <div class="date-wrapper"
                            *ngIf="user_details.current_organization_details.subscription_details.subscription.stats.includes(bar_types.linksummarycustom)"
                            [class.active]="bar_types.linksummarycustom === bar_view_showing">
                            <i class="bi bi-calendar3 me-1"></i>
                            <input type="text" ngxDaterangepickerMd [singleDatePicker]="false" [closeOnAutoApply]="true"
                                [class.disabled]="!user_details.current_organization_details.subscription_details.subscription.stats.includes(bar_types.linksummarycustom)"
                                opens="right" displayFormat="YYYY-MM-DD" (datesUpdated)="setDate($event)"
                                [maxDate]="maxDate" class="form-control datepicker-calendar-icon" name="bsValue"
                                placeholder="Select visit range" [autoApply]="true" [(ngModel)]="bsValue" />
                        </div>

                        <span class="tooltip-content content-top"
                            *ngIf="!user_details.current_organization_details.subscription_details.subscription.stats.includes(bar_types.linksummarycustom)">
                            <img loading="lazy" src="assets/lock-icon.svg">
                            <span class="tooltip-text">{{ feature_upgrade_text }}</span>
                            <ng-container
                                *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                                <br>
                                <a routerLink="/pricing" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="14" fill="currentColor" class="bi bi-arrow-up-circle me-1"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg> Upgrade</a>
                            </ng-container>
                        </span>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="col-md-2 text-end">
            <div class="filter-select-wrapper">
                <ng-select appearance="outline" [(ngModel)]="bar_view" (change)="_prepareBar()" [clearable]="false"
                    [searchable]="false">
                    <ng-option [value]="bar_view_types.cities">Cities <i class="bi bi-building"></i></ng-option>
                    <ng-option [value]="bar_view_types.location">Countries <i class="bi bi-geo-alt"></i></ng-option>
                    <ng-option [value]="bar_view_types.os">Os <i class="bi bi-display"></i></ng-option>
                    <ng-option [value]="bar_view_types.device">Device <i class="bi bi-phone"></i></ng-option>
                    <ng-option [value]="bar_view_types.browser">Browser <i class="bi bi-window"></i></ng-option>
                </ng-select>
                <i class="bi bi-filter filter"></i>
            </div>
        </div>
    </div>

    <app-loading *ngIf="processing else notprocessing"></app-loading>
    <ng-template #notprocessing>
        <div [id]="console_id"></div>
    </ng-template>