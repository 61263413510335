import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QrGeneratorSOCIALS_TEMPLATE } from '@app/store/models/qr-generator-socials-template.interface';
import { Subscription, Observable } from 'rxjs';
import { QrGeneratorSOCIALS, SocialsInterfaceTypes } from 'shared-library';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';

@Component({
  selector: 'app-shared-qr-customize-social-media-links',
  templateUrl: './shared-qr-customize-social-media-links.component.html',
  styleUrls: ['./shared-qr-customize-social-media-links.component.scss']
})
export class SharedQrCustomizeSocialMediaLinksComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @Input() socials: QrGeneratorSOCIALS[] = [];

  @Input() _icon_color = "#6C6F74";
  @Output() _qr_clicked = new EventEmitter<boolean>();
  templates = this.qrGeneratorService.getSocialTemplates();
  @Input() event_socials: Observable<QrGeneratorSOCIALS[]>;

  constructor(
    public functions: FunctionsService,
    private functionService: FunctionsService,
    public qrGeneratorService: UserQrGeneratorService
  ) { }

  ngOnInit(): void {
    this.functionService.initializeTooltip()
    if (this.event_socials) {
      this.subscriptions.push(this.event_socials.subscribe(es => this.socials = es))
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  findTemplate(type: SocialsInterfaceTypes): QrGeneratorSOCIALS_TEMPLATE {
    return this.templates.find(t => t.type === type)
  }

}
