import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Tab } from '@app/store/models/tab.interface';
import { AppState } from '@app/store/states/app.states';
import * as AppActions from '@app/store/actions/app.actions';
import { IAppConfig } from '@app/store/models/config.interface';
import { APP_CONFIG } from 'src/app/modules/core/config/config';
import { selectAppState } from '@app/store/selectors/app.selectors';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { UserTabRootPages } from '@app/store/enums/user-tab-root-pages.enum';
import { UserTabChildPages } from '@app/store/enums/user-tab-child-pages.enum';
import { PaymentService } from 'src/app/modules/core/services/payment.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { SeoEventNames, SeoService } from '@app/modules/core/services/seo.service';
import { UpgradeRequiredViewTypes } from '@app/store/enums/upgrade-required-view-types.enum';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { dynamicQRLeft, ProductSubscriptionDurations, Pricings, PublicUserDetails, SystemUserTypes } from 'shared-library';

declare const bootstrap: any

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements OnInit, OnDestroy {

  toggle = false
  processing = false
  toggleSidebar = false
  modal_element: any;
  user_tabs: Tab[] = []

  selected_more_scan_price: Pricings
  appState$ = this.store.select(selectAppState);

  session_info: SessionInfo
  user_page: UserTabRootPages
  private subscriptions: Subscription[] = []
  user_details_dup: PublicUserDetails;
  @Input() user_details: PublicUserDetails = null;

  user_tab_child_pages = {
    billing: UserTabChildPages.BILLING,
    profile: UserTabChildPages.PROFILE,
  }

  upgrade_ui_view_type = UpgradeRequiredViewTypes.INLINE

  constructor(
    private store: Store,
    private router: Router,
    private seoService: SeoService,
    private httpClient: HttpClient,
    public coreService: CoreService,
    public functionService: FunctionsService,
    public readonly paymentService: PaymentService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
    }))
    if (this.session_info.user_details.user_category !== SystemUserTypes.ADMIN) {
      let tabs_result = this.coreService.userManagementTabs(this.session_info, this.session_info.user_details)
      if (tabs_result) {
        this.user_page = tabs_result.page
        this.user_tabs = tabs_result.tabs
      }
    }
  }

  ngOnInit(): void {
    if (!this.user_details) this.user_details = JSON.parse(JSON.stringify(this.session_info.user_details))
    this.user_details_dup = JSON.parse(JSON.stringify(this.user_details))
    this.getPricings()
    this.functionService.initializeTooltip()
  }

  openCanvas(id: string) {
    this.modal_element = new bootstrap.Offcanvas(document.getElementById(id), {
      keyboard: false
    })
    this.modal_element.show()
  }

  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe())
  }

  isActive(page: string): boolean {
    return window.location.href.includes(`/${page}`)
  }

  private async getPricings() {
    if (!this.coreService.hasPermission(`${this.session_info.user_details.user_category}.USERMANAGEMENT.PAYMENT`)) return
    if (this.session_info.user_details.user_category !== SystemUserTypes.ADMIN) {
      const url = this.config.apiEndpoints.user.pricings.replace("/{id}", ``)
      if (!this.session_info.user_details.current_organization_details.subscription_details.subscription.unlimited_scans) {
        this.store.dispatch(AppActions.fetchProductScansPrices({ url: `${url}more_scans` }));
      }
      this.store.dispatch(AppActions.fetchSubscriptionPrices({ url: `${url}plan_subscription` }));
    }
  }

  findNextPlan(app_state: AppState): Pricings {
    return app_state.data.cache.products.plans.find(p => p.product_code === this.user_details.current_organization_details.subscription_details.requires_upgrade && p.plan.durations.in === ProductSubscriptionDurations.YEAR)
  }

  startPayment(plan: Pricings = null) {

    if (plan && !this.user_details.current_organization_details.subscription_details.subscription.price) {
      // this.seoService.eventEmitter(SeoEventNames.UserActions, "clickedTryNewPlan", plan.title, {
      //   id: plan.id,
      //   price: plan.price,
      //   title: plan.title,
      //   currency: plan.currency,
      // })
      return this.paymentService.startPayment(plan)
    }

    // this.seoService.eventEmitter(SeoEventNames.UserActions, "clickedTryNewPlan", "UnknownPlan")
    this.router.navigateByUrl("/pricing")
  }

  dynamicQRLeftLoc() {
    return dynamicQRLeft(this.user_details.current_organization_details)
  }

  async subscribeToAlert() {
    if (this.processing) return
    if (JSON.stringify(this.user_details.current_organization_details.current_team_details.report) === JSON.stringify(this.user_details_dup.current_organization_details.current_team_details.report)) {
      // Nothing changed
      this.modal_element?.hide()
      return
    }

    this.processing = true
    try {
      const url = this.session_info.user_details.user_category === SystemUserTypes.ADMIN ? this.config.apiEndpoints.user.workspaces.list.replace("/{id}", `/${this.user_details.id}`) : this.config.apiEndpoints.user.workspaces.list.replace("/{id}", ``)
      await this.httpClient.post(`${url}/${this.user_details_dup.current_organization_details.current_team_details.id}/report-subscription`, this.user_details_dup.current_organization_details.current_team_details.report).toPromise()
      this.functionService.showSuccessMessage(`Report preference successfully updated.`)
      this.modal_element?.hide()

      this.user_details.current_organization_details.current_team_details.report = JSON.parse(JSON.stringify(this.user_details_dup.current_organization_details.current_team_details.report))
      if (this.session_info.user_details.id === this.user_details.id) {
        this.session_info.user_details.current_organization_details.current_team_details.report = this.user_details.current_organization_details.current_team_details.report
        this.coreService.setSessionData(this.session_info, false)
      }

    } catch (error) {
    }
    this.processing = false
  }
}
