import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, SocialsInterfaceTypes } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-social-media',
  templateUrl: './shared-qr-preview-social-media.component.html',
  styleUrls: ['./shared-qr-preview-social-media.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewSocialMediaComponent implements OnInit {


  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    public qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.SOCIAL_MEDIA)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.socials_data) {
          this.event_additional_settings_obs.next({
            text_color: d.socials_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.socials_data = this.qrGeneratorService.initSocialMediaCategoryData()

          let social_templates = this.qrGeneratorService.getSocialTemplates()

          let position = 1
          let type = SocialsInterfaceTypes.website
          let template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: "Visit us online",
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "www.your-website.com",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.mail
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "example@email.com",
            meta_title: "Contact us",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.instagram
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Follow us on IG!",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.youtube
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Subscribe to our channel",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.facebook
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Find us on Facebook",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.linkedin
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Add us on LinkedIn",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })


          type = SocialsInterfaceTypes.twitter
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Follow us on Twitter",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.tiktok
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Us on TikTok",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.onlyfans
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "",
            meta_title: "Become a fan",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          type = SocialsInterfaceTypes.whatsapp
          template = social_templates.find(st => st.type == type)
          this.data.socials_data.socials.push({
            title: template.name,
            type,
            name: template.name,
            custom_title: "",
            email: "",
            mobile: "01234567891",
            meta_title: "Text us on WhatsApp",
            value: "",
            href: "",
            position: position++,
            favicon: template.favicon
          })

          this.data.socials_data.colors.primary_color = "#34047A"
          this.data.socials_data.colors.secondary_color = "#FFC500"
          this.data.socials_data.colors.text_color = "#4D4D4D"
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
