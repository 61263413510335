<div class="load-content" *ngIf="type === 'default'">
    <div class="load-wrapp">
        <div class="loading {{_class}}"></div>
    </div>
</div>

<div class="loading-page" *ngIf="type === 'percentage'">
    <div class="counter">
        <p [ngStyle]="p_style">{{processing}}</p>
        <h1>{{percentage}}%</h1>
        <hr [ngStyle]="hr_style" />
    </div>
</div>