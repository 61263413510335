<div class="preview-wrapper animated fadeIn" *ngIf="data && data.sms_data">
    <div class="mb-3">
        <small class="mb-1">Number</small>
        <input type="text" readonly [(ngModel)]="data.sms_data.mobile"
            class="form-control form-control-sm text-secondary">
    </div>
    <div class="mb-3">
        <small class="mb-1">Message</small>
        <textarea class="form-control form-control-sm" readonly [(ngModel)]="data.sms_data.body" rows="5"></textarea>
    </div>
    <button class="btn btn-primary btn-block mt-4">Send SMS</button>
</div>