import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SeoService } from '@app/modules/core/services/seo.service';
import { CoreService } from '@app/modules/core/services/core.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { PublicInformationPageMessageTypes } from './public-information-page.enum';

declare const confetti: any

@Component({
  selector: 'app-public-information-page',
  templateUrl: './public-information-page.component.html',
  styleUrls: ['./public-information-page.component.scss']
})
export class PublicInformationPageComponent implements OnInit, OnDestroy {

  session_info: SessionInfo
  private subscriptions: Subscription[] = []
  @Input() message_mode: PublicInformationPageMessageTypes;
  _messages = {
    DISCOUNT: PublicInformationPageMessageTypes.DISCOUNT,
    QR_NOT_FOUND: PublicInformationPageMessageTypes.QR_NOT_FOUND,
    QR_IS_DISABLED: PublicInformationPageMessageTypes.QR_IS_DISABLED,
    QR_IS_SCANNABLE: PublicInformationPageMessageTypes.QR_IS_SCANNABLE,
    QR_VISITS_REACHED: PublicInformationPageMessageTypes.QR_VISITS_REACHED,
    NEWSLETTERS_UNSUBSCRIPTION: PublicInformationPageMessageTypes.NEWSLETTERS_UNSUBSCRIPTION
  }

  constructor(
    private route: ActivatedRoute,
    private seoService: SeoService,
    private coreService: CoreService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    if (!this.message_mode) {
      const currentUrl = this.route.snapshot.url.join('/');
      if (currentUrl?.toLowerCase()?.trim() === PublicInformationPageMessageTypes.QR_IS_SCANNABLE) {
        this.message_mode = PublicInformationPageMessageTypes.QR_IS_SCANNABLE
        this.doConfetti()
      }

      if (currentUrl?.toLowerCase()?.trim() === PublicInformationPageMessageTypes.NEWSLETTERS_UNSUBSCRIPTION) {
        this.message_mode = PublicInformationPageMessageTypes.NEWSLETTERS_UNSUBSCRIPTION
      }
    }

    if (this.message_mode) {
      this.seoService.generateMetaTags(this.message_mode)
    }

  }


  private doConfetti() {
    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      if (confetti) {

        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
      }
    }, 250);
  }
}


