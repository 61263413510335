<ng-container *ngIf="data?.restaurant_menu_data">
  <div #previewWrapper class="preview-wrapper animated fadeIn">

    <section class="order-summary" *ngIf="active_view === 'summary'">
      <div class="back-button" (click)="changeView('products')">
        <i class="bi bi-arrow-left"></i>
      </div>
      <b class="title mb-2">Order Summary</b>
      <ng-container *ngFor="let cart_item of cart">
        <div class="menu-item" *ngIf="cart_item.quantity">
          <img loading="lazy" *ngIf="cart_item.thumbnail" (click)="gotoProduct(cart_item.item_id)"
            [src]="cart_item.thumbnail" [alt]="cart_item.title" class="item-image">
          <div class="item-details">
            <b (click)="gotoProduct(cart_item.item_id)" class="item-title">{{cart_item.title}}</b>
            <small (click)="gotoProduct(cart_item.item_id)"
              *ngIf="cart_item.descriptions.length">{{cart_item.descriptions.join(', ')}}</small>
            <b (click)="gotoProduct(cart_item.item_id)" class="item-price"
              [ngStyle]="{'color': data.restaurant_menu_data.colors.secondary_color}">{{functions.toFixed(cart_item.unit_price
              *
              cart_item.quantity) | currency:
              data.restaurant_menu_data.menu.currency}}</b>
            <div class="item-to-cart">
              <button (click)="cart_item.quantity = cart_item.quantity - (cart_item.quantity > 0 ? 1 : 0)"><i
                  class="bi bi-dash"></i></button>
              <strong>{{cart_item.quantity}}</strong>
              <button (click)="cart_item.quantity = cart_item.quantity + 1"><i class="bi bi-plus"></i></button>
            </div>
          </div>
        </div>
      </ng-container>

      <hr>
      <div class="d-flex justify-content-between">
        <b>Amount<i class="bi bi-info-circle-fill small ms-2"
            [ngStyle]="{'color': data.restaurant_menu_data.colors.primary_color}" title="Other charges may apply."
            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top"></i></b>
        <b [ngStyle]="{'color': data.restaurant_menu_data.colors.primary_color}">{{cartTotal() | currency:
          data.restaurant_menu_data.menu.currency}}</b>
      </div>
    </section>

    <section class="menu-item-details" *ngIf="active_view === 'product'">
      <ng-container *ngIf="product">
        <div class="item-image-wrapper" *ngIf="product.thumbnail">
          <div class="back-button" (click)="changeView('products')">
            <i class="bi bi-arrow-left"></i>
          </div>
          <img loading="lazy" [src]="product.thumbnail" [alt]="product.headings.main" class="item-image">
        </div>
        <div class="item-body">
          <div class="back-button" (click)="changeView('products')" *ngIf="!product.thumbnail">
            <i class="bi bi-arrow-left"></i>
          </div>
          <div class="item-details pb-0">
            <b class="item-title mb-1">{{product.headings.main}}</b><br>
            <p class="item-description mb-1">{{product.headings.meta}}</p>
            <b class="item-price"
              [ngStyle]="{'color': data.restaurant_menu_data.colors.secondary_color}">{{product.price
              | currency: data.restaurant_menu_data.menu.currency}}</b>
          </div>
          <hr>
          <ng-container *ngIf="product.allergens.length">
            <b class="section-name">Allergens Present</b>
            <p class="section-description">{{product.allergens.join(', ')}}</p>
            <hr>
          </ng-container>

          <ng-container *ngFor="let sub of product.subs">
            <ng-container *ngIf="sub.show">
              <section class="mb-3">
                <div class="d-flex justify-content-between mb-1">
                  <b class="section-name">{{sub.title}}</b>
                  <div><span class="badge rounded-pill text-bg-light">{{sub.is_required ? 'Required' :
                      'Optional'}}</span></div>
                </div>

                <ng-container *ngFor="let option of sub.options">
                  <ng-container *ngIf="!sub.is_multi_select">
                    <div class="form-check ps-0">
                      <input class="form-check-input"
                        (change)="reCalculatePrice(); potential_cart_entry.descriptions[0] = option.title"
                        [(ngModel)]="potential_cart_entry.subs[sub.id.toString()][0]" [value]="option.id" type="radio"
                        [name]="'flexRadioDefault' + sub.id" [id]="'flexRadioDefault' + sub.id + option.id">
                      <label class="form-check-label" [for]="'flexRadioDefault' + sub.id + option.id">
                        {{option.title}} <b class="ms-2"
                          [ngStyle]="{'color': data.restaurant_menu_data.colors.secondary_color}"
                          *ngIf="option.price > 0">{{option.price | currency:
                          data.restaurant_menu_data.menu.currency}}</b>
                      </label>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="sub.is_multi_select">
                    <div class="checkbox-wrapper">
                      <label [for]="'flexCheckboxDefault' + sub.id + option.id">
                        <span class="checkbox">
                          <input [checked]="potential_cart_entry.subs[sub.id.toString()].includes(option.id)"
                            (change)="pushPopOption(option, potential_cart_entry.subs[sub.id.toString()]); reCalculatePrice()"
                            [value]="option.id" type="checkbox" [name]="'flexCheckboxDefault' + sub.id"
                            [id]="'flexCheckboxDefault' + sub.id + option.id">
                          <svg>
                            <use xlink:href="#checkbox" class="checkbox"></use>
                          </svg>
                        </span>
                        {{option.title}} <b class="ms-2"
                          [ngStyle]="{'color': data.restaurant_menu_data.colors.secondary_color}"
                          *ngIf="option.price > 0">{{option.price | currency:
                          data.restaurant_menu_data.menu.currency}}</b></label>
                    </div>
                  </ng-container>

                </ng-container>
              </section>
              <hr>
            </ng-container>
          </ng-container>

          <div class="item-to-cart">
            <button
              (click)="potential_cart_entry.quantity = potential_cart_entry.quantity - (potential_cart_entry.quantity > 1 ? 1 : 0); reCalculatePrice()"><i
                class="bi bi-dash"></i></button>
            <strong>{{potential_cart_entry.quantity}}</strong>
            <button (click)="potential_cart_entry.quantity = potential_cart_entry.quantity + 1; reCalculatePrice()"><i
                class="bi bi-plus"></i></button>
          </div>
        </div>

        <div class="add-to-cart d-grid">
          <button class="btn btn-block" (click)="addToCart()"
            [ngStyle]="{'color': data.restaurant_menu_data.colors.text_color, 'background-color': data.restaurant_menu_data.colors.secondary_color}">
            Add
            {{potential_cart_entry.quantity}} to cart • {{functions.toFixed(potential_cart_entry.unit_price *
            potential_cart_entry.quantity) | currency:
            data.restaurant_menu_data.menu.currency}}</button>
        </div>
      </ng-container>
    </section>

    <section *ngIf="active_view === 'products'">
      <img loading="lazy" [src]="data.restaurant_menu_data.menu.logo" alt="Restaurant Banner" class="restaurant-banner">

      <div class="preview-body">
        <div class="restaurant-title">
          <b class="restaurant-name">{{data.restaurant_menu_data.headings.main}}</b>
          <p class="restaurant-description">{{data.restaurant_menu_data.headings.meta}}</p>
        </div>

        <div class="restaurant-tabs sticky-top">
          <div class="nav nav-pills">
            <div class="custom-swiper-wrapper" appSwiper>
              <swiper-container class="swiper-container">
                <ng-container *ngFor="let section of data.restaurant_menu_data.menu.sections; let i = index">
                  <ng-container *ngIf="section.show">
                    <swiper-slide class="swiper-slide">
                      <div class="nav-item">
                        <a class="nav-link" (click)="scrollToElement('target' + section.id)"
                          [id]="'target' + section.id">{{section.headings.main}}</a>
                      </div>
                    </swiper-slide>
                  </ng-container>
                </ng-container>
              </swiper-container>
            </div>
          </div>
        </div>

        <div class="scrollspy-custom" #scrollspyContainer>
          <ng-container *ngFor="let section of data.restaurant_menu_data.menu.sections; let i = index">
            <ng-container *ngIf="section.show">
              <section data-spy="scroll" [attr.data-target]="'target' + section.id" class="restaurant-menu"
                [id]="'section' + section.id">
                <b class="section-name">{{section.headings.main}}</b>
                <p class="section-description">{{section.headings.meta}}
                </p>
                <ng-container *ngFor="let item of section.items; let ii = index">
                  <div class="menu-item" [id]="'menu-item' + item.id" *ngIf="item.show" (click)="viewItem(item)">
                    <img loading="lazy" *ngIf="item.thumbnail" [src]="item.thumbnail" [alt]="item.headings.main"
                      class="item-image">
                    <div class="item-details">
                      <b class="item-title">{{item.headings.main}}</b>
                      <p class="item-description">{{item.headings.meta}}</p>
                      <b class="item-price"
                        [ngStyle]="{'color': data.restaurant_menu_data.colors.secondary_color}">{{item.price
                        | currency:
                        data.restaurant_menu_data.menu.currency}}</b>
                    </div>
                  </div>
                </ng-container>
                <div class="my-3">
                  <hr>
                </div>
              </section>
            </ng-container>
          </ng-container>
        </div>

        <div class="mx-3">
          <ng-container *ngIf="data.restaurant_menu_data.socials.length">
            <app-shared-qr-customize-social-media-links [socials]="data.restaurant_menu_data.socials"
              [event_socials]="event_socials_obs.asObservable()">
            </app-shared-qr-customize-social-media-links>
          </ng-container>
        </div>

        <div class="d-flex flex-column bd-highlight sticky-footer-container">
          <div class="mb-2" *ngIf="calculateCartItems(); let items">
            <div class="share-button cart" (click)="changeView('summary')"
              [ngStyle]="{'background-color': data.restaurant_menu_data.colors.secondary_color}">
              <span class="badge"
                [ngStyle]="{'background-color': data.restaurant_menu_data.colors.secondary_color, 'color': data.restaurant_menu_data.colors.text_color}">
                {{items}}
              </span>
              <i class="bi bi-bag" [ngStyle]="{'color': data.restaurant_menu_data.colors.text_color}"></i>
            </div>
          </div>

          <div class="mb-2" *ngIf="data.restaurant_menu_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
              [ngStyle]="{'background-color': data.restaurant_menu_data.colors.secondary_color}"
              data-bs-toggle="tooltip" data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
              title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
              <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.restaurant_menu_data.colors.text_color}"></i>
            </div>
          </div>
          <div class="mb-2" *ngIf="data.restaurant_menu_data.share_on">
            <div class="share-button"
              [ngStyle]="{'background-color': data.restaurant_menu_data.colors.secondary_color}">
              <app-share-to-social [data]="social_data" [text_color]="data.restaurant_menu_data.colors.text_color"
                [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</ng-container>