import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';

@Component({
  selector: 'app-action-denied-contact-support',
  templateUrl: './action-denied-contact-support.component.html',
  styleUrls: ['./action-denied-contact-support.component.scss']
})
export class ActionDeniedContactSupportComponent implements OnInit {

  @Input('msg') msg: string;

  constructor(
    private router: Router,
    private functionsService: FunctionsService
  ) { }

  ngOnInit() {
  }

  gotoPage(): void {
    this.functionsService.closeAllModals()
    this.router.navigateByUrl("/support")
  }

}
