<div class="preview-wrapper animated fadeIn" *ngIf="data && data.businesspage_data">
    <img loading="lazy" [src]="data.businesspage_data.banner_url" width="100%">

    <div class="preview-body">
        <div [ngStyle]="{'background-color': data.businesspage_data.colors.primary_color}" class="header-caption">
            <b
                [ngStyle]="{'color': data.businesspage_data.colors.text_color}">{{data.businesspage_data.headings.main}}</b>
            <p [ngStyle]="{'color': data.businesspage_data.colors.text_color}">{{data.businesspage_data.headings.meta}}
            </p>

            <a [href]="functions.forceUrl(data.businesspage_data.cta.url)" target="_blank"
                *ngIf="data.businesspage_data.cta.show" class="btn btn-sm mt-3"
                [ngStyle]="{'background-color': data.businesspage_data.colors.secondary_color, 'color': data.businesspage_data.colors.text_color}">
                {{data.businesspage_data.cta.title != 'Custom' ? data.businesspage_data.cta.title :
                data.businesspage_data.cta.custom_title}}
            </a>
        </div>

        <div class="preview-contents">

            <div class="media-wrapper" *ngIf="data.businesspage_data.company">
                <div class="media-logo">
                    <div class="svg-icon me-3">
                        <svg viewBox="0 0 496 496">
                            <path
                                d="m434 464h-32v-392c0-8.836-7.163-16-16-16h-120v-40c0-8.836-7.163-16-16-16h-111c-8.836 0-16 7.164-16 16v40h-13c-8.836 0-16 7.164-16 16v392h-32c-8.836 0-16 7.164-16 16s7.164 16 16 16h372c8.837 0 16-7.164 16-16s-7.163-16-16-16zm-279-432h79v24h-79zm61 432v-68h64v68zm96 0v-68h7c8.837 0 16-7.164 16-16s-7.163-16-16-16c-55.654 0-90.452 0-142 0-8.836 0-16 7.164-16 16s7.164 16 16 16h7v68h-58v-376h244v376zm-87-329c0 8.836-7.164 16-16 16h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16zm95 0c0 8.836-7.163 16-16 16h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16zm-95 60c0 8.836-7.164 16-16 16h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16zm95 0c0 8.836-7.163 16-16 16h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16zm-95 60c0 8.836-7.164 16-16 16h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16zm95 0c0 8.836-7.163 16-16 16h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16zm-95 60c0 8.836-7.164 16-16 16h-18c-8.836 0-16-7.164-16-16s7.164-16 16-16h18c8.836 0 16 7.164 16 16zm95 0c0 8.836-7.163 16-16 16h-18c-8.837 0-16-7.164-16-16s7.163-16 16-16h18c8.837 0 16 7.164 16 16z" />
                        </svg>
                    </div>
                </div>
                <div class="media-contents">
                    <b>Company</b>
                    <p>{{data.businesspage_data.company}}</p>
                </div>
            </div>

            <div class="media-wrapper">
                <div class="media-logo">
                    <i class="bi bi-clock bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Opening Hours</b>
                    <div class="pt-2">
                        <ng-container *ngFor="let day of data.businesspage_data.opening_hours">
                            <div class="open-hours" *ngIf="day.opened">
                                <span class="days">{{day.name.substr(0, 3)}}:</span>
                                <span>
                                    <ng-container *ngFor="let hours of day.hours">
                                        <span *ngIf="hours.from">
                                            {{functions.convertTimeFrom24to12Format(hours.from)}} -
                                        </span>
                                        <span *ngIf="hours.to">
                                            {{functions.convertTimeFrom24to12Format(hours.to)}} <br>
                                        </span>
                                    </ng-container>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="data.businesspage_data.location.address">
                <div class="media-logo">
                    <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Location</b>
                    <a *ngIf="data.businesspage_data.location.location_url; else noLinkToLocation"
                        [href]="functions.forceUrl(data.businesspage_data.location.location_url)"
                        target="_blank">{{data.businesspage_data.location.address}}</a>
                    <ng-template #noLinkToLocation>
                        <p>{{data.businesspage_data.location.address}}</p>
                    </ng-template>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="data.businesspage_data.full_name">
                <div class="media-logo">
                    <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Contact</b>
                    <p>{{data.businesspage_data.full_name}}</p>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="data.businesspage_data.mobile">
                <div class="media-logo">
                    <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Phone</b>
                    <a href="tel:{{data.businesspage_data.mobile}}"
                        target="_blank">{{data.businesspage_data.mobile}}</a>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="functions.isEmail(data.businesspage_data.email)">
                <div class="media-logo">
                    <i class="bi bi-envelope bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Email</b>
                    <a [href]="'mailto:' + data.businesspage_data.email"
                        target="_blank">{{data.businesspage_data.email}}</a>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="functions.is_valid_url(data.businesspage_data.website)">
                <div class="media-logo">
                    <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                    <b>Website</b>
                    <a [href]="functions.forceUrl(data.businesspage_data.website)"
                        target="_blank">{{data.businesspage_data.website}}</a>
                </div>
            </div>

            <div class="media-wrapper" *ngIf="data.businesspage_data.facilities.length">
                <div class="media-contents">
                    <b>Facility Features</b>
                    <div class="pt-2">
                        <app-shared-qr-customize-amenities-icon [data_preview]="data.businesspage_data.facilities">
                        </app-shared-qr-customize-amenities-icon>
                    </div>
                </div>
            </div>
        </div>

        <!-- Social Media Section -->
        <ng-container *ngIf="data.businesspage_data.socials.length">
            <app-shared-qr-customize-social-media-links [socials]="data.businesspage_data.socials"
                [event_socials]="event_socials_obs.asObservable()">
            </app-shared-qr-customize-social-media-links>
        </ng-container>

    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.businesspage_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.businesspage_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.businesspage_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.businesspage_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.businesspage_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.businesspage_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>