<div class="row why-choose-us mb-3">
    <div class="col-md-12 text-center">
        <h1 class="title">Why Choose Us</h1>
        <p class="mb-3 fs-6">
            BrandQRcodes.com is your gateway to transforming QR codes into captivating digital experiences. We offer
            user-friendly design tools, affordability, and unmatched customization options to ensure your brand stands
            out effortlessly. Create and share your unique QR code pages with confidence. </p>
    </div>
    <div class="row my-5">
        <div class="col-md-4 mb-4 animated fadeIn delay-{{i}}" *ngFor="let content of contents; let i = index">
            <div class="wcu-parent">
                <div class="number">{{content.id}}</div>
                <p class="title"><i class="bi bi-arrow-right me-2"></i>{{content.title}}</p>
                <p class="text">{{content.text}}</p>
            </div>
        </div>
    </div>
</div>