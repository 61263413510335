import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { environment } from 'src/environments/environment';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-images',
  templateUrl: './shared-qr-preview-images.component.html',
  styleUrls: ['./shared-qr-preview-images.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewImagesComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.IMAGES)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        if (d.images_data) {
          this.event_additional_settings_obs.next({
            text_color: d.images_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.images_data = this.qrGeneratorService.initImagesCategoryData()
          this.data.images_data.headings.main = "Sarah's Gallery"
          this.data.images_data.headings.meta = "Here's a glimpse of my work in fashion photography. Check them out and head over to my website."
          this.data.images_data.images.grid = true
          this.data.images_data.images.list = [
            `${environment.files_url}assets/resources/samples/pictures/gallery/1.jpeg`,
            `${environment.files_url}assets/resources/samples/pictures/gallery/2.jpeg`,
            `${environment.files_url}assets/resources/samples/pictures/gallery/3.jpeg`,
            `${environment.files_url}assets/resources/samples/pictures/gallery/5.jpeg`,
            `${environment.files_url}assets/resources/samples/pictures/gallery/4.jpeg`,
            `${environment.files_url}assets/resources/samples/pictures/gallery/6.jpeg`,
          ].map((url, position) => {
            return {
              url,
              size: "",
              name: "",
              position
            }
          })
          this.data.images_data.share_on = true
          this.data.images_data.colors.primary_color = "#DF6589FF"
          this.data.images_data.colors.secondary_color = "#3C1053FF"
          this.data.images_data.website = "brandqrcodes.com"
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
