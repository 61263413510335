import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '@app/modules/core/services/core.service';

@Component({
  selector: 'app-shared-error-occurred',
  templateUrl: './shared-error-occurred.component.html',
  styleUrls: ['./shared-error-occurred.component.scss']
})
export class SharedErrorOccurredComponent implements OnInit {

  @Input() message = "Network error occurred during your request, please try again later...";
  constructor(public coreService: CoreService) { }

  ngOnInit(): void {
  }

}
