// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_version: '1.200.14', // Updating this will force logout and reload
  // app_url: `brandqrcodes.loc:4210`,
  // app_url_full: `http://brandqrcodes.loc:4210/`,
  app_url: `staging.brandqrcodes.com`,
  app_url_full: `https://staging.brandqrcodes.com/`,
  google_keys: {
    api: `AIzaSyCHE9RrgHMG02CC-gk6zNmN3QsjbLifrOA`,
    recaptcha: "6Lf2sdMdAAAAACXn7PPlVVfhEM-4WaZ-hl6iDj6v"
  },
  referral_cookie_key: 'ref',
  files_url: `https://s3-ap-southeast-1.amazonaws.com/dev-files.brandqrcodes.com/`,
  // api_endpoint: "http://127.0.0.1:4111/",
  api_endpoint: "https://api.brandqrcodes.com/dev/",
  encryption_key: 'BPLrqAghLizie1swS2tmj9pnKSOJTHwMzI0KCOS8KPvhdRbxY',
  stripe_key: 'pk_test_51JikjADfC57n3Eh2Go8F37g7gibZkk5XYmsopYcBIFHFM7xrYZr4QDGS3jHWrAl0ZOdnHR67kCPBuz1hEqFbG8if00INdhKayS',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
