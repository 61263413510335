<ng-container *ngIf="data?.slidein_data">
    <div class="preview-wrapper animated"
        [ngClass]="{'slideInLeft': !data.slidein_data.right_to_view, 'slideInRight': data.slidein_data.right_to_view}">
        <div class="preview-body">
            <div class="slidein" [ngStyle]="{'background-color': data.slidein_data.colors.primary_color}">
                <div class="row">
                    <div class="slidein-header">
                        <i class="bi bi-x-circle closer"
                            [ngStyle]="{'color': data.slidein_data.colors.secondary_color}"></i>
                        <img loading="lazy" *ngIf="data.slidein_data.banner.show" [src]="data.slidein_data.banner.url"
                            width="100%">
                    </div>
                    <div class="col-md-12">
                        <div class="slidein-body">
                            <h5 class="mb-0" [ngStyle]="{'color': data.slidein_data.colors.secondary_color}">
                                {{data.slidein_data.headings.main}}
                            </h5>
                            <small
                                [ngStyle]="{'color': data.slidein_data.colors.text_color}">{{data.slidein_data.headings.meta}}</small>
                            <ng-container *ngIf="data.slidein_data.form.enable && data.slidein_data.form.inputs.length">
                                <div class="mt-2" *ngFor="let input of data.slidein_data.form.inputs">
                                    <input [type]="input.type" [required]="input.required"
                                        [maxLength]="input.max_length" class="form-control form-control-sm"
                                        [placeholder]="input.placeholder">
                                </div>

                                <button class="btn btn-block btn-sm mt-3"
                                    [ngStyle]="{'color': '#FFFFFF', 'background-color': data.slidein_data.colors.secondary_color}">{{
                                    data.slidein_data.form.cta_text}}</button>
                            </ng-container>
                            <button class="btn btn-block btn-sm my-2" *ngIf="data.slidein_data.cta.show"
                                [ngStyle]="{'color': '#FFFFFF', 'background-color': data.slidein_data.colors.secondary_color}">{{data.slidein_data.cta.title
                                === 'Custom' ? data.slidein_data.cta.custom_title :
                                data.slidein_data.cta.title}}</button>

                            <div class="text-center" *ngIf="data.slidein_data.no_thanks.show">
                                <small class="mouse-pointer"
                                    [ngStyle]="{'color': data.slidein_data.colors.text_color}">{{data.slidein_data.no_thanks.title}}</small>
                            </div>

                            <div class="text-center website my-3"
                                *ngIf="functions.is_valid_url(data.slidein_data.website)">
                                <a class="mouse-pointer" [href]="functions.forceUrl(data.slidein_data.website)"
                                    target="_blank"
                                    [ngStyle]="{'color': data.slidein_data.colors.secondary_color}">{{data.slidein_data.website}}</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>