import { Component } from '@angular/core';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';

@Component({
  selector: 'app-shared-qr-preview-website',
  templateUrl: './shared-qr-preview-website.component.html',
  styleUrls: ['./shared-qr-preview-website.component.scss']
})
export class SharedQrPreviewWebsiteComponent {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  url_to_show = "http://brandqrcodes.com"

  constructor(
    private coreService: CoreService,
    public functionService: FunctionsService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.create_mode) {
        this.data = this.session_info?.qr_generating?.drafts?.find(d => d.selected_category === QrGeneratorNavigatorCategories.WEBSITE)
      } else {
        this.data = this.session_info?.qr_generating?.updating
      }
      if (this.data?.website_data?.url) {
        this.url_to_show = this.data.website_data.url
      }
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
