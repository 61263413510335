import { Component, Inject, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { environment } from 'src/environments/environment';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { AppStores, QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-app',
  templateUrl: './shared-qr-preview-app.component.html',
  styleUrls: ['./shared-qr-preview-app.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewAppComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.create_mode) {
        this.data = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.APP)
      } else {
        this.data = this.session_info.qr_generating.updating
      }
      this.functions.initializeTooltip()
      if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
        // show demo data instead
        this.data.app_data = JSON.parse(JSON.stringify(this.qrGeneratorService.initAPPCategoryData()))
        this.data.app_data.company_name = "Instagram, Inc."
        this.data.app_data.headings.main = "Express Yourself and Connect With Friends"
        this.data.app_data.headings.meta = "Bringing you closer to the people and things you love. — Instagram from Facebook. Connect with friends, share what you’re up to, or see what's new from others all over the world."

        this.data.app_data.app.name = "Instagram"
        if (!this.data.app_data.app.links.find(link => link.type === AppStores.APPSTORE)) {
          this.data.app_data.app.links.push({
            position: 0,
            name: "App Store",
            type: AppStores.APPSTORE,
            logo: `${environment.app_url_full}assets/logos/app-store.png`,
            url: "https://apps.apple.com/us/app/instagram/id389801252"
          })
        }

        if (!this.data.app_data.app.links.find(link => link.type === AppStores.GOOGLEPLAY)) {
          this.data.app_data.app.links.push({
            position: 0,
            name: "Google Play",
            type: AppStores.GOOGLEPLAY,
            logo: `${environment.app_url_full}assets/logos/google-play.png`,
            url: "https://play.google.com/store/apps/details?id=com.instagram.android&hl=en&gl=US"
          })
        }

        if (!this.data.app_data.app.links.find(link => link.type === AppStores.AMAZON)) {
          this.data.app_data.app.links.push({
            position: 0,
            name: "Amazon",
            type: AppStores.AMAZON,
            url: "https://www.amazon.com/Instagram/dp/B00KZP2DTQ",
            logo: `${environment.app_url_full}assets/logos/amazon.png`
          })
        }

        this.data.app_data.share_on = true
        this.data.app_data.website = "www.instagram.com"
        this.data.app_data.colors.primary_color = "#481380"
        this.data.app_data.colors.secondary_color = "#7f78d2"
      }
    }))
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }


}
