<div class="preview-wrapper animated fadeIn" *ngIf="data?.memories_data">

    <div class="preview-header d-flex align-items-end"
        [ngStyle]="{'background-image': 'url(' + data.memories_data.banner_url + ')'}">
    </div>
    <div class="header-caption">
        <span
            [ngStyle]="{'color': data.memories_data.colors.secondary_color}">{{data.memories_data.headings.main}}</span>
        <p [ngStyle]="{'color': data.memories_data.colors.secondary_color}">{{data.memories_data.headings.meta}}</p>
        <i *ngIf="data.memories_data.website">
            <a [href]="functions.forceUrl(data.memories_data.website)"
                [ngStyle]="{'color': data.memories_data.colors.secondary_color}">{{data.memories_data.website}}</a>
        </i>
    </div>
    <div class="preview-body">
        <div class="preview-contents">
            <ng-container *ngFor="let image of sample_images; let i = index">
                <ng-container *ngIf="i%2===0">
                    <ng-template *ngTemplateOutlet="showGalleryItem; context: {image, i}"></ng-template>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let image of sample_images; let i = index">
                <ng-template *ngTemplateOutlet="showGalleryItem; context: {image, i}"></ng-template>
            </ng-container>
            <ng-template #showGalleryItem let-image="image" let-i="i">
                <div class="image-container fadeIn animated delay-{{i}}">
                    <img loading="lazy" [src]="image" alt="Example Image">

                    <div class="image-overlay-download" *ngIf="data.memories_data.downloadable"
                        [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.memories_data.colors.primary_color).join(',') + ', 50%)', 'color': data.memories_data.colors.text_color}">
                        <i class="bi bi-cloud-arrow-down"></i>
                    </div>

                    <div class="image-overlay"
                        [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.memories_data.colors.primary_color).join(',') + ', 50%)', 'color': data.memories_data.colors.text_color}">
                        <i class="bi bi-image me-2"></i>
                        Caption Here
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2">
            <div class="share-button" [ngStyle]="{'background-color': data.memories_data.colors.secondary_color}">
                <i class="bi bi-camera" [ngStyle]="{'color': data.memories_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.memories_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.memories_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.memories_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.memories_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.memories_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.memories_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>