import { Component } from '@angular/core';
import { CoreService } from '@app/modules/core/services/core.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(public coreService: CoreService) { }

}
