import moment from 'moment';
import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { CoreService } from '@app/modules/core/services/core.service';
import { SessionInfo } from '@app/store/models/session-info.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  private subscriptions: Subscription[] = []
  session_info: SessionInfo
  current_year = moment().year()
  @Input() footer_text = "Build simple QR Code-accessible pages for your business and portfolio - with analytics."

  constructor(
    public coreService: CoreService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => this.session_info = si))
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe())
  }

}
