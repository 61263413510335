<ng-container *ngIf="settings">
  <div class="dropdown" *ngIf="getCurrent(); let currentLang">
    <button class="btn" type="button" [ngStyle]="color ? {'color': color} : {}" id="languageChange"
      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="d-flex">
        <div class="dropdown-icon">
          <i class="bi bi-translate me-2"></i>
        </div>
        <div class="name text-truncate">{{currentLang}}</div>
      </div>
    </button>
    <div class="dropdown-menu" aria-labelledby="languageChange">
      <ng-container *ngFor="let language of languages">
        <a class="dropdown-item" (click)="tellParent(language.value)">
          {{language.title}}</a>
      </ng-container>
    </div>
  </div>
</ng-container>