import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDragndrop]'
})
export class DragndropDirective {

    @Output() onFileDropped = new EventEmitter<any>();
    @HostBinding('style.background-color') public background = "";
    @HostBinding('style.border-color') public border = "";


    constructor() { }

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#FEF3F4';
        this.border = '#EB1D24';
    }

    //Dragleave listener, when something is dragged away from our host element
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#FFFFFF'
        this.border = '#ECEDEE';
    }

    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#FFFFFF'
        this.border = '#ECEDEE';
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit({
                target: {
                    files
                }
            })
        }
    }

}
