<div class="social-media-wrapper social-media-wrapper">
    <div class="s-header">
        <b>Social Media</b>
    </div>

    <div class="icon-wrapper">
        <ng-container *ngFor="let social of socials">
            <ng-container *ngIf="findTemplate(social.type); let template_details">
                <a [href]="qrGeneratorService.getActualUrl(social)" [title]="social.name" data-bs-toggle="tooltip"
                    data-bs-html="true" data-bs-placement="top" target="_blank">
                    <div class="icon">
                        <i [style.color]="_icon_color" [class]="template_details.icon"
                            *ngIf="template_details.icon; else noIcon">
                        </i>
                        <ng-template #noIcon>
                            <ng-container *ngIf="'tiktok' == template_details.type">
                                <svg viewBox="0 0 512 512" [attr.fill]="_icon_color">
                                    <path
                                        d="m480.32 128.39c-29.22 0-56.18-9.68-77.83-26.01-24.83-18.72-42.67-46.18-48.97-77.83-1.56-7.82-2.4-15.89-2.48-24.16h-83.47v228.08l-.1 124.93c0 33.4-21.75 61.72-51.9 71.68-8.75 2.89-18.2 4.26-28.04 3.72-12.56-.69-24.33-4.48-34.56-10.6-21.77-13.02-36.53-36.64-36.93-63.66-.63-42.23 33.51-76.66 75.71-76.66 8.33 0 16.33 1.36 23.82 3.83v-62.34-22.41c-7.9-1.17-15.94-1.78-24.07-1.78-46.19 0-89.39 19.2-120.27 53.79-23.34 26.14-37.34 59.49-39.5 94.46-2.83 45.94 13.98 89.61 46.58 121.83 4.79 4.73 9.82 9.12 15.08 13.17 27.95 21.51 62.12 33.17 98.11 33.17 8.13 0 16.17-.6 24.07-1.77 33.62-4.98 64.64-20.37 89.12-44.57 30.08-29.73 46.7-69.2 46.88-111.21l-.43-186.56c14.35 11.07 30.04 20.23 46.88 27.34 26.19 11.05 53.96 16.65 82.54 16.64v-60.61-22.49c.02.02-.22.02-.24.02z" />
                                </svg>
                            </ng-container>
                            <ng-container *ngIf="'onlyfans' == template_details.type">
                                <svg viewBox="0 0 512 512" [attr.fill]="_icon_color">
                                    <g>
                                        <path
                                            d="m437.02 74.98c-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.351 48.353-74.98 112.64-74.98 181.02s26.629 132.667 74.98 181.02c48.353 48.351 112.64 74.98 181.02 74.98s132.667-26.629 181.02-74.98c48.351-48.353 74.98-112.64 74.98-181.02s-26.629-132.667-74.98-181.02zm-181.02 407.02c-124.617 0-226-101.383-226-226s101.383-226 226-226 226 101.383 226 226-101.383 226-226 226z" />
                                        <path
                                            d="m256 271c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15s15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                        <path
                                            d="m356 211h-5v-15c0-52.383-42.617-95-95-95s-95 42.617-95 95v15h-5c-8.284 0-15 6.716-15 15v150c0 8.284 6.716 15 15 15h200c8.284 0 15-6.716 15-15v-150c0-8.284-6.716-15-15-15zm-165-15c0-35.841 29.159-65 65-65s65 29.159 65 65v15h-130zm150 165h-170v-120h170z" />
                                    </g>
                                </svg>
                            </ng-container>
                            <ng-container *ngIf="'whatsapp' == template_details.type">
                                <svg viewBox="0 0 24 24" [attr.fill]="_icon_color">
                                    <path
                                        d="m17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z" />
                                    <path
                                        d="m20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z" />
                                </svg>
                            </ng-container>
                            <ng-container *ngIf="'pinterest' == template_details.type">
                                <svg viewBox="0 0 511.977 511.977" [attr.fill]="_icon_color">
                                    <path
                                        d="M262.948,0C122.628,0,48.004,89.92,48.004,187.968c0,45.472,25.408,102.176,66.08,120.16
                                                c6.176,2.784,9.536,1.6,10.912-4.128c1.216-4.352,6.56-25.312,9.152-35.2c0.8-3.168,0.384-5.92-2.176-8.896
                                                c-13.504-15.616-24.224-44.064-24.224-70.752c0-68.384,54.368-134.784,146.88-134.784c80,0,135.968,51.968,135.968,126.304
                                                c0,84-44.448,142.112-102.208,142.112c-31.968,0-55.776-25.088-48.224-56.128c9.12-36.96,27.008-76.704,27.008-103.36
                                                c0-23.904-13.504-43.68-41.088-43.68c-32.544,0-58.944,32.224-58.944,75.488c0,27.488,9.728,46.048,9.728,46.048
                                                S144.676,371.2,138.692,395.488c-10.112,41.12,1.376,107.712,2.368,113.44c0.608,3.168,4.16,4.16,6.144,1.568
                                                c3.168-4.16,42.08-59.68,52.992-99.808c3.968-14.624,20.256-73.92,20.256-73.92c10.72,19.36,41.664,35.584,74.624,35.584
                                                c98.048,0,168.896-86.176,168.896-193.12C463.62,76.704,375.876,0,262.948,0z" />
                                </svg>
                            </ng-container>
                        </ng-template>
                    </div>
                </a>
            </ng-container>
        </ng-container>

    </div>
</div>