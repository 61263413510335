<div class="row">
    <div class="col-md-6">
        <small class="text-muted">{{total}} total {{functionService.pluralize('record', total)}}</small>
    </div>
    <div class="col-md-6 text-end">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-end">
                <li class="page-item" [class.disabled]="1 === current">
                    <a class="page-link" (click)="changePage(page - 1)" tabindex="-1" aria-disabled="true">Previous</a>
                </li>
                <li class="page-item" [class.active]="item === current" [class.disabled]="item === current"
                    *ngFor="let item of getPageRange()">
                    <a class="page-link" (click)="changePage(item)">{{item}}</a>
                </li>
                <li class="page-item" [class.disabled]="totalPages === current">
                    <a class="page-link" (click)="changePage(page + 1)">Next</a>
                </li>
            </ul>
        </nav>
    </div>
</div>