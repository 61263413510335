<div class="preview-wrapper animated fadeIn" *ngIf="data?.vacancy_data">
  <div class="preview-header" [ngStyle]="{'background-color': data.vacancy_data.colors.primary_color}">
    <img loading="lazy" *ngIf="data.vacancy_data.logo.show" [src]="data.vacancy_data.logo.url" height="26" class="mb-3">
    <h6 class="mb-0" [ngStyle]="{'color': data.vacancy_data.colors.text_color}">
      {{data.vacancy_data.company_name}}</h6>
    <div class="d-flex flex-row-reverse bd-highlight banner-container">
      <div class="ms-2" *ngIf="data.vacancy_data.show_qr_code">
        <div class="share-button" data-bs-custom-class="qr-preview-tooltip" data-bs-toggle="tooltip"
          data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-html="true"
          title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
          <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.vacancy_data.colors.text_color}"></i>
        </div>
      </div>
      <div *ngIf="data.vacancy_data.share_on">
        <div class="share-button">
          <app-share-to-social [data]="social_data" [text_color]="data.vacancy_data.colors.text_color"
            [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
        </div>
      </div>
    </div>
  </div>

  <div class="preview-body">
    <div class="header-caption" *ngIf="!steps"
      [ngStyle]="{'background-color': data.vacancy_data.colors.secondary_color}">
      <b [ngStyle]="{'color': data.vacancy_data.colors.text_color}">{{data.vacancy_data.headings.main}}</b>
      <p [ngStyle]="{'color': data.vacancy_data.colors.text_color}">{{data.vacancy_data.headings.meta}}</p>
    </div>

    <div class="preview-contents">

      <ng-container *ngIf="!steps; else nextPage">
        <div class="job-description" [innerHTML]="data.vacancy_data.application.descriptions"></div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.contact.address">
          <div class="media-logo">
            <i class="bi bi-geo bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>Location</b>
            <div *ngIf="!data.vacancy_data.contact.address_url; else addExists">
              {{data.vacancy_data.contact.address}}</div>
            <ng-template #addExists>
              <a [href]="functions.forceUrl(data.vacancy_data.contact.address_url)"
                target="_blank">{{data.vacancy_data.contact.address}}</a>
            </ng-template>
          </div>
        </div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.application.summary">
          <div class="media-logo">
            <i class="bi bi-info-circle bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>About</b>
            <p>{{data.vacancy_data.application.summary}}</p>
          </div>
        </div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.contact.name">
          <div class="media-logo">
            <i class="bi bi-person bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>Name</b>
            <p>{{data.vacancy_data.contact.name}}</p>
          </div>
        </div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.contact.email">
          <div class="media-logo">
            <i class="bi bi-envelope bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>Email</b>
            <a [href]="'mailto:' + data.vacancy_data.contact.email"
              target="_blank">{{data.vacancy_data.contact.email}}</a>
          </div>
        </div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.contact.mobile">
          <div class="media-logo">
            <i class="bi bi-phone bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>Phone Number</b>
            <a [href]="'tel:' + data.vacancy_data.contact.mobile"
              target="_blank">{{data.vacancy_data.contact.mobile}}</a>
          </div>
        </div>

        <div class="media-wrapper" *ngIf="data.vacancy_data.contact.website">
          <div class="media-logo">
            <i class="bi bi-globe2 bi-lg"></i>
          </div>
          <div class="media-contents">
            <b>Website</b>
            <a class="website-url" [href]="functions.forceUrl(data.vacancy_data.contact.website)"
              target="_blank">{{data.vacancy_data.contact.website}}</a>
          </div>
        </div>
      </ng-container>
      <ng-template #nextPage>
        <span class="go-back d-flex align-items-center mouse-pointer mb-3" *ngIf="1 == steps"
          (click)="steps = steps - 1">
          <i class="bi bi-arrow-left me-1"></i>
          <small class="m-0">Go back</small>
        </span>
        <ng-container *ngIf="1 == steps; else isFinalStep">
          <div class="mb-3">
            <small>Name<span class="text-danger">*</span></small>
            <input type="text" [(ngModel)]="data.vacancy_data.application.contact.name"
              class="form-control form-control-sm" placeholder="Enter your name">
          </div>
          <div class="mb-3">
            <small>Email<span class="text-danger">*</span></small>
            <input type="email" [(ngModel)]="data.vacancy_data.application.contact.email"
              class="form-control form-control-sm" placeholder="Enter your email">
          </div>
          <div class="file-wrapper px-2 py-3  animated fadeIn mt-4 mb-3" *ngIf="demo_file.file; else fileNotPicked">
            <div class="file-body">
              <div class="file-icon me-2">
                <img loading="lazy" src="assets/file.png" width="30">
              </div>
              <div class="file-name">
                <b class="sm mb-0">{{demo_file.file.name}}</b>
                <small class="mt-1">File size: {{functions.formatBytes(demo_file.file.size)}}</small>
              </div>
            </div>
            <div class="file-close">
              <i class="bi bi-x bi-md" (click)="demo_file.file=null"></i>
            </div>
          </div>
          <ng-template #fileNotPicked>
            <div class="mb-3">
              <small>Upload CV<span class="text-danger">*</span></small>
              <div class="custom-file-wrapper">
                <input type="file" (change)="fileChangedEvent($event)" accept="application/pdf,image/*" id="cvUpload">
                <label class="sm" for="cvUpload"
                  [ngStyle]="{'background-color': data.vacancy_data.colors.secondary_color, 'color': data.vacancy_data.colors.text_color}">
                  <svg width="14" height="14" fill="currentColor" class="bi bi-upload me-2" viewBox="0 0 16 16">
                    <path
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path
                      d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                  </svg> Browse File
                </label>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #isFinalStep>
          <div class="py-3 text-center">
            <div class="pb-3">
              <svg viewBox="0 0 478 478" width="50">
                <rect id="Rectangle-Copy-360" fill="#FFFFFF" x="71" y="60" width="341" height="358" rx="170.5">
                </rect>
                <g id="check" fill="#23B559" fill-rule="nonzero">
                  <path
                    d="M238.933,0 C106.974,0 0,106.974 0,238.933 C0,370.892 106.974,477.866 238.933,477.866 C370.892,477.866 477.866,370.892 477.866,238.933 C477.726,107.033 370.834,0.141 238.933,0 Z M370.466,165.666 L199.799,336.333 C193.134,342.996 182.331,342.996 175.667,336.333 L107.4,268.066 C100.62,261.518 100.432,250.714 106.98,243.934 C113.528,237.154 124.332,236.966 131.112,243.514 C131.254,243.652 131.394,243.791 131.532,243.934 L187.733,300.135 L346.334,141.534 C353.114,134.986 363.918,135.174 370.466,141.953 C376.854,148.567 376.854,159.052 370.466,165.666 Z"
                    id="Shape"></path>
                </g>
              </svg>
            </div>
            <p>Your application has been submitted successfully.</p>
            <button class="btn btn-block mt-4" (click)="resetSteps()"
              [ngStyle]="{'background-color': data.vacancy_data.colors.secondary_color, 'color': data.vacancy_data.colors.text_color}">Go
              Home</button>
          </div>
        </ng-template>

      </ng-template>

    </div>

  </div>

  <div class="btn-wrapper" *ngIf="steps !== 2 && !demo_file.applied">
    <a class="btn mt-3" (click)="startSubmit()" *ngIf="data.vacancy_data.application.off_site; else redirectThem"
      [ngStyle]="{'background-color': data.vacancy_data.colors.secondary_color, 'color': data.vacancy_data.colors.text_color}">
      {{data.vacancy_data.cta.title == "Custom" ? data.vacancy_data.cta.custom_title :
      data.vacancy_data.cta.title}}
    </a>
    <ng-template #redirectThem>
      <a [href]="functions.forceUrl(data.vacancy_data.cta.url)" target="_blank" class="btn mt-3"
        [ngStyle]="{'background-color': data.vacancy_data.colors.secondary_color, 'color': data.vacancy_data.colors.text_color}">
        {{data.vacancy_data.cta.title == "Custom" ? data.vacancy_data.cta.custom_title :
        data.vacancy_data.cta.title}}
      </a>
    </ng-template>

  </div>

</div>