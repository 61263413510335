import { Component, Inject, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, QrGeneratorNavigatorFEEDBACK_QUESTION } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-feedback',
  templateUrl: './shared-qr-preview-feedback.component.html',
  styleUrls: ['./shared-qr-preview-feedback.component.scss']
})
export class SharedQrPreviewFeedbackComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  level1_index = -1
  level2_index = -1

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  steps = 0 // 0 for filling feedback forms and navigation, 1 for showing submit confirmation button, 2 for Thanks for your feedback

  constructor(
    public coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category == QrGeneratorNavigatorCategories.FEEDBACK)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        this.resetSteps()

        if (d.feedback_data) {
          this.event_additional_settings_obs.next({
            text_color: d.feedback_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }

        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.feedback_data.headings.main = "We want to hear from you."
          this.data.feedback_data.headings.meta = "Let us know what you think about our services and how we can improve."
          this.data.feedback_data.company_name = "Sarah's Grocers"
          this.data.feedback_data.feedback.questions = [
            {
              id: 0,
              icon: "bi bi-basket",
              title: "Fruits and vegetables",
              feedback: {
                rating: 0,
                description: ""
              },
              subs: [
                {
                  id: 0,
                  icon: "bi bi-basket",
                  title: "Apple",
                  feedback: {
                    rating: 0,
                    description: ""
                  },
                  subs: []
                },
                {
                  id: 1,
                  icon: "bi bi-basket",
                  title: "Orange",
                  feedback: {
                    rating: 0,
                    description: ""
                  },
                  subs: []
                }
              ]
            },
            {
              id: 0,
              icon: "bi bi-basket",
              title: "Spices",
              feedback: {
                rating: 0,
                description: ""
              },
              subs: []
            },
            {
              id: 0,
              icon: "bi bi-basket",
              title: "Fresh meat",
              feedback: {
                rating: 0,
                description: ""
              },
              subs: []
            },
            {
              id: 0,
              icon: "bi bi-basket",
              title: "Bread and pastries",
              feedback: {
                rating: 0,
                description: ""
              },
              subs: []
            }
          ]
          this.data.feedback_data.contact.website = "brandqrcodes.com"
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  resetSteps() {
    if (this.level1_index > -1) {
      if (this.data.feedback_data.feedback.questions.length > this.level1_index) this.level1_index = -1
      else if (this.data.feedback_data.feedback.questions[this.level1_index].subs.length > this.level2_index) this.level2_index = -1
    }
    if (this.level1_index === -1 && this.level2_index > -1) this.level2_index = -1
    this.steps = 0
  }

  shouldSavenContinue(): boolean {
    if (!this.data.feedback_data.feedback.questions.length) return false
    if (this.data.feedback_data.feedback.questions.length > 1) return true
    return this.data.feedback_data.feedback.questions[0].subs.length > 1
  }

  shouldShowSend(): boolean {
    if (this.level1_index === -1 || this.steps > 1) return false
    let in_feedback_view = (this.level2_index > -1 || !this.data.feedback_data.feedback.questions[this.level1_index].subs.length)
    let at_least_one_response_given = false
    for (const d of this.data.feedback_data.feedback.questions) {
      if (!d.subs.length) {
        d.feedback.description = d.feedback.description || ""
        if (d.feedback.rating || d.feedback.description.trim()) {
          at_least_one_response_given = true
          break
        }
        continue
      }
      for (const dd of d.subs) {
        dd.feedback.description = dd.feedback.description || ""
        if (dd.feedback.rating || dd.feedback.description.trim()) {
          at_least_one_response_given = true
          break
        }
      }
    }
    let in_sub_view = this.level2_index === -1
    return (in_feedback_view || in_sub_view) && at_least_one_response_given
  }

  allResponseReceived(q: QrGeneratorNavigatorFEEDBACK_QUESTION): boolean {
    if (!q.subs.length) {
      q.feedback.description = q.feedback.description || ""
      return (q.feedback.rating || q.feedback.description.trim()) ? true : false
    }
    let all_filled = true
    for (const qq of q.subs) {
      qq.feedback.description = qq.feedback.description || ""
      if (!qq.feedback.rating && !qq.feedback.description.trim()) {
        all_filled = false
        break
      }
    }
    return all_filled
  }

  startSubmit() {
    this.steps++
  }


}
