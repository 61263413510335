import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '@app/modules/core/services/core.service';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shared-qr-preview-location',
  templateUrl: './shared-qr-preview-location.component.html',
  styleUrls: ['./shared-qr-preview-location.component.scss']
})
export class SharedQrPreviewLocationComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []
  url_to_show = this.functionService.generateMapUrl(0, 0)
  @Input() selected_category: QrGeneratorNavigatorCategories

  constructor(
    private coreService: CoreService,
    public functionService: FunctionsService,
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      this.setParams()
    }))
  }

  ngOnInit(): void {
    this.setParams()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  private setParams() {
    if (this.selected_category) {
      if (this.create_mode) {
        this.data = this.session_info?.qr_generating?.drafts?.find(d => d.selected_category === this.selected_category)
      } else {
        this.data = this.session_info?.qr_generating?.updating
      }
      if (this.data?.location_data?.url) {
        this.url_to_show = this.data.location_data.url
      }
    }
  }

}
