import { Observable, Subscription } from 'rxjs';
import { LanguageSettings, SupportedLanguage, SupportedLanguages } from 'shared-library';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {

  current = SupportedLanguage.English;

  @Input() color: string;
  @Input() settings: LanguageSettings;
  @Output() nextLanguage = new EventEmitter<SupportedLanguage>()
  @Input() currentLanguage: Observable<SupportedLanguage> = null

  private subscriptions: Subscription[] = []
  languages: Array<{ title: string; value: SupportedLanguage; }> = []

  constructor() {
    for (const key in SupportedLanguages) {
      if (Object.prototype.hasOwnProperty.call(SupportedLanguages, key)) {
        this.languages.push({
          value: key as SupportedLanguage,
          title: SupportedLanguages[key]
        })
      }
    }
  }

  ngOnInit(): void {
    if (this.settings) {
      this.current = this.settings.default
    }
    if (this.currentLanguage) {
      this.subscriptions.push(this.currentLanguage.subscribe(v => this.current = v))
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe())
  }

  tellParent(lang: SupportedLanguage): void {
    this.current = lang
    this.nextLanguage.next(lang)
  }

  getCurrent() {
    return this.languages.find(({ value }) => this.current === value)?.title
  }

}
