import { Navigation } from 'swiper/modules';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSwiper]'
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions = {};

  constructor(private el: ElementRef<SwiperContainer>) { }

  ngAfterViewInit(): void {
    const swipper_element = this.el.nativeElement.querySelector('.swiper-container') as SwiperContainer;
    if (!swipper_element) return
    Object.assign(swipper_element, {
      modules: [Navigation],
      autoHeight: true,
      spaceBetween: 2,
      navigation: true,
      centeredSlides: false,
      slidesPerView: 'auto',
      freeMode: true,
      grabCursor: true,
      observer: true,
      observeParents: true,
      ...this.config,
    });
    swipper_element.initialize();
    const prev_button = this.el.nativeElement.querySelector('.custom-swiper-button-prev') as HTMLElement
    if (prev_button) {
      prev_button.onclick = () => {
        swipper_element.swiper.slidePrev()
      }
    }
    const next_button = this.el.nativeElement.querySelector('.custom-swiper-button-next') as HTMLElement
    if (next_button) {
      next_button.onclick = () => {
        swipper_element.swiper.slideNext()
      }
    }
  }
}
