<div class="preview-wrapper animated fadeIn" *ngIf="data?.feedback_data">
    <div class="preview-header" [ngStyle]="{'background-color': data.feedback_data.colors.primary_color}">
        <img loading="lazy" *ngIf="data.feedback_data.logo.show" [src]="data.feedback_data.logo.url" height="26"
            class="mb-3">
        <h6 class="mb-0" [ngStyle]="{'color': data.feedback_data.colors.text_color}">
            {{data.feedback_data.company_name}}</h6>

        <div class="d-flex flex-row-reverse bd-highlight banner-container">
            <div class="ms-2" *ngIf="data.feedback_data.show_qr_code">
                <div class="share-button" data-bs-custom-class="qr-preview-tooltip" data-bs-toggle="tooltip"
                    data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-html="true"
                    title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                    <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.feedback_data.colors.text_color}"></i>
                </div>
            </div>
            <div *ngIf="data.feedback_data.share_on">
                <div class="share-button">
                    <app-share-to-social [data]="social_data" [text_color]="data.feedback_data.colors.text_color"
                        [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
                </div>
            </div>
        </div>
    </div>
    <div class="preview-body">

        <ng-container *ngIf="!steps">

            <div class="header-caption" *ngIf="level1_index === -1"
                [ngStyle]="{'background-color': data.feedback_data.colors.secondary_color}">
                <b [ngStyle]="{'color': data.feedback_data.colors.text_color}">{{data.feedback_data.headings.main}}</b>
                <p *ngIf="data.feedback_data.headings.meta" [ngStyle]="{'color': data.feedback_data.colors.text_color}">
                    {{data.feedback_data.headings.meta}}</p>
            </div>

            <ng-container *ngIf="level1_index === -1; else questionPicked">
                <!-- First level -->
                <ng-container *ngFor="let question of data.feedback_data.feedback.questions; let i = index">
                    <div class="media-wrapper animated fadeIn delay-{{i}}" (click)="level1_index = i">
                        <div class="media-logo" *ngIf="question.icon">
                            <i [class]="question.icon" [class.text-success]="allResponseReceived(question)">
                            </i>
                        </div>
                        <div class="media-contents">
                            <b>{{question.title}}</b>
                        </div>
                        <div class="media-icon">
                            <i class="bi bi-chevron-right bi-sm"></i>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #questionPicked>
                <ng-container
                    *ngIf="!data.feedback_data.feedback.questions[level1_index].subs.length && level2_index === -1; else hasSubs">
                    <!-- Second level (No subs) -->
                    <span class="go-back d-flex align-items-center mouse-pointer mb-3" (click)="level1_index = -1">
                        <i class="bi bi-arrow-left me-2"></i>
                        <small class="m-0">Go back</small>
                    </span>
                    <div class="review-wrapper text-center">
                        <b>{{data.feedback_data.feedback.questions[level1_index].title}}</b><br>
                        <small>Please evaluate using the stars.</small>
                        <div class="star-wrapper mt-3 mb-4">
                            <star-rating [value]="data.feedback_data.feedback.questions[level1_index].feedback.rating"
                                [totalstars]="5" checkedcolor="#F6A623" uncheckedcolor="#D0D1D3"
                                (rate)="data.feedback_data.feedback.questions[level1_index].feedback.rating = coreService.onRate($event)"
                                size="24px" [readonly]="false"></star-rating>


                        </div>
                        <textarea class="form-control"
                            [(ngModel)]="data.feedback_data.feedback.questions[level1_index].feedback.description"
                            rows="4" placeholder="Add a comment (optional)"></textarea>
                        <button class="btn btn-block mt-3" *ngIf="shouldSavenContinue()" (click)="level1_index = -1"
                            [ngStyle]="{'border-color': data.feedback_data.colors.secondary_color, 'color': data.feedback_data.colors.secondary_color}"
                            [disabled]="!data.feedback_data.feedback.questions[level1_index].feedback.rating">Save &
                            Continue</button>
                        <div class="mt-2"
                            *ngIf="data.feedback_data.feedback.questions[level1_index].feedback.rating > 0">
                            <small class="mouse-pointer"
                                (click)="data.feedback_data.feedback.questions[level1_index].feedback.rating = 0; data.feedback_data.feedback.questions[level1_index].feedback.description = ''">Reset
                                feedback</small>
                        </div>
                    </div>
                </ng-container>
                <ng-template #hasSubs>
                    <!-- Third level -->
                    <ng-container *ngIf="level2_index === -1; else showingInnerSub">
                        <span class="go-back d-flex align-items-center mouse-pointer mb-3" (click)="level1_index = -1">
                            <i class="bi bi-arrow-left me-2"></i>
                            <small class="m-0">Go back</small>
                        </span>
                        <ng-container
                            *ngFor="let question of data.feedback_data.feedback.questions[level1_index].subs; let _index = index">
                            <div class="media-wrapper animated fadeIn delay-{{_index}}" (click)="level2_index = _index">
                                <div class="media-logo">
                                    <i *ngIf="question.feedback.rating || question.feedback.description; else stillPendingResponse"
                                        class="bi bi-check2 text-success"></i>
                                    <ng-template #stillPendingResponse>
                                        <i class="bi bi-dash-circle-dotted"></i>
                                    </ng-template>
                                </div>
                                <div class="media-contents">
                                    <b>{{question.title}}</b>
                                </div>
                                <div class="media-icon">
                                    <i class="bi bi-chevron-right bi-sm"></i>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #showingInnerSub>
                        <span class="go-back d-flex align-items-center mouse-pointer mb-3" (click)="level2_index = -1">
                            <i class="bi bi-arrow-left me-2"></i>
                            <small class="m-0">Go back</small>
                        </span>
                        <div class="review-wrapper text-center">
                            <b>{{data.feedback_data.feedback.questions[level1_index].subs[level2_index].title}}</b><br>
                            <small>Please evaluate using the stars.</small>
                            <div class="star-wrapper mt-3 mb-4">

                                <star-rating
                                    [value]="data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.rating"
                                    [totalstars]="5" checkedcolor="#F6A623" uncheckedcolor="#D0D1D3" size="24px"
                                    [readonly]="false"
                                    (rate)="data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.rating = coreService.onRate($event)">
                                </star-rating>

                            </div>
                            <textarea class="form-control"
                                [(ngModel)]="data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.description"
                                rows="5" placeholder="Add a comment (optional)"></textarea>
                            <button class="btn btn-block mt-3" *ngIf="shouldSavenContinue()" (click)="level2_index = -1"
                                [ngStyle]="{'border-color': data.feedback_data.colors.secondary_color, 'color': data.feedback_data.colors.secondary_color}"
                                [disabled]="!data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.rating">Save
                                & Continue</button>

                            <div class="mt-2"
                                *ngIf="data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.rating > 0">
                                <small class="mouse-pointer"
                                    (click)="data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.rating = 0; data.feedback_data.feedback.questions[level1_index].subs[level2_index].feedback.description = ''">Reset
                                    feedback</small>
                            </div>
                        </div>
                    </ng-template>

                </ng-template>

            </ng-template>

        </ng-container>

        <ng-container *ngIf="steps === 1">
            <div class="review-wrapper">
                <p>Please provide your contact details if you want us to get back to you.</p>
                <div class="mb-3 mt-3">
                    <small>Name</small>
                    <input type="text" [(ngModel)]="data.feedback_data.feedback.contact.name"
                        class="form-control form-control-sm" placeholder="Your name (Optional)">
                </div>
                <div class="mb-3">
                    <small>Email</small>
                    <input [(ngModel)]="data.feedback_data.feedback.contact.email" type="email"
                        class="form-control form-control-sm" placeholder="Please type your email (Optional)">
                </div>
                <div class="mb-3">
                    <small>Mobile Number</small>
                    <input type="text" [(ngModel)]="data.feedback_data.feedback.contact.mobile"
                        class="form-control form-control-sm" placeholder="(012) 345 67891 (Optional)">
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="steps === 2">
            <div class="review-wrapper text-center">
                <div class="pb-3">
                    <svg viewBox="0 0 478 478" width="50">
                        <rect id="Rectangle-Copy-360" fill="#FFFFFF" x="71" y="60" width="341" height="358" rx="170.5">
                        </rect>
                        <g id="check" fill="#23B559" fill-rule="nonzero">
                            <path
                                d="M238.933,0 C106.974,0 0,106.974 0,238.933 C0,370.892 106.974,477.866 238.933,477.866 C370.892,477.866 477.866,370.892 477.866,238.933 C477.726,107.033 370.834,0.141 238.933,0 Z M370.466,165.666 L199.799,336.333 C193.134,342.996 182.331,342.996 175.667,336.333 L107.4,268.066 C100.62,261.518 100.432,250.714 106.98,243.934 C113.528,237.154 124.332,236.966 131.112,243.514 C131.254,243.652 131.394,243.791 131.532,243.934 L187.733,300.135 L346.334,141.534 C353.114,134.986 363.918,135.174 370.466,141.953 C376.854,148.567 376.854,159.052 370.466,165.666 Z"
                                id="Shape"></path>
                        </g>
                    </svg>
                </div>
                <p>Thanks for your response! Your feedback has been received successfully.</p>
                <button class="btn btn-block mt-4" (click)="resetSteps()"
                    [ngStyle]="{'background-color': data.feedback_data.colors.secondary_color, 'color': data.feedback_data.colors.text_color}">Back
                    Home</button>
            </div>
        </ng-container>

        <div class="text-center mt-2" *ngIf="steps === 1">
            <small>
                <a [ngStyle]="{'color': data.feedback_data.colors.secondary_color}" class="mouse-pointer"
                    (click)="steps = 0">Cancel</a>
            </small>
        </div>

        <div class="text-center mt-3" *ngIf="steps !== 1">
            <small><a [ngStyle]="{'color': data.feedback_data.colors.secondary_color}"
                    [href]="functions.forceUrl(data.feedback_data.contact.website)" target="_blank">Visit our
                    website</a></small>
        </div>
    </div>

    <div class="btn-wrapper">
        <button class="btn btn-block mt-4" *ngIf="shouldShowSend()" (click)="startSubmit()"
            [ngStyle]="{'background-color': data.feedback_data.colors.secondary_color, 'color': data.feedback_data.colors.text_color}">Submit
            feedback
        </button>
    </div>

</div>