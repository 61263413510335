import { Component, Inject, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { environment } from 'src/environments/environment';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, QrGeneratorSOCIALS } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-video',
  templateUrl: './shared-qr-preview-video.component.html',
  styleUrls: ['./shared-qr-preview-video.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewVideoComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_socials_obs: Subject<QrGeneratorSOCIALS[]> = new Subject<QrGeneratorSOCIALS[]>()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category == QrGeneratorNavigatorCategories.VIDEOS)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {

        if (d.videos_data) {
          this.event_additional_settings_obs.next({
            text_color: d.videos_data.colors.text_color,
            tooltip: ""
          })
          this.functions.initializeTooltip()
        }
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.videos_data = this.qrGeneratorService.initVideoCategoryData()
          this.data.videos_data.headings.main = "How To Generate QR Code For Your Business Page"
          this.data.videos_data.headings.meta = "Elevate your business page with eye-catching QR codes using BrandQRcodes.com!"
          this.data.videos_data.company_name = ""
          this.data.videos_data.logo.url = `${environment.app_url_full}assets/logos/logo-white.png`
          this.data.videos_data.videos.push({
            position: 0,
            title: "How To Generate QR Code For Your Business Page",
            favicon: "",
            highlight: true,
            video_ori_url: "https://www.youtube.com/watch?v=W2E9bVNSFN8",
            video_embed_url: "https://www.youtube.com/embed/W2E9bVNSFN8"
          })
          this.data.videos_data.videos.push({
            position: 0,
            title: "Create a Central Image Gallery with Memories QR Codes!",
            favicon: "",
            highlight: false,
            video_ori_url: "https://www.youtube.com/watch?v=KXAtGVkEQDY",
            video_embed_url: "https://www.youtube.com/embed/KXAtGVkEQDY"
          })
          this.data.videos_data.colors.primary_color = "#101820FF"
          this.data.videos_data.colors.secondary_color = "#d92027"
          this.data.videos_data.colors.text_color = "#FFFFFF"
          this.event_socials_obs.next(this.data.videos_data.socials)
          return
        }
        this.data = d
        if (this.data.videos_data)
          this.event_socials_obs.next(this.data.videos_data.socials)
      }
    }));
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }


}
