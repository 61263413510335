import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input() color = ""
  @Input() _class: string = ""
  @Input() processing = "processing"
  @Input() type: "default" | "percentage" = "default"

  percentage = 1
  @Input() percentage_obs: Observable<number>;

  private subscriptions: Subscription[] = []

  p_style = {}
  hr_style = {}

  constructor() { }

  ngOnInit(): void {
    if (this.percentage_obs) {
      this.subscriptions.push(this.percentage_obs.subscribe(p => {
        this.percentage = p
        this.hr_style = {
          width: this.percentage + '%',
          ...(this.color ? { background: this.color } : {})
        }
      }))

      this.p_style = {
        ...(this.color ? { color: this.color } : {})
      }
      this.hr_style = {
        width: this.percentage + '%',
        ...(this.color ? { background: this.color } : {})
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe())
  }

}
