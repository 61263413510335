<ng-container *ngIf="data?.pet_data">
  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType1">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
            *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
            [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
        </div>

        <div class="profile">
          <div class="profile-contents" [ngStyle]="{'background-color': data.pet_data.colors.secondary_color}">
            <b class="profile-info"
              [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
            <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}">
            </div>
          </div>
        </div>
        <div class="preview-body">
          <div class="d-flex pe-1 flex-column align-items-end">
            <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              <b>{{data.pet_data.headings.main}}</b>
            </h6>
            <p class="mt-n2 mb-4 fw-light" [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              {{data.pet_data.headings.meta}}</p>
          </div>

          <div class="preview-contents">
            <div class="ql-container ql-snow" *ngIf="data.pet_data.summary" style="border-width: 0;">
              <small class="ql-editor text-center pb-4" id="ql-editor" [innerHTML]="data.pet_data.summary">
              </small>
            </div>
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>
          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType2">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
          <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}"></div>
        </div>
        <div class="profile-contents" [ngStyle]="{'background-color': data.pet_data.colors.secondary_color}">
          <div class="profile-info">
            <b [ngStyle]="{'color': data.pet_data.colors.primary_color}">{{data.pet_data.headings.main}}</b>
            <p [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              {{data.pet_data.headings.meta}}</p>
            <div class="ql-container ql-snow" [ngStyle]="{'color': data.pet_data.colors.text_color}"
              *ngIf="data.pet_data.summary" style="border-width: 0;">
              <small class="ql-editor text-center pb-4" id="ql-editor"
                [ngStyle]="{'color': data.pet_data.colors.text_color}" [innerHTML]="data.pet_data.summary">
              </small>
            </div>
          </div>
        </div>
        <div class="preview-body">
          <div class="preview-contents">

            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>
          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType3">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-end pe-3">
          <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
            *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.secondary_color"
            [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
        </div>
        <div class="profile-contents" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}">
          </div>
          <b class="profile-info" [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
        </div>
        <div class="preview-body">
          <div class="title-container" [ngStyle]="{'border-left-color': data.pet_data.colors.secondary_color}">
            <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              <b>{{data.pet_data.headings.main}}</b>
            </h6>
            <p [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              {{data.pet_data.headings.meta}}</p>
          </div>

          <div class="ql-container ql-snow" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
            *ngIf="data.pet_data.summary" style="border-width: 0;">
            <small class="ql-editor text-center pb-4" id="ql-editor"
              [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
            </small>
          </div>

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType4">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-end pe-3 pb-3">
          <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
            *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.secondary_color"
            [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
        </div>
        <div class="profile-contents" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <b class="profile-info" [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
          <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}">
          </div>
        </div>
        <div class="preview-body">
          <div class="title-container" [ngStyle]="{'border-left-color': data.pet_data.colors.secondary_color}">
            <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              <b>{{data.pet_data.headings.main}}</b>
            </h6>
            <p [ngStyle]="{'color': data.pet_data.colors.primary_color}">
              {{data.pet_data.headings.meta}}</p>
            <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
              *ngIf="data.pet_data.summary" style="border-width: 0;">
              <small class="ql-editor p-0" id="ql-editor" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
                [innerHTML]="data.pet_data.summary">
              </small>
            </div>
          </div>

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType5">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
        </div>

        <div class="preview-body">
          <div class="profile">
            <div class="profile-avatar"
              [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')', 'border-color': data.pet_data.colors.text_color}">
            </div>
            <div class="profile-contents">
              <div class="profile-info">
                <div class="d-flex mb-3 flex-column title-container"
                  [ngStyle]="{'border-left-color': data.pet_data.colors.secondary_color}">
                  <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    <b>{{data.pet_data.headings.main}}</b>
                  </h6>
                  <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    {{data.pet_data.headings.meta}}</p>
                  <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
                    *ngIf="data.pet_data.summary" style="border-width: 0;">
                    <small class="ql-editor text-center" id="ql-editor"
                      [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType6">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
        </div>

        <div class="preview-body">
          <div class="profile">
            <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}">
            </div>
            <div class="profile-contents">
              <div class="profile-info">
                <div class="d-flex mb-3 flex-column title-container"
                  [ngStyle]="{'border-left-color': data.pet_data.colors.secondary_color}">
                  <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    <b>{{data.pet_data.headings.main}}</b>
                  </h6>
                  <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    {{data.pet_data.headings.meta}}</p>
                  <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
                    *ngIf="data.pet_data.summary" style="border-width: 0;">
                    <small class="ql-editor text-center pb-4" id="ql-editor"
                      [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType7">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>

          <div class="profile">
            <div class="profile-avatar"
              [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')', 'border-color': data.pet_data.colors.text_color}">
            </div>
            <div class="profile-contents">
              <div class="profile-info">
                <div class="d-flex mb-3 flex-column title-container">
                  <h6 [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    <b>{{data.pet_data.headings.main}}</b>
                  </h6>
                  <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    {{data.pet_data.headings.meta}}</p>
                  <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.text_color}"
                    *ngIf="data.pet_data.summary" style="border-width: 0;">
                    <small class="ql-editor text-center" id="ql-editor"
                      [ngStyle]="{'color': data.pet_data.colors.text_color}" [innerHTML]="data.pet_data.summary">
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="preview-body">

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType8">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
        </div>

        <div class="preview-body">
          <div class="profile">
            <div class="profile-avatar"
              [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')', 'border-color': data.pet_data.colors.text_color}">
            </div>
            <div class="profile-contents">
              <div class="profile-info">
                <div class="d-flex mb-3 flex-column title-container"
                  [ngStyle]="{'border-left-color': data.pet_data.colors.secondary_color}">
                  <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    <b>{{data.pet_data.headings.main}}</b>
                  </h6>
                  <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                    {{data.pet_data.headings.meta}}</p>
                  <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
                    *ngIf="data.pet_data.summary" style="border-width: 0;">
                    <small class="ql-editor text-center pb-4" id="ql-editor"
                      [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="[ThemeTypes.ThemeType9,ThemeTypes.ThemeType10].includes(data.pet_data.theme.type)">
    <div class="preview-wrapper animated fadeIn {{ThemeTypes.ThemeType9}} {{ThemeTypes.ThemeType10}}"
      [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">
      <div class="pet-contents">
        <div class="profile-img">
          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>
          <div class="profile-avatar" [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')'}"></div>
        </div>

        <div class="profile mb-3">
          <div class="profile-contents"
            [ngStyle]="{'background': ThemeTypes.ThemeType9 === data.pet_data.theme.type ? 'linear-gradient(to top, ' + data.pet_data.colors.primary_color + ', ' + data.pet_data.colors.primary_color + ', ' + data.pet_data.colors.primary_color + ', rgba(' + functions.hexToRgb(data.pet_data.colors.primary_color).join(',') + ', 0%))': data.pet_data.colors.secondary_color}">
            <div class="profile-info">
              <div class="d-flex flex-column">
                <div class="profile-name-wrapper">
                  <h3 class="fw-bold" [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    {{data.pet_data.pet_name}}</h3>
                  <h6 class="fw-light mt-n2" [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    {{data.pet_data.headings.main}}
                  </h6>
                  <p class="mt-n2 fw-lighter" [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    {{data.pet_data.headings.meta}}</p>
                </div>
              </div>
              <div class="ql-container ql-snow mt-1" [ngStyle]="{'color': data.pet_data.colors.text_color}"
                *ngIf="data.pet_data.summary" style="border-width: 0;">
                <small class="ql-editor p-0" id="ql-editor" [ngStyle]="{'color': data.pet_data.colors.text_color}"
                  [innerHTML]="data.pet_data.summary">
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="preview-body">
          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <button class="btn btn-sm w-100 mb-3 px-0" *ngIf="data.pet_data.show_send_location"
              [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
              <i class="bi bi-geo-alt"></i>
              {{'send_location_to_owner' | translate}}
            </button>
            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType11">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">

          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>

          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>

          <div class="profile">
            <div class="profile-avatar"
              [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')', 'border-color': data.pet_data.colors.secondary_color}">
            </div>
            <div class="profile-contents">
              <div class="profile-info">
                <div class="d-flex flex-column title-container">
                  <h6 [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    <b>{{data.pet_data.headings.main}}</b>
                  </h6>
                  <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.text_color}">
                    {{data.pet_data.headings.meta}}</p>
                  <button class="btn btn-sm my-2 px-0" *ngIf="data.pet_data.show_send_location"
                    [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
                    <i class="bi bi-geo-alt"></i>
                    {{'send_location_to_owner' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ql-container text-center l-snow mt-4" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
          *ngIf="data.pet_data.summary" style="border-width: 0;">
          <small class="ql-editor text-center pb-4" id="ql-editor"
            [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
          </small>
        </div>
        <div class="preview-body">
          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.pet_data.theme.type === ThemeTypes.ThemeType12">
    <div class="preview-wrapper animated fadeIn {{data.pet_data.theme.type}}">
      <div class="pet-contents">
        <div class="preview-header text-center" [ngStyle]="{'background-color': data.pet_data.colors.primary_color}">

          <div class="language-wrapper">
            <app-language-switch [currentLanguage]="selected_current_language_obs.asObservable()"
              *ngIf="data.pet_data.language?.switchable" [color]="data.pet_data.colors.text_color"
              [settings]="data.pet_data.language" (nextLanguage)="translateService.use($event)"></app-language-switch>
          </div>

          <b [ngStyle]="{'color': data.pet_data.colors.text_color}">{{data.pet_data.pet_name}}</b>
        </div>
        <div class="profile">
          <div class="profile-img text-center">
            <div class="profile-avatar"
              [ngStyle]="{'background-image': 'url(' + data.pet_data.profile_url + ')', 'border-color': data.pet_data.colors.secondary_color}">
            </div>
          </div>
          <div class="profile-contents">
            <div class="profile-info">
              <div class="d-flex flex-column title-container text-center pt-3">
                <h6 [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                  <b>{{data.pet_data.headings.main}}</b>
                </h6>
                <p class="mt-n2 fw-light" [ngStyle]="{'color': data.pet_data.colors.primary_color}">
                  {{data.pet_data.headings.meta}}</p>
                <button class="btn btn-sm my-2 px-0" *ngIf="data.pet_data.show_send_location"
                  [ngStyle]="{'background-color': data.pet_data.colors.secondary_color, 'color': data.pet_data.colors.text_color}">
                  <i class="bi bi-geo-alt"></i>
                  {{'send_location_to_owner' | translate}}
                </button>
                <div class="ql-container ql-snow" [ngStyle]="{'color': data.pet_data.colors.primary_color}"
                  *ngIf="data.pet_data.summary" style="border-width: 0;">
                  <small class="ql-editor text-center" id="ql-editor"
                    [ngStyle]="{'color': data.pet_data.colors.primary_color}" [innerHTML]="data.pet_data.summary">
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="preview-body">
          <div class="preview-contents">
            <div class="media-parent">
              <h6 class="pt-2">
                <b>{{'about' | translate }} {{data.pet_data.pet_name}}</b>
              </h6>
              <div class="media-wrapper" *ngIf="data.pet_data.pet_name">
                <div class="media-logo">
                  <i class="bi bi-person-square bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.pet_name}}</p>
                </div>
              </div>
              <ng-container *ngFor="let item of data.pet_data.other_information">
                <div class="media-wrapper">
                  <div class="media-logo">
                    <i class="{{item.icon}} bi-lg me-3"></i>
                  </div>
                  <div class="media-contents">
                    <b>{{item.key}}</b>
                    <p>{{item.value}}</p>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="media-gallery py-2" *ngIf="data.pet_data.gallery.show"
              [class.grid]="data.pet_data.gallery.images.grid && data.pet_data.gallery.images.list.length > 1">
              <img loading="lazy" *ngFor="let image of data.pet_data.gallery.images.list" [src]="image.url">
            </div>
          </div>

          <div class="preview-contents">
            <h6 class="pt-2">
              <b>{{'owners_details' | translate}}</b>
            </h6>
            <div class="media-parent">
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.name">
                <div class="media-logo">
                  <i class="bi bi-person bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'name' | translate}}</b>
                  <p>{{data.pet_data.owner_details.name}}</p>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.mobile">
                <div class="media-logo">
                  <i class="bi bi-phone bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'mobile' | translate}}</b>
                  <a href="tel:{{data.pet_data.owner_details.mobile}}"
                    target="_blank">{{data.pet_data.owner_details.mobile}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="functions.isEmail(data.pet_data.owner_details.email)">
                <div class="media-logo">
                  <i class="bi bi-send bi-lg me-3"></i>
                </div>
                <div class="media-contents text-truncate">
                  <b>{{'email' | translate}}</b>
                  <a [href]="'mailto:' + data.pet_data.owner_details.email"
                    target="_blank">{{data.pet_data.owner_details.email}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.website">
                <div class="media-logo">
                  <i class="bi bi-globe2 bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'website' | translate}}</b>
                  <a [href]="functions.forceUrl(data.pet_data.owner_details.website)"
                    target="_blank">{{data.pet_data.owner_details.website}}</a>
                </div>
              </div>
              <div class="media-wrapper" *ngIf="data.pet_data.owner_details.location.address">
                <div class="media-logo">
                  <i class="bi bi-geo bi-lg me-3"></i>
                </div>
                <div class="media-contents">
                  <b>{{'location' | translate}}</b>
                  <a *ngIf="data.pet_data.owner_details.location.location_url; else noLinkToLocation"
                    [href]="functions.forceUrl(data.pet_data.owner_details.location.location_url)"
                    target="_blank">{{data.pet_data.owner_details.location.address}}</a>
                  <ng-template #noLinkToLocation>
                    <p>{{data.pet_data.owner_details.location.address}}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="footer" *ngIf="data.pet_data.logo.show">
            <img loading="lazy" [src]="data.pet_data.logo.url" height="26" class="mb-3">
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
    </div>
  </ng-container>

  <ng-template #stickyFooterContent>
    <div class="d-flex flex-column bd-highlight sticky-footer-container">
      <ng-container *ngIf="data.pet_data.owner_details.savable">
        <div class="mb-2">
          <div title="Save contact" [class.disable-div]="!data.pet_data.owner_details.mobile?.trim()"
            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="left" class="save-button"
            [ngStyle]="{'background-color': data.pet_data.colors.secondary_color}">
            <a (click)="qrGeneratorService.savePetOwnerInfoToDevice(data.pet_data)">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14pt" height="14pt"
                viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  [attr.fill]="data.pet_data.colors.text_color" stroke="none">
                  <path d="M1043 4955 c-192 -42 -353 -193 -420 -394 -11 -33 -17 -107 -20 -267
                            l-5 -222 -92 -4 c-142 -6 -201 -51 -201 -153 0 -102 57 -145 201 -153 l94 -5
                            0 -298 0 -299 -93 0 c-110 0 -150 -17 -185 -76 -40 -68 -19 -145 52 -198 22
                            -17 45 -22 127 -24 l99 -4 0 -298 0 -298 -99 -4 c-82 -2 -105 -7 -127 -24 -71
                            -53 -92 -130 -52 -198 35 -59 75 -76 185 -76 l93 0 0 -299 0 -298 -94 -5
                            c-144 -8 -201 -51 -201 -153 0 -102 59 -147 201 -153 l92 -4 4 -222 c5 -239 9
                            -260 73 -386 39 -77 153 -187 237 -228 132 -65 44 -62 1798 -62 1754 0 1668
                            -3 1800 62 89 43 204 159 248 250 65 134 62 15 62 2098 0 2080 3 1963 -61
                            2097 -43 91 -158 207 -249 251 -132 65 -45 62 -1805 61 -1308 0 -1611 -3
                            -1662 -14z m3279 -295 c63 -18 129 -70 162 -130 l31 -55 0 -1915 0 -1915 -31
                            -55 c-34 -60 -100 -113 -164 -130 -26 -7 -563 -9 -1632 -8 l-1593 3 -55 31
                            c-66 37 -113 101 -129 174 -6 28 -11 127 -11 219 l0 169 98 4 c111 5 149 20
                            181 71 26 43 28 117 4 159 -32 53 -74 71 -184 76 l-99 5 2 296 3 296 101 5
                            c111 6 141 18 174 73 28 45 26 115 -2 157 -38 54 -79 70 -185 70 l-93 0 0 300
                            0 300 93 0 c106 0 147 16 185 70 28 42 30 112 2 157 -33 55 -63 67 -174 73
                            l-101 5 -3 296 -2 296 99 5 c110 5 152 23 184 76 24 42 22 116 -4 159 -32 51
                            -70 66 -182 71 l-99 4 4 197 c3 197 3 198 33 257 32 62 94 114 159 133 45 13
                            3181 14 3228 1z" />
                  <path d="M2770 4060 c-360 -44 -639 -346 -657 -710 -12 -227 64 -417 230 -577
                            98 -95 207 -156 347 -195 69 -19 282 -16 360 6 272 73 485 295 545 569 27 122
                            18 286 -22 400 -44 124 -85 189 -188 292 -76 77 -104 98 -185 137 -143 70
                            -286 96 -430 78z m234 -320 c226 -70 359 -329 287 -559 -66 -211 -285 -350
                            -496 -314 -363 62 -512 500 -257 758 121 123 298 167 466 115z" />
                  <path d="M2685 2396 c-324 -54 -597 -217 -789 -473 -144 -193 -236 -456 -236
                                    -679 0 -99 14 -132 70 -167 l35 -22 1073 -3 c757 -2 1086 1 1114 9 51 13 93
                                    54 107 104 14 53 -3 225 -35 345 -114 434 -465 772 -904 871 -112 25 -329 33
                                    -435 15z m400 -315 c222 -57 433 -216 554 -416 37 -61 95 -207 105 -267 l7
                                    -38 -891 0 -890 0 6 28 c82 372 378 653 752 712 87 14 266 4 357 -19z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </ng-container>

      <div class="mb-2" *ngIf="data.pet_data.show_qr_code">
        <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
          [ngStyle]="{'background-color': data.pet_data.colors.secondary_color}" data-bs-toggle="tooltip"
          data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
          title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
          <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.pet_data.colors.text_color}"></i>
        </div>
      </div>

      <div class="mb-2" *ngIf="data.pet_data.share_on">
        <div class="share-button" [ngStyle]="{'background-color': data.pet_data.colors.secondary_color}">
          <app-share-to-social [data]="social_data" [text_color]="data.pet_data.colors.text_color"
            [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
        </div>
      </div>

    </div>
  </ng-template>

</ng-container>