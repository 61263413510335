import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-txt-rotate',
  templateUrl: './txt-rotate.component.html',
  styleUrls: ['./txt-rotate.component.scss']
})
export class TxtRotateComponent implements OnInit {

  @Input() speed = 50
  @Input() pause_duration = 2000
  @Input() texts: string[] = []

  text = ''
  private current_index = 0
  private is_deleting = false

  constructor() { }

  ngOnInit() {
    this.initTxtRotate()
  }

  initTxtRotate() {
    if (!this.texts?.length) return

    const full_text = this.texts[this.current_index];

    if (this.is_deleting) {
      this.text = full_text.substring(0, this.text.length - 1);
    } else {
      this.text = full_text.substring(0, this.text.length + 1);
    }

    let speed = this.speed

    if (this.is_deleting) { speed /= 4; }

    if (!this.is_deleting && this.text === full_text) {
      speed = this.pause_duration;
      this.is_deleting = true;
    } else if (this.is_deleting && this.text === '') {
      this.is_deleting = false;
      this.current_index++;
      speed = 500;
      if (this.texts.length === this.current_index) {
        this.current_index = 0
      }
    }

    setTimeout(() => {
      this.initTxtRotate();
    }, speed);

  }

}
