import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';

@Component({
  selector: 'app-shared-qr-preview-sms',
  templateUrl: './shared-qr-preview-sms.component.html',
  styleUrls: ['./shared-qr-preview-sms.component.scss']
})
export class SharedQrPreviewSmsComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  constructor(
    private coreService: CoreService,
    private qrGeneratorService: UserQrGeneratorService
  ) {

    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si

      let d: QrGeneratorNavigator
      if (this.create_mode) {
        d = this.session_info.qr_generating.drafts.find(d => d.selected_category == QrGeneratorNavigatorCategories.SMS)
      } else {
        d = this.session_info.qr_generating.updating
      }

      if (d) {
        if (d.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data = JSON.parse(JSON.stringify(d))
          this.data.sms_data = this.qrGeneratorService.initSMSCategoryData()
          return
        }
        this.data = d
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
