import { Component, Inject, OnInit } from '@angular/core';
import { SocialHelperAdditionalSettings } from '@app/store/models/social-helper-additional-settings.interface';
import { Subscription, Subject } from 'rxjs';

import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorCategories, today } from 'shared-library';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';

@Component({
  selector: 'app-shared-qr-preview-coupons',
  templateUrl: './shared-qr-preview-coupons.component.html',
  styleUrls: ['./shared-qr-preview-coupons.component.scss', '../shared-qr-preview.component.scss']
})
export class SharedQrPreviewCouponsComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  code_copied = false
  code_copied_cc: any

  session_info: SessionInfo
  data: QrGeneratorNavigator
  private subscriptions: Subscription[] = []

  social_data = this.qrGeneratorService.initSocialHelper()
  event_additional_settings_obs: Subject<SocialHelperAdditionalSettings> = new Subject<SocialHelperAdditionalSettings>()

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService,
    @Inject(APP_CONFIG) public readonly config: IAppConfig
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.session_info) {
        if (this.create_mode) {
          this.data = this.session_info.qr_generating.drafts.find(d => d.selected_category === QrGeneratorNavigatorCategories.COUPONS)
        } else {
          this.data = this.session_info.qr_generating.updating
        }
        this.functions.initializeTooltip()

        if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data.coupons_data = JSON.parse(JSON.stringify(this.qrGeneratorService.initCouponsCategoryData()))
          this.data.coupons_data.company_name = "Sarah's Boutique"
          this.data.coupons_data.share_on = true
          this.data.coupons_data.coupon.expiry_date = today()
          this.data.coupons_data.headings.main = "Huge Christmas sales!"
          this.data.coupons_data.headings.meta = "Get up to 50% off all items in our stores nationwide. No hidden conditions. Just shop!"
          this.data.coupons_data.ctas.reveal_coupon.text = "GET COUPON"
          this.data.coupons_data.coupon.code = "EXAMPLECODE1"
          this.data.coupons_data.colors.primary_color = "#F8B202"
          this.data.coupons_data.colors.secondary_color = "#D0041B"
        }
      }
    }))
  }

  ngOnInit(): void {
    this.functions.initializeTooltip()
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

  localCopyToClipboard(whatToCopy: string) {
    this.functions.copyToClipboard(whatToCopy, "")
    this.code_copied = true
    clearTimeout(this.code_copied_cc)
    this.code_copied_cc = setTimeout(() => {
      this.code_copied = false
    }, 3000)
  }

  toggleCodeReveal() {
    this.data.coupons_data.coupon.showing = !this.data.coupons_data.coupon.showing
    // if (this.ori_data.coupons_data) {
    //   this.ori_data.coupons_data.coupon.showing = this.data.coupons_data.coupon.showing
    //   this.user.setSessionData(this.session_info)
    // }
  }

}
