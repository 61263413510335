<div class="iphonex-wrapper">
  <div class="iphonex">
    <div class="iphonex__case">
      <div class="iphonex__inner">
        <ng-container *ngIf="_page">
          <app-shared-qr-preview-social-media *ngIf="all_categories.SOCIAL_MEDIA === _page">
          </app-shared-qr-preview-social-media>
          <app-shared-qr-preview-website *ngIf="all_categories.WEBSITE === _page">
          </app-shared-qr-preview-website>
          <app-shared-qr-preview-url *ngIf="all_categories.URL === _page">
          </app-shared-qr-preview-url>
          <app-shared-qr-preview-location [selected_category]="_page"
            *ngIf="[all_categories.LOCATION, all_categories.LOCATION_PLUS].includes(_page)">
          </app-shared-qr-preview-location>
          <app-shared-qr-preview-bitcoins *ngIf="all_categories.BITCOIN === _page">
          </app-shared-qr-preview-bitcoins>
          <app-shared-qr-preview-wifi *ngIf="all_categories.WIFI === _page">
          </app-shared-qr-preview-wifi>
          <app-shared-qr-preview-coupons *ngIf="all_categories.COUPONS === _page">
          </app-shared-qr-preview-coupons>
          <app-shared-qr-preview-vcard-pro *ngIf="all_categories.VCARD_PRO === _page">
          </app-shared-qr-preview-vcard-pro>
          <app-shared-qr-preview-pet *ngIf="all_categories.PET === _page">
          </app-shared-qr-preview-pet>
          <app-shared-qr-preview-event *ngIf="all_categories.EVENT === _page">
          </app-shared-qr-preview-event>
          <app-shared-qr-preview-business-page *ngIf="all_categories.BUSINESS_PAGE === _page">
          </app-shared-qr-preview-business-page>
          <app-shared-qr-preview-long-plain-text *ngIf="all_categories.LONG_PLAIN_TEXT === _page">
          </app-shared-qr-preview-long-plain-text>
          <app-shared-qr-preview-feedback *ngIf="all_categories.FEEDBACK === _page">
          </app-shared-qr-preview-feedback>
          <app-shared-qr-preview-vacancy *ngIf="all_categories.VACANCY === _page">
          </app-shared-qr-preview-vacancy>
          <app-shared-qr-preview-ratings *ngIf="all_categories.RATING === _page">
          </app-shared-qr-preview-ratings>
          <app-shared-qr-preview-video *ngIf="all_categories.VIDEOS === _page">
          </app-shared-qr-preview-video>
          <app-shared-qr-preview-pdf *ngIf="all_categories.PDF === _page"></app-shared-qr-preview-pdf>
          <app-shared-qr-preview-app *ngIf="all_categories.APP === _page"></app-shared-qr-preview-app>
          <app-shared-qr-preview-mp3 *ngIf="all_categories.MP3 === _page"></app-shared-qr-preview-mp3>
          <app-shared-qr-preview-restaurant-menu
            *ngIf="all_categories.RESTAURANT_MENU === _page"></app-shared-qr-preview-restaurant-menu>
          <app-shared-qr-preview-images *ngIf="all_categories.IMAGES === _page">
          </app-shared-qr-preview-images>
          <app-shared-qr-preview-memories *ngIf="all_categories.MEMORIES === _page">
          </app-shared-qr-preview-memories>
          <app-shared-qr-preview-vcard *ngIf="all_categories.VCARD === _page">
          </app-shared-qr-preview-vcard>
          <app-shared-qr-preview-email *ngIf="all_categories.EMAIL === _page">
          </app-shared-qr-preview-email>
          <app-shared-qr-preview-sms *ngIf="all_categories.SMS === _page"></app-shared-qr-preview-sms>
          <app-shared-qr-preview-text *ngIf="all_categories.TEXT === _page">
          </app-shared-qr-preview-text>
        </ng-container>
        <ng-container *ngIf="popup_page">
          <app-shared-popup-preview-lightbox *ngIf="all_categories.LIGHTBOX === popup_page">
          </app-shared-popup-preview-lightbox>
          <app-shared-popup-preview-slidein *ngIf="all_categories.SLIDE_IN === popup_page">
          </app-shared-popup-preview-slidein>
          <app-shared-popup-preview-bigsidebar *ngIf="all_categories.BIG_SIDE_BAR === popup_page">
          </app-shared-popup-preview-bigsidebar>
          <app-shared-popup-preview-successpage *ngIf="all_categories.SUCCESS_PAGE === popup_page">
          </app-shared-popup-preview-successpage>
        </ng-container>
      </div>
    </div>
    <div class="iphonex__power"></div>
    <div class="iphonex__volume iphonex__volume--up"></div>
    <div class="iphonex__volume iphonex__volume--down"></div>
    <div class="iphonex__power-2"></div>
  </div>

  <div class="iphonex-preview" *ngIf="getDemoURL(); let details">
    <div>
      <p class="mt-2 mb-4">Scan for demo</p>
      <img loading="lazy" [src]="details.preview_img" alt="QR Code Demo">
      <p class="mt-4 mb-1">or <a [href]="details.url" target="_blank">click here</a></p>
    </div>
  </div>
</div>