<div class="preview-wrapper animated fadeIn" *ngIf="data?.event_data">
  <img loading="lazy" [src]="data.event_data.banner_url" width="100%">

  <div class="preview-body">
    <div class="header-caption" [ngStyle]="{'background-color': data.event_data.colors.primary_color}">
      <b [ngStyle]="{'color': data.event_data.colors.text_color}">{{data.event_data.headings.main}}</b>
      <p [ngStyle]="{'color': data.event_data.colors.text_color}">{{data.event_data.headings.meta}}</p>
      <a [href]="functions.forceUrl(data.event_data.cta.url)" target="_blank" *ngIf="data.event_data.cta.show"
        class="btn btn-sm mt-3"
        [ngStyle]="{'background-color': data.event_data.colors.secondary_color, 'color': data.event_data.colors.text_color}">
        {{data.event_data.cta.title == 'Custom' ? data.event_data.cta.custom_title : data.event_data.cta.title}}
      </a>
    </div>

    <div class="preview-contents">

      <div class="media-wrapper" *ngIf="data.event_data.event.duration.from">
        <div class="media-logo">
          <i class="bi bi-calendar2-check bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>When</b>
          <p>{{functions.formatDate(data.event_data.event.duration.from, false, true)}}</p>
          <ng-container
            *ngIf="data.event_data.event.duration.to && data.event_data.event.duration.to != data.event_data.event.duration.from">
            -
            <p>{{functions.formatDate(data.event_data.event.duration.to, false, true)}}</p>
          </ng-container>
          <small *ngIf="data.event_data.event.duration.all_day">All day</small>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.location.address || data.event_data.event.venue">
        <div class="media-logo">
          <i class="bi bi-geo bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>{{data.event_data.event.venue || 'Where'}}</b>
          <p>
            <a *ngIf="data.event_data.location.location_url; else noLinkToLocation"
              [href]="functions.forceUrl(data.event_data.location.location_url)"
              target="_blank">{{data.event_data.location.address}}</a>
            <ng-template #noLinkToLocation>
              {{data.event_data.location.address}}
            </ng-template>
          </p>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.event.about">
        <div class="media-logo">
          <i class="bi bi-info-circle bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>About</b>
          <p>{{data.event_data.event.about}}</p>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.event.organizer || data.event_data.event.contact.name">
        <div class="media-logo">
          <i class="bi bi-person bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>Organizer</b>
          <p>{{data.event_data.event.organizer}}</p>
          <small *ngIf="data.event_data.event.contact.name">{{data.event_data.event.contact.name}}</small>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.event.contact.mobile">
        <div class="media-logo">
          <i class="bi bi-phone bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>Phone</b>
          <a href="tel:{{data.event_data.event.contact.mobile}}">{{data.event_data.event.contact.mobile}}</a>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.event.contact.email">
        <div class="media-logo">
          <i class="bi bi-envelope bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>Email</b>
          <a href="mailto:{{data.event_data.event.contact.email}}">{{data.event_data.event.contact.email}}</a>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.event.contact.website">
        <div class="media-logo">
          <i class="bi bi-globe2 bi-lg"></i>
        </div>
        <div class="media-contents">
          <b>Website</b>
          <a [href]="functions.forceUrl(data.event_data.event.contact.website)"
            target="_blank">{{data.event_data.event.contact.website}}</a>
        </div>
      </div>

      <div class="media-wrapper" *ngIf="data.event_data.facilities.length">
        <div class="media-contents">
          <b>Facility Features</b>
          <div class="pt-2">
            <app-shared-qr-customize-amenities-icon [data_preview]="data.event_data.facilities">
            </app-shared-qr-customize-amenities-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- Social Media Section -->
    <ng-container *ngIf="data.event_data.socials.length">
      <app-shared-qr-customize-social-media-links [socials]="data.event_data.socials"
        [event_socials]="event_socials_obs.asObservable()">
      </app-shared-qr-customize-social-media-links>
    </ng-container>
  </div>

  <div class="d-flex flex-column bd-highlight sticky-footer-container">
    <div class="mb-2" *ngIf="data.event_data.show_qr_code">
      <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
        [ngStyle]="{'background-color': data.event_data.colors.secondary_color}" data-bs-toggle="tooltip"
        data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
        title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
        <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.event_data.colors.text_color}"></i>
      </div>
    </div>
    <div class="mb-2" *ngIf="data.event_data.share_on">
      <div class="share-button" [ngStyle]="{'background-color': data.event_data.colors.secondary_color}">
        <app-share-to-social [data]="social_data" [text_color]="data.event_data.colors.text_color"
          [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
      </div>
    </div>
  </div>
</div>