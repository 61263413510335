<app-loading class="full-screen" *ngIf="!session_info?.user_details || session_info?.app_busy; else dataIsSet">
</app-loading>
<ng-template #dataIsSet>
  <ng-container *ngIf="appState$ | async; let app_state">
    <div class="wrapper">
      <div class="sidebar" [ngClass]="{'active': toggle, 'sidebar-sm': toggleSidebar}">
        <div class="header">
          <i class="bi bi-list menu-icon-top"
            (click)="toggleSidebar = !toggleSidebar; functionService.initializeTooltip()"></i>
          <i class="bi bi-chevron-left sidebar-toggle"
            (click)="toggle = !toggle; functionService.initializeTooltip()"></i>
          <a routerLink="/user/dashboard">
            <img loading="lazy" src="assets/logos/logo-white.png" height="24" class="logo">
            <img loading="lazy" src="assets/logos/logo-white-sm.png" height="30" class="logo-sm">
          </a>

          <ng-container *ngIf="'user' === session_info.user_details.user_category">
            <div class="visit-wrapper">
              <i class="bi bi-bell mouse-pointer" (click)="openCanvas('alertModal')"></i>
              <div class="icon"
                [class.mouse-pointer]="!user_details.current_organization_details.subscription_details.subscription.unlimited_scans && !!app_state.data.cache.products.scans.length && coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')"
                (click)="user_details.current_organization_details.subscription_details.subscription.unlimited_scans || !app_state.data.cache.products.scans.length || !coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT') || openCanvas('pricingModal')">
                <svg viewBox="0 0 512 512">
                  <g>
                    <g>
                      <path
                        d="M422.762,507.605l-87.518-87.503l-42.422,42.422c-3.662,3.662-8.979,5.171-14.048,3.999    c-5.068-1.201-9.141-4.922-10.781-9.858l-84.858-254.561c-1.802-5.391-0.396-11.338,3.618-15.352    c3.999-3.999,9.961-5.464,15.352-3.618l254.561,84.858c4.937,1.641,8.657,5.713,9.858,10.781    c1.187,5.054-0.322,10.371-3.999,14.048l-42.422,42.422l87.503,87.517c5.859,5.859,5.859,15.352,0,21.211l-63.633,63.633    C438.113,513.465,428.621,513.465,422.762,507.605z" />
                    </g>
                    <g>
                      <path
                        d="M195,120c-8.291,0-15-6.709-15-15V15c0-8.291,6.709-15,15-15s15,6.709,15,15v90C210,113.291,203.291,120,195,120z" />
                    </g>
                    <g>
                      <path
                        d="M120.762,141.973L57.114,78.325c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0l63.647,63.647    c5.859,5.859,5.859,15.352,0,21.211C136.113,147.832,126.621,147.832,120.762,141.973z" />
                    </g>
                    <g>
                      <path
                        d="M57.114,332.886c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C72.466,338.745,62.974,338.745,57.114,332.886z" />
                    </g>
                    <g>
                      <path
                        d="M248.027,141.973c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C263.379,147.832,253.887,147.832,248.027,141.973z" />
                    </g>
                    <g>
                      <path
                        d="M105,210H15c-8.291,0-15-6.709-15-15s6.709-15,15-15h90c8.291,0,15,6.709,15,15S113.291,210,105,210z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="visit-body"
                [class.mouse-pointer]="!user_details.current_organization_details.subscription_details.subscription.unlimited_scans && !!app_state.data.cache.products.scans.length && coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')"
                (click)="user_details.current_organization_details.subscription_details.subscription.unlimited_scans || !app_state.data.cache.products.scans.length || !coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT') || openCanvas('pricingModal')">
                <ng-container
                  *ngIf="user_details.current_organization_details.subscription_details.subscription.unlimited_scans; else limitedScans">
                  <h6 class="mb-n2 text-white">
                    Unlimited
                  </h6>
                  <small class="text-white">scans</small>
                </ng-container>
                <ng-template #limitedScans>
                  <h4 class="m-0 text-white">
                    <ng-container>
                      {{user_details.current_organization_details.visits_left_this_month|
                      shortNumber}}
                    </ng-container>
                  </h4>
                  <small class="text-white">{{functionService.pluralize('visit',
                    user_details.current_organization_details.visits_left_this_month)}}
                    left</small>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <div class="profile-wrapper">
            <div class="avatar-wrapper">
              <div class="avatar" *ngIf="session_info.user_details.profile_img.url; else noProfilePicture"
                [style.background]="'url(' + session_info.user_details.profile_img.url + ')'">
              </div>
              <ng-template #noProfilePicture>
                <div class="user-initials">
                  <b>{{functionService.findInitials(session_info.user_details.name)}}</b>
                </div>
              </ng-template>
            </div>
            <div class="dropdown">
              <button class="btn" type="button" id="userMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <div class="d-flex">
                  <div class="name text-truncate">{{session_info.user_details.name}}</div>
                  <div class="dropdown-icon">
                    <i class="bi bi-chevron-down"></i>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" aria-labelledby="userMenu">
                <ng-container *ngIf="'user' === session_info.user_details.user_category; else notUser">
                  <ng-container *ngFor="let ct of user_tabs">
                    <ng-container *ngIf="ct.include_in_menu">
                      <ng-container *ngIf="'account-settings' === ct.page">
                        <ng-container *ngFor="let ctt of ct.children">
                          <a class="dropdown-item" routerLinkActive="active"
                            (click)="coreService.gotoAccountSettings(ctt.page)"><i class="bi {{ctt.icon}}"></i>
                            {{ctt.description}}</a>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #notUser>
                  <ng-container *ngIf="coreService.hasPermission('admin.ADMINUSERMANAGEMENT.R')">
                    <a class="dropdown-item" [routerLink]="'/admin/admins/' + session_info.user_details.id"><i
                        class="bi bi-gear"></i> Account settings</a>
                  </ng-container>
                </ng-template>

                <hr class="dropdown-divider">
                <a class="dropdown-item" (click)="coreService.logMeOut()">
                  <i class="bi bi-box-arrow-right"></i> Logout
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion" id="sideMenuAccordion">
          <ng-container *ngIf="'user' === session_info.user_details.user_category">
            <ng-container>
              <div class="accordion-item">
                <div class="accordion-header">
                  <div *ngFor="let ct of user_tabs">
                    <ng-container *ngIf="ct.include_in_menu">
                      <button class="accordion-button icon-none" *ngIf="'account-settings' !== ct.page" type="button"
                        [routerLink]="['/user/' + ct.page]" routerLinkActive="active">
                        <i [class]="ct.icon" [title]="toggleSidebar ? ct.description : ''" data-bs-toggle="tooltip"
                          data-bs-html="true" data-bs-placement="right"></i>
                        <span>{{ct.description}}</span></button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- Admin menus -->
          <ng-container *ngIf="'admin' == session_info.user_details.user_category">
            <ng-container
              *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.ADMINUSERMANAGEMENT.DASHBOARD')">
              <div class="accordion-item">
                <div class="accordion-header">
                  <button class="accordion-button icon-none" type="button"
                    [routerLink]="['/' + session_info.user_details.user_category + '/dashboard']"
                    routerLinkActive="active">
                    <i class="bi bi-bar-chart" [title]="'Dashboard'" data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right"></i> <span>Dashboard</span>
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT') || coreService.hasPermission(session_info.user_details.user_category + '.QRLISTMANAGEMENT.R')">
              <div class="accordion-item">
                <div class="accordion-header" id="headingUsersOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#userCollapseTwo" aria-expanded="false" aria-controls="userCollapseTwo">
                    <i class="bi bi-person" [title]="'Users'" data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right"></i> <span>Users</span>
                  </button>
                </div>
                <div id="userCollapseTwo" class="accordion-collapse collapse" [class.show]="isActive('users')"
                  aria-labelledby="headingUsersOne" data-bs-parent="#sideMenuAccordion">
                  <div class="accordion-body">
                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT')">
                      <!-- <b>Users</b> -->
                      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        [routerLink]="['/' + session_info.user_details.user_category + '/users']">
                        <i class="bi bi-people" [title]="'Users'" data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right"></i>
                        <span>Users</span></a>
                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.QRLISTMANAGEMENT.R')">
                      <!-- <b>QRs</b> -->
                      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        [routerLink]="['/' + session_info.user_details.user_category + '/users/qrs']">
                        <i class="bi bi-qr-code" [title]="'QR Codes'" data-bs-toggle=" tooltip" data-bs-html="true"
                          data-bs-placement="right"></i>
                        <span>QR Codes</span>
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.ADMINUSERMANAGEMENT') || coreService.hasPermission(session_info.user_details.user_category + '.PRODUCTMANAGEMENT') || coreService.hasPermission(session_info.user_details.user_category + '.COUPONMANAGEMENT')
                                ">
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <i class="bi bi-person" [title]="'Admin'" data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right"></i> <span>Admin</span>
                  </button>
                </div>
                <div id="collapseTwo" class="accordion-collapse collapse" [class.show]="isActive('admin') ||
                                                  isActive('coupons') ||
                                                  isActive('support') ||
                                                  isActive('checkout') ||
                                                  isActive('products') ||
                                                  isActive('invoices') ||
                                                  isActive('referrals') ||
                                                  isActive('marketing')" aria-labelledby="headingTwo"
                  data-bs-parent="#sideMenuAccordion">
                  <div class="accordion-body">
                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.ADMINUSERMANAGEMENT')">
                      <b>ADMINS</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.ADMINUSERMANAGEMENT.R')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/admins"><i class="bi bi-list-ul" [title]="'List'" data-bs-toggle="tooltip"
                            data-bs-html="true" data-bs-placement="right"></i> <span>List</span></a>
                      </ng-container>
                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.PRODUCTMANAGEMENT')">
                      <b class="mt-2">PRODUCTS</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.PRODUCTMANAGEMENT.R')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/products/more_scans"><i class="bi bi-upc-scan" [title]="'QR Scans'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>QR Scans</span></a>
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/products/plan_subscription"><i class="bi bi-recycle"
                            [title]="'Subscriptions'" data-bs-toggle="tooltip" data-bs-html="true"
                            data-bs-placement="right"></i>
                          <span>Subscriptions</span></a>
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/products/ala_carte"><i class="bi bi-qr-code" [title]="'Ala Carte'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Ala Carte</span></a>
                      </ng-container>
                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.INVOICELISTMANAGEMENT') || coreService.hasPermission(session_info.user_details.user_category + 'USERMANAGEMENT.MANAGEREFERRALS')">
                      <b class="mt-2">FINANCE</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.INVOICELISTMANAGEMENT.R')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/invoices/list"><i class="bi bi-receipt" [title]="'Invoices'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Invoices</span></a>
                      </ng-container>

                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.MANAGEREFERRALS')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/referrals"><i class="bi bi-cash-coin" [title]="'Referrals'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Referrals</span></a>
                      </ng-container>

                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.INVOICELISTMANAGEMENT.CHECKOUTSESSIONS')">
                        <!-- <b>QRs</b> -->
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLink]="['/admin/checkout/sessions']">
                          <i class="bi bi-cart-check" [title]="'Checkout Sessions'" data-bs-toggle=" tooltip"
                            data-bs-html="true" data-bs-placement="right"></i>
                          <span>Checkout Sessions</span>
                        </a>
                      </ng-container>

                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.MARKETINGMANAGEMENT')">
                      <b class="mt-2">MARKETING</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.MARKETINGMANAGEMENT.MANAGECAMPAIGNS')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/marketing"><i class="bi bi-shop" [title]="'Campaigns'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Campaigns</span></a>
                      </ng-container>
                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.COUPONMANAGEMENT')">
                      <b class="mt-2">COUPONS</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.COUPONMANAGEMENT.R')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/coupons/promo"><i class="bi bi-hourglass-split" [title]="'Promo codes'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Promo codes</span></a>
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/coupons/discount"><i class="bi bi-percent" [title]="'Discount codes'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Discount codes</span></a>
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/coupons/voucher"><i class="bi bi-gift" [title]="'Voucher codes'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Voucher codes</span></a>
                      </ng-container>
                    </ng-container>

                    <ng-container
                      *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.SUPPORTMANAGEMENT')">
                      <b class="mt-2">SUPPORT</b>
                      <ng-container
                        *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.SUPPORTMANAGEMENT.R')">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="/admin/support/tickets"><i class="bi bi-ticket" [title]="'Support Tickets'"
                            data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"></i>
                          <span>Tickets</span></a>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>

          </ng-container>
        </div>

        <ng-container *ngIf="session_info.user_details.user_category !== 'admin'">
          <ng-container
            *ngIf="coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
            <ng-container *ngIf="findNextPlan(app_state); let plan">
              <div class="upgrade-wrapper">
                <div class="upgrade-contents">
                  <img loading="lazy" src="assets/upgrade-icon.png" width="60" class="mb-3">
                  <h6>Need more?</h6>
                  <p>From only <b>{{plan.unit_price | currency: plan.currency}}</b>{{
                    plan.plan.unlimited_scans ? ' for unlimited dynamic QR Code scans.' : ''}}</p>
                  <button class="btn btn-light btn-block text-secondary" (click)="startPayment(plan)">Try
                    {{plan.title}}</button>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="workspace-wrapper" *ngIf="session_info.user_details.workspaces.length > 1">
            <div class="custom-swiper-wrapper" appSwiper>
              <button class="btn btn-outline-primary custom-swiper-button-prev"
                *ngIf="session_info.user_details.workspaces.length >= 5"><i class="bi bi-chevron-left"></i></button>
              <swiper-container class="swiper-container">
                <ng-container *ngFor="let workspace of session_info.user_details.workspaces">
                  <swiper-slide class="swiper-slide">
                    <div class="carousel-cell"
                      [class.active]="workspace.id === session_info.user_details.current_organization_details.current_team_details.id">
                      <div class="avatar" (click)="coreService.switchWorkspace(workspace.id)"
                        [class.active]="workspace.id === session_info.user_details.current_organization_details.current_team_details.id"
                        *ngIf="workspace.profile_url; else noProfilePictureWorkspace"
                        [style.background]="'url(' + workspace.profile_url + ')'">
                      </div>
                      <ng-template #noProfilePictureWorkspace>
                        <div class="user-initials" (click)="coreService.switchWorkspace(workspace.id)"
                          [class.active]="workspace.id === session_info.user_details.current_organization_details.current_team_details.id">
                          <b>{{functionService.findInitials(workspace.name)}}</b>
                        </div>
                      </ng-template>
                    </div>
                  </swiper-slide>
                </ng-container>
              </swiper-container>
              <button class="btn btn-outline-primary custom-swiper-button-next"
                *ngIf="session_info.user_details.workspaces.length >= 5"><i class="bi bi-chevron-right"></i></button>
            </div>
          </div>

        </ng-container>
      </div>
      <div class="sidebar-contents">
        <i class="bi bi-list menu-icon" [ngClass]="{'active': toggle}" (click)="toggle = !toggle"></i>

        <ng-container *ngIf="coreService.findRequiredAction(); else noPaymentRequired">
          <div class="notification-bar mb-4">
            <div class="row">
              <div class="col-sm-10 d-flex align-items-center">
                <div class="icon me-3">
                  <i class="bi bi-exclamation-circle-fill"></i>
                </div>
                <div class="notification-body">
                  <b>Payment Failed</b>
                  <p class="mt-1 mb-0">If you received a payment failure alert, it means the renewal
                    payment
                    for
                    your issue account may have failed</p>
                </div>
              </div>
              <div class="col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-end">
                <ng-container
                  *ngIf="session_info.user_details.user_category !== 'admin' && coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                  <button class="btn btn-secondary mt-3 mt-sm-0"
                    (click)="coreService.gotoAccountSettings(user_tab_child_pages.billing)">Update</button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noPaymentRequired>
          <ng-container *ngIf="dynamicQRLeftLoc() < 1">
            <div class="notification-bar mb-4">
              <div class="row">
                <div class="col-sm-10 d-flex align-items-center">
                  <div class="icon me-3">
                    <i class="bi bi-exclamation-octagon-fill"></i>
                  </div>
                  <div class="notification-body">
                    <b>Dynamic QR Code limit reached!</b>
                    <p class="mt-1 mb-0">You have reached dynamic QR limit in your subscription.</p>
                  </div>
                </div>
                <div class="col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <ng-container
                    *ngIf="session_info.user_details.user_category !== 'admin' && coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                    <button class="btn btn-secondary mt-3 mt-sm-0" (click)="startPayment()">Upgrade</button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="user_details.current_organization_details.visits_left_this_month <= 0">
            <div class="notification-bar mb-4">
              <div class="row">
                <div class="col-sm-10 d-flex align-items-center">
                  <div class="icon me-3">
                    <i class="bi bi-exclamation-octagon-fill"></i>
                  </div>
                  <div class="notification-body">
                    <b>Monthly visit limit reached!</b>
                    <p class="mt-1 mb-0">Apologies, but you've reached the maximum QR page visits
                      for this month.</p>
                  </div>
                </div>
                <div class="col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <ng-container
                    *ngIf="app_state.data.cache.products.scans.length &&
                                    session_info.user_details.user_category !== 'admin' &&
                                    coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.PAYMENT')">
                    <button class="btn btn-secondary mt-3 mt-sm-0" (click)="openCanvas('pricingModal')">
                      Top up visits</button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!session_info.miscellaneous.account_confirmed">
            <div class="notification-bar mb-4">
              <div class="row">
                <div class="col-sm-10 d-flex align-items-center">
                  <div class="icon me-3">
                    <i class="bi bi-exclamation-octagon-fill"></i>
                  </div>
                  <div class="notification-body">
                    <b>Account pending activation!</b>
                    <p class="mt-1 mb-0">Your account is pending activation. Please check your email
                      for further instructions to complete the activation process.</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-template>
        <ng-content></ng-content>
      </div>
      <!-- Dark Overlay element -->
      <div class="overlay" id="overLay" (click)="toggle = !toggle" [ngClass]="{'active': toggle}"></div>
    </div>

    <!-- PRICING MODAL -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="pricingModal">
      <div class="offcanvas-header">
        <h5>{{app_state.data.cache.products.scans.length ? 'Topup Visits' : 'Need More?'}}</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ng-container *ngFor="let price of app_state.data.cache.products.scans">
          <div class="plan" (click)="selected_more_scan_price = price"
            [class.active]="selected_more_scan_price?.id === price.id">
            <div class="visits">
              <h3 class="m-0">{{price.scans | shortNumber}} <sub>visits</sub></h3>
            </div>
            <div class="price">
              <h6 class="mb-0">{{price.price | currency: price.currency}}</h6>
              <small>
                <ng-container *ngIf="!price.is_recurring">
                  One time fee
                </ng-container>
              </small>
            </div>
            <i class="bi bi-check-circle-fill"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="user_details.current_organization_details.subscription_details.requires_upgrade">
          <div class="line" *ngIf="app_state.data.cache.products.scans.length">
            <span>OR</span>
            <hr />
          </div>
          <app-upgrade-required inline_class="my-2"
            message="Subscribe now for <b>unlimited</b> monthly visits and unlock the full potential of our services."
            [view_type]="upgrade_ui_view_type"></app-upgrade-required>
        </ng-container>
      </div>
      <div class="offcanvas-footer text-end p-3">
        <button class="btn btn-outline-secondary me-2" data-bs-dismiss="offcanvas">Cancel</button>
        <button class="btn btn-secondary" data-bs-dismiss="offcanvas"
          (click)="paymentService.startPayment(selected_more_scan_price)"
          [disabled]="!selected_more_scan_price">Proceed</button>
      </div>
    </div>

    <!-- ALERT CANVAS -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="alertModal">
      <div class="offcanvas-header">
        <h5>Email alerts</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body" *ngIf="user_details_dup">
        <ng-container
          *ngIf="!user_details_dup.current_organization_details.subscription_details.subscription.unlimited_scans">
          <p>Email me when the number of monthly visits <b class="text-secondary">used up</b> hits:</p>
          <div class="row g-3">
            <div class="col-sm-4 col-4"
              *ngFor="let percentage of user_details_dup.current_organization_details.current_team_details.report.notification_thresholds">
              <div class="percentage-box"
                (click)="percentage.subscribers.includes(user_details_dup.id) ? percentage.subscribers.splice(percentage.subscribers.indexOf(user_details_dup.id), 1) : percentage.subscribers.push(user_details_dup.id)"
                [class.active]="percentage.subscribers.includes(user_details_dup.id)">
                <h5>{{percentage.percentage}}%</h5>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="toggle-box mt-5">
            <p class="mb-0">Email me <b>weekly</b> visit report.</p>
            <div class="toggleWrapper">
              <input type="checkbox"
                [checked]="user_details_dup.current_organization_details.current_team_details.report.weekly_subscribers.includes(user_details_dup.id)"
                (change)="user_details_dup.current_organization_details.current_team_details.report.weekly_subscribers.includes(user_details_dup.id) ? user_details_dup.current_organization_details.current_team_details.report.weekly_subscribers.splice(user_details_dup.current_organization_details.current_team_details.report.weekly_subscribers.indexOf(user_details_dup.id), 1) : user_details_dup.current_organization_details.current_team_details.report.weekly_subscribers.push(user_details_dup.id)"
                id="send_me">
              <label for="send_me" class="toggle m-0"><span class="toggle_handler"></span></label>
            </div>
          </div>
          <div class="toggle-box">
            <p class="mb-0">Email me <b>monthly</b> visit report.</p>
            <div class="toggleWrapper">
              <input type="checkbox"
                [checked]="user_details_dup.current_organization_details.current_team_details.report.monthly_subscribers.includes(user_details_dup.id)"
                (change)="user_details_dup.current_organization_details.current_team_details.report.monthly_subscribers.includes(user_details_dup.id) ? user_details_dup.current_organization_details.current_team_details.report.monthly_subscribers.splice(user_details_dup.current_organization_details.current_team_details.report.monthly_subscribers.indexOf(user_details_dup.id), 1) : user_details_dup.current_organization_details.current_team_details.report.monthly_subscribers.push(user_details_dup.id)"
                id="send_me_monthly">
              <label for="send_me_monthly" class="toggle m-0"><span class="toggle_handler"></span></label>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="offcanvas-footer text-end p-3">
        <button class="btn btn-outline-secondary me-2" data-bs-dismiss="offcanvas">Cancel</button>
        <button class="btn btn-secondary" (click)="subscribeToAlert()" [disabled]="processing">
          <app-inline-loader [hidden]="!processing"></app-inline-loader>
          <span [hidden]="processing">Update</span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>