import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { FunctionsService } from 'src/app/modules/core/services/functions.service';
import { UserQrGeneratorService } from 'src/app/modules/user/services/user-qr-generator.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { PopupGeneratorNavigator } from '@app/store/models/popup-generator-navigator.interface';
import { PopupCategories, PopupGeneratorNavigatorInputTypes } from 'shared-library';

@Component({
  selector: 'app-shared-popup-preview-bigsidebar',
  templateUrl: './shared-popup-preview-bigsidebar.component.html',
  styleUrls: ['./shared-popup-preview-bigsidebar.component.scss']
})
export class SharedPopupPreviewBigsidebarComponent implements OnInit {

  create_mode = !window.location.href.includes("edit")

  session_info: SessionInfo
  data: PopupGeneratorNavigator
  private subscriptions: Subscription[] = []

  constructor(
    private coreService: CoreService,
    public functions: FunctionsService,
    private qrGeneratorService: UserQrGeneratorService
  ) {
    this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
      this.session_info = si
      if (this.session_info) {
        if (this.create_mode) {
          this.data = this.session_info.popup_generating.drafts.find(d => d.selected_category === PopupCategories.BIG_SIDE_BAR)
        } else {
          this.data = this.session_info.popup_generating.updating
        }

        if (this.data?.active_step === QrGeneratorNavigatorSteps.START) {
          // show demo data instead
          this.data.bigsidebar_data = JSON.parse(JSON.stringify(this.qrGeneratorService.initBigSideBarCategoryData()))
          this.data.bigsidebar_data.headings.main = "Increase your revenue!"
          this.data.bigsidebar_data.headings.meta = "21 proven ways to get more leads and close more deals."
          this.data.bigsidebar_data.cta.show = false
          this.data.bigsidebar_data.cta.title = "Read more"
          this.data.bigsidebar_data.form.enable = true
          this.data.bigsidebar_data.form.inputs = [
            {
              id: 1,
              value: "",
              required: true,
              max_length: 120,
              label: "Full Name",
              icon: "bi bi-paragraph",
              placeholder: "Your Full Name",
              type: PopupGeneratorNavigatorInputTypes.TEXT
            },
            {
              id: 2,
              value: "",
              label: "Email",
              required: true,
              max_length: 120,
              icon: "bi bi-at",
              placeholder: "Enter your email",
              type: PopupGeneratorNavigatorInputTypes.EMAIL
            }]
          this.data.bigsidebar_data.colors.text_color = "#606060"
          this.data.bigsidebar_data.colors.primary_color = "#FFFFFF"
          this.data.bigsidebar_data.colors.secondary_color = "#97BC62FF"
        }
      }
    }))
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe())
  }

}
