<div class="preview-wrapper animated fadeIn" *ngIf="data?.lightbox_data">
    <div class="preview-body">
        <div class="lightbox" [ngStyle]="{'background-color': data.lightbox_data.colors.primary_color}">
            <div class="lightbox-header">
                <i class="bi bi-x-circle closer" [ngStyle]="{'color': data.lightbox_data.colors.secondary_color}"></i>
                <img loading="lazy" *ngIf="data.lightbox_data.banner.show" [src]="data.lightbox_data.banner.url"
                    width="100%">
            </div>
            <div class="lightbox-body">
                <h5 [ngStyle]="{'color': data.lightbox_data.colors.secondary_color}">
                    {{data.lightbox_data.headings.main}}
                </h5>
                <small
                    [ngStyle]="{'color': data.lightbox_data.colors.text_color}">{{data.lightbox_data.headings.meta}}</small>

                <ng-container *ngIf="data.lightbox_data.form.enable && data.lightbox_data.form.inputs.length">
                    <div class="mt-2" *ngFor="let input of data.lightbox_data.form.inputs">
                        <input [type]="input.type" [required]="input.required" [maxLength]="input.max_length"
                            class="form-control form-control-sm" [placeholder]="input.placeholder">
                    </div>
                    <button class="btn btn-block btn-sm mt-3"
                        [ngStyle]="{'color': '#FFFFFF', 'background-color': data.lightbox_data.colors.secondary_color}">{{
                        data.lightbox_data.form.cta_text}}</button>
                </ng-container>

                <button class="btn btn-block btn-sm mt-2" *ngIf="data.lightbox_data.cta.show"
                    [ngStyle]="{'color': '#FFFFFF', 'background-color': data.lightbox_data.colors.secondary_color}">{{data.lightbox_data.cta.title
                    === 'Custom' ? data.lightbox_data.cta.custom_title :
                    data.lightbox_data.cta.title}}</button>

                <div class="text-center mt-2" *ngIf="data.lightbox_data.no_thanks.show">
                    <small class="mouse-pointer"
                        [ngStyle]="{'color': data.lightbox_data.colors.text_color}">{{data.lightbox_data.no_thanks.title}}</small>
                </div>

                <div class="text-center website mt-3" *ngIf="functions.is_valid_url(data.lightbox_data.website)">
                    <a class="mouse-pointer" [href]="functions.forceUrl(data.lightbox_data.website)" target="_blank"
                        [ngStyle]="{'color': data.lightbox_data.colors.secondary_color}">{{data.lightbox_data.website}}</a>
                </div>
            </div>
        </div>
    </div>
</div>