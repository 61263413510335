<div class="preview-wrapper animated fadeIn" *ngIf="data?.images_data">
    <div class="preview-contents">
        <div class="preview-header" [ngStyle]="{'background-color': data.images_data.colors.primary_color}">
            <b [ngStyle]="{'color': data.images_data.colors.text_color}">{{data.images_data.headings.main}}</b>
            <p [ngStyle]="{'color': data.images_data.colors.text_color}">{{data.images_data.headings.meta}}</p>
            <a *ngIf="data.images_data.cta.show" [href]="functions.forceUrl(data.images_data.cta.url)" target="_blank"
                class="btn mt-4"
                [ngStyle]="{'background-color': data.images_data.colors.secondary_color, 'color': data.images_data.colors.text_color}">
                {{data.images_data.cta.title == 'Custom' ? data.images_data.cta.custom_title :
                data.images_data.cta.title}}
            </a>
        </div>
        <div class="preview-body"
            [class.grid]="data.images_data.images.grid && data.images_data.images.list.length > 1">
            <ng-container *ngFor="let image of data.images_data.images.list; let i = index">
                <ng-container *ngIf="i%2===0">
                    <img loading="lazy" [src]="image.url">
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let image of data.images_data.images.list; let i = index">
                <ng-container *ngIf="i%2===1">
                    <img loading="lazy" [src]="image.url">
                </ng-container>
            </ng-container>
        </div>
        <div class="text-center mt-3">
            <a [href]="functions.forceUrl(data.images_data.website)" target="_blank"
                [ngStyle]="{'color': data.images_data.colors.secondary_color}">{{data.images_data.website}}</a>
        </div>
    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.images_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.images_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.images_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.images_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.images_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.images_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>