<div class="preview-wrapper animated fadeIn" *ngIf="data?.rating_data">
    <div class="preview-header" [ngStyle]="{'background-color': data.rating_data.colors.primary_color}">
        <h6 class="mb-0" [ngStyle]="{'color': data.rating_data.colors.text_color}">{{data.rating_data.company_name}}
        </h6>
    </div>
    <div class="preview-body">
        <div class="feedback-wrapper text-center">
            <img loading="lazy" [src]="data.rating_data.banner_url" width="100%">
            <div class="contents">
                <ng-container *ngIf="rating_sent; else ratingNotSent">
                    <div class="text-center pb-3">
                        <svg viewBox="0 0 478 478" width="50">
                            <rect id="Rectangle-Copy-360" fill="#FFFFFF" x="71" y="60" width="341" height="358"
                                rx="170.5"></rect>
                            <g id="check" fill="#23B559" fill-rule="nonzero">
                                <path
                                    d="M238.933,0 C106.974,0 0,106.974 0,238.933 C0,370.892 106.974,477.866 238.933,477.866 C370.892,477.866 477.866,370.892 477.866,238.933 C477.726,107.033 370.834,0.141 238.933,0 Z M370.466,165.666 L199.799,336.333 C193.134,342.996 182.331,342.996 175.667,336.333 L107.4,268.066 C100.62,261.518 100.432,250.714 106.98,243.934 C113.528,237.154 124.332,236.966 131.112,243.514 C131.254,243.652 131.394,243.791 131.532,243.934 L187.733,300.135 L346.334,141.534 C353.114,134.986 363.918,135.174 370.466,141.953 C376.854,148.567 376.854,159.052 370.466,165.666 Z"
                                    id="Shape"></path>
                            </g>
                        </svg>
                    </div>
                    <p>Thanks for your response! Your rating has been received successfully.</p>
                    <button class="btn btn-block mt-4 mb-2"
                        (click)="data.rating_data.rating.rating = 0; rating_sent = !rating_sent"
                        [ngStyle]="{'background-color': data.rating_data.colors.secondary_color, 'color': data.rating_data.colors.text_color}">Rate
                        again</button>
                </ng-container>
                <ng-template #ratingNotSent>
                    <p *ngIf="data.rating_data.headings.main">{{data.rating_data.headings.main}}</p>
                    <div class="mb-3">
                        <star-rating class="rating" [value]="data.rating_data.rating.rating" [totalstars]="5"
                            checkedcolor="#F6A623" uncheckedcolor="#D0D1D3"
                            (rate)="data.rating_data.rating.rating = coreService.onRate($event)" size="24px"
                            [readonly]="false">
                        </star-rating>

                    </div>
                    <textarea [(ngModel)]="data.rating_data.rating.description"
                        *ngIf="data.rating_data.rating.rating > 0" class="form-control" rows="4"
                        placeholder="Add a comment (optional)"></textarea>
                    <button (click)="rating_sent = !rating_sent" [disabled]="!data.rating_data.rating.rating"
                        class="btn btn-block mt-3 mb-2"
                        [ngStyle]="{'background-color': data.rating_data.colors.secondary_color, 'color': data.rating_data.colors.text_color}">SEND
                        RATING</button>
                </ng-template>
                <div class="text-center mt-2" *ngIf="data.rating_data.contact.website">
                    <small><a [ngStyle]="{'color': data.rating_data.colors.secondary_color}"
                            [href]="functions.forceUrl(data.rating_data.contact.website)"
                            target="_blank">{{data.rating_data.contact.website}}</a></small>
                </div>
            </div>
        </div>

        <!-- Social Media Section -->
        <ng-container *ngIf="data.rating_data.socials.length">
            <app-shared-qr-customize-social-media-links [event_socials]="event_socials_obs.asObservable()">
            </app-shared-qr-customize-social-media-links>
        </ng-container>
    </div>
    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.rating_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.rating_data.colors.secondary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.rating_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.rating_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.rating_data.colors.secondary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.rating_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>