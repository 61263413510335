<div class="row m-0 link-analytics">
    <div class="col-md-12 p-0">
        <h6 class="mb-0">
            <span [ngSwitch]="pie_data_type">
                <span *ngSwitchCase="'countries'">
                    <i class="bi bi-geo-alt me-1"></i>
                </span>
                <span *ngSwitchCase="'os'">
                    <i class="bi bi-display me-1"></i>
                </span>
                <span *ngSwitchCase="'days'">
                    <i class="bi bi-calendar2 me-1"></i>
                </span>
                <span *ngSwitchCase="'devices'">
                    <i class="bi bi-phone me-1"></i>
                </span>
                <span *ngSwitchDefault>
                    <i class="bi bi-bar-chart me-1"></i>
                </span>
            </span>
            Top {{functions.ucfirst(pie_data_type)}}
        </h6>
    </div>
    <div class="col-md-9 text-center" *ngIf="'v2' == version">
        <div [id]="console_id" class="pt-5" style="height: 320px;"></div>
    </div>
    <div class="col-md-3 pt-4 p-0" *ngIf="percentages.length">
        <div class="top">
            <div class="p-bar" *ngFor="let item of percentages">
                <span>{{ item.name }}</span>
                <span class="percentage">{{ item.percentage }}%</span>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ width: item.percentage + '%' }"
                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-9 text-center" *ngIf="'v1' == version">
        <div [id]="console_id" class="pt-5" style="height: 320px;"></div>
    </div>
</div>