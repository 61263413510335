<div class="preview-wrapper animated fadeIn" *ngIf="data?.email_data">
    <div class="mb-3">
        <small class="mb-1">Email</small>
        <input type="text" readonly [(ngModel)]="data.email_data.email"
            class="form-control form-control-sm text-primary">
    </div>
    <div class="mb-3">
        <small class="mb-1">Subject</small>
        <input type="text" readonly [(ngModel)]="data.email_data.subject" class="form-control form-control-sm">
    </div>
    <div class="mb-3" *ngIf="data.email_data.body">
        <small class="mb-1">Message</small>
        <div [innerHtml]="data.email_data.body"></div>
    </div>

    <button class="btn btn-primary btn-block mt-4">Send Email</button>
</div>