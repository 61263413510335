<ng-container *ngIf="data?.vcardpro_data">

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType1">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header" [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26" class="mb-5">
                </div>
                <div class="profile">
                    <div class="profile-contents"
                        [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}">
                        <div class="profile-img">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-info pt-3">
                            <b
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                            <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                {{data.vcardpro_data.designation}}</p>
                        </div>
                    </div>
                </div>
                <div class="preview-body">
                    <div class="preview-contents">
                        <div class="media-wrapper pt-2 pb-1" *ngIf="data.vcardpro_data.summary">
                            <p [innerHtml]="data.vcardpro_data.summary"></p>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.website">
                            <div class="media-logo">
                                <i class="bi bi-globe2 bi-lg me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Website</b>
                                <a [href]="functions.forceUrl(data.vcardpro_data.website)"
                                    target="_blank">{{data.vcardpro_data.website}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-logo">
                                <i class="bi bi-phone bi-lg me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Mobile</b>
                                <a href="tel:{{data.vcardpro_data.mobile}}"
                                    target="_blank">{{data.vcardpro_data.mobile}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-logo">
                                <i class="bi bi-send bi-lg me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Email</b>
                                <a [href]="'mailto:' + data.vcardpro_data.email"
                                    target="_blank">{{data.vcardpro_data.email}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-logo">
                                <i class="bi bi-briefcase bi-lg me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>{{data.vcardpro_data.designation}}</b>
                                <p>{{data.vcardpro_data.company}}</p>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-logo">
                                <i class="bi bi-geo bi-lg me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Location</b>
                                <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                    [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                    target="_blank">{{data.vcardpro_data.location.address}}</a>
                                <ng-template #noLinkToLocation>
                                    <p>{{data.vcardpro_data.location.address}}</p>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType2">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26">
                </div>
                <div class="profile">
                    <div class="profile-img text-center">
                        <div class="profile-avatar"
                            [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                    </div>
                    <div class="profile-contents"
                        [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}">
                        <div class="profile-info text-center">
                            <b
                                [ngStyle]="{'color': data.vcardpro_data.colors.primary_color}">{{data.vcardpro_data.full_name}}</b>
                            <p [ngStyle]="{'color': data.vcardpro_data.colors.primary_color}">
                                {{data.vcardpro_data.designation}}</p>
                            <div class="ql-container ql-snow"
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                <div class="ql-editor" id="ql-editor"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    [innerHTML]="data.vcardpro_data.summary">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview-body">
                    <div class="preview-contents">
                        <div class="media-wrapper" *ngIf="data.vcardpro_data.website">
                            <div class="media-logo">
                                <i class=" bi bi-globe2"></i>
                            </div>
                            <div class="media-contents">
                                <b>Website</b>
                                <a [href]="functions.forceUrl(data.vcardpro_data.website)"
                                    target="_blank">{{data.vcardpro_data.website}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-logo">
                                <i class="bi bi-phone me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Mobile</b>
                                <a href="tel:{{data.vcardpro_data.mobile}}"
                                    target="_blank">{{data.vcardpro_data.mobile}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-logo">
                                <i class="bi bi-send me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Email</b>
                                <a [href]="'mailto:' + data.vcardpro_data.email"
                                    target="_blank">{{data.vcardpro_data.email}}</a>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-logo">
                                <i class="bi bi-briefcase me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>{{data.vcardpro_data.designation}}</b>
                                <p>{{data.vcardpro_data.company}}</p>
                            </div>
                        </div>

                        <div class="media-wrapper" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-logo">
                                <i class="bi bi-geo me-3"></i>
                            </div>
                            <div class="media-contents">
                                <b>Location</b>
                                <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                    [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                    target="_blank">{{data.vcardpro_data.location.address}}</a>
                                <ng-template #noLinkToLocation>
                                    <p>{{data.vcardpro_data.location.address}}</p>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container
        *ngIf="[ThemeTypes.ThemeType3, ThemeTypes.ThemeType5, ThemeTypes.ThemeType6].includes(data.vcardpro_data.theme.type)">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26">
                </div>
                <div class="profile">
                    <div class="profile-img text-center">
                        <div class="profile-avatar"
                            [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                    </div>
                    <div class="profile-contents">
                        <div class="profile-info text-center">
                            <b
                                [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.full_name}}</b>
                            <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">
                                {{data.vcardpro_data.designation}}</p>
                            <p [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">
                                <strong>{{data.vcardpro_data.company}}</strong>
                            </p>
                            <div class="ql-container ql-snow"
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                <div class="ql-editor" id="ql-editor"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    [innerHTML]="data.vcardpro_data.summary">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview-body">
                    <div class="preview-contents">

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType4">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header" [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26">
                </div>
                <div class="profile">

                    <div class="profile-img d-flex bd-highlight">
                        <div class="w-100 bd-highlight">
                            <div class="profile-contents">
                                <div class="profile-info">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                                    <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                        {{data.vcardpro_data.designation}}</p>
                                    <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                        <strong>{{data.vcardpro_data.company}}</strong>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-1 bd-highlight">
                            <div class="d-flex flex-row justify-content-end">
                                <div class="profile-avatar"
                                    [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ql-container ql-snow" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                        *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                        <div class="ql-editor" id="ql-editor"
                            [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                            [innerHTML]="data.vcardpro_data.summary">
                        </div>
                    </div>
                </div>
                <div class="preview-body">
                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType7">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents pt-2">
                <div class="preview-header text-center mb-3"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        class="mb-3" height="26">

                    <div class="profile">
                        <div class="profile-img text-center">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-contents">
                            <div class="profile-info text-center">
                                <b
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                                <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    {{data.vcardpro_data.designation}}</p>
                                <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    <strong>{{data.vcardpro_data.company}}</strong>
                                </p>
                                <small [class.disable-div]="!data.vcardpro_data.mobile?.trim()"
                                    *ngIf="data.vcardpro_data.savable" class="mt-2"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">
                                    <i class="bi bi-person-add me-1"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                    Add to contacts
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body">
                    <div class="ql-container ql-snow" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                        *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                        <div class="ql-editor" id="ql-editor"
                            [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                            [innerHTML]="data.vcardpro_data.summary">
                        </div>
                    </div>

                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="[ThemeTypes.ThemeType8, ThemeTypes.ThemeType9].includes(data.vcardpro_data.theme.type)">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26">
                </div>

                <div class="preview-body">
                    <div class="profile">
                        <div class="profile-img text-center">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-contents">
                            <div class="profile-info text-center">
                                <b
                                    [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.full_name}}</b>
                                <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">
                                    {{data.vcardpro_data.designation}}</p>
                                <p [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">
                                    <strong>{{data.vcardpro_data.company}}</strong>
                                </p>
                                <div class="ql-container ql-snow"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                    <div class="ql-editor" id="ql-editor"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                        [innerHTML]="data.vcardpro_data.summary">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preview-contents mt-3">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType10">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        class="mb-3" height="26">

                    <div class="profile">
                        <div class="profile-img text-center">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-contents">
                            <div class="profile-info text-center">
                                <b
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                                <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    {{data.vcardpro_data.designation}}</p>
                                <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    <strong>{{data.vcardpro_data.company}}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body">
                    <div class="ql-container ql-snow" [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"
                        *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                        <div class="ql-editor" id="ql-editor"
                            [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"
                            [innerHTML]="data.vcardpro_data.summary">
                        </div>
                    </div>
                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="[ThemeTypes.ThemeType11, ThemeTypes.ThemeType13].includes(data.vcardpro_data.theme.type)">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header" [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        class="mb-3 ms-2" height="26">
                    <div class="profile">
                        <div class="profile-img">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-contents">
                            <div class="profile-info mt-3">
                                <b
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                                <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    {{data.vcardpro_data.designation}}</p>
                                <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    <strong>{{data.vcardpro_data.company}}</strong>
                                </p>
                                <div class="ql-container ql-snow mt-3"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                    <div class="ql-editor p-0" id="ql-editor"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                        [innerHTML]="data.vcardpro_data.summary">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body" [ngStyle]="{'border-top-color': data.vcardpro_data.colors.text_color}">
                    <div class="preview-contents">
                        <div class="d-flex flex-row bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType12">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        class="mb-3 ms-2" height="26">
                    <div class="profile">
                        <div class="profile-img">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-contents">
                            <div class="profile-info mt-3">
                                <b
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                                <p class="mt-n1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    {{data.vcardpro_data.designation}}</p>
                                <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                    <strong>{{data.vcardpro_data.company}}</strong>
                                </p>
                                <div class="ql-container ql-snow mt-3"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                    <div class="ql-editor" id="ql-editor"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                        [innerHTML]="data.vcardpro_data.summary">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body" [ngStyle]="{'border-top-color': data.vcardpro_data.colors.text_color}">
                    <div class="preview-contents">
                        <div class="d-flex flex-row bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType14">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}">
            <div class="vcard-contents">
                <div class="preview-header text-center">
                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                        height="26" class="mb-5 mt-1">
                </div>
                <div class="profile">
                    <div class="profile-contents"
                        [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                        <div class="profile-img">
                            <div class="profile-avatar"
                                [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                        </div>
                        <div class="profile-info py-3">
                            <b
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.full_name}}</b>
                            <p [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                {{data.vcardpro_data.designation}}</p>
                            <p class="mt-1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                <strong>{{data.vcardpro_data.company}}</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="ql-container ql-snow mt-3" [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"
                    *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                    <div class="ql-editor" id="ql-editor"
                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"
                        [innerHTML]="data.vcardpro_data.summary">
                    </div>
                </div>

                <div class="preview-body"
                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 10%)'}">
                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.secondary_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.secondary_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>


            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data.vcardpro_data.theme.type === ThemeTypes.ThemeType15">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}"
            [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}">
            <div class="vcard-contents">
                <div class="profile-img text-center">
                    <div class="profile-avatar"
                        [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                </div>

                <div class="profile">

                    <div class="profile-contents"
                        [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                        <div class="preview-header"
                            [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
                            <img loading="lazy" *ngIf="data.vcardpro_data.logo.show" [src]="data.vcardpro_data.logo.url"
                                height="26">
                        </div>
                        <div class="profile-info">
                            <h3 class="fw-light mt-1" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                {{data.vcardpro_data.full_name}}</h3>
                            <p class="fw-lighter" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                {{data.vcardpro_data.designation}}</p>
                            <div class="ql-container mt-2 ql-snow"
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                <div class="ql-editor p-0 fw-lighter" id="ql-editor"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    [innerHTML]="data.vcardpro_data.summary">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body">
                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>


            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-container
        *ngIf="[ThemeTypes.ThemeType16, ThemeTypes.ThemeType17, ThemeTypes.ThemeType18].includes(data.vcardpro_data.theme.type)">
        <div class="preview-wrapper animated fadeIn {{data.vcardpro_data.theme.type}}"
            [ngStyle]="{'background-color': data.vcardpro_data.colors.primary_color}">
            <div class="vcard-contents">
                <div class="profile-img">
                    <div class="profile-avatar"
                        [ngStyle]="{'background-image': 'url(' + data.vcardpro_data.profile_url + ')'}"></div>
                </div>

                <div class="profile">
                    <div class="profile-contents"
                        [ngStyle]="{'background-image': 'linear-gradient(to top, ' + data.vcardpro_data.colors.primary_color + ', ' + data.vcardpro_data.colors.primary_color + ', ' + data.vcardpro_data.colors.primary_color + ', rgba(' + functions.hexToRgb(data.vcardpro_data.colors.primary_color).join(',') + ', 0%))'}">
                        <div class="profile-info">
                            <div class="d-flex flex-column">
                                <div class="profile-name-wrapper">
                                    <h3 class="fw-light mt-1"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                        {{data.vcardpro_data.full_name}}</h3>
                                    <p class="fw-lighter" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">
                                        {{data.vcardpro_data.designation}}</p>
                                </div>
                                <div class="preview-header">
                                    <img loading="lazy" *ngIf="data.vcardpro_data.logo.show"
                                        [src]="data.vcardpro_data.logo.url" height="26">
                                </div>
                            </div>
                            <div class="ql-container ql-snow"
                                [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                *ngIf="data.vcardpro_data.summary" style="border-width: 0;">
                                <div class="ql-editor p-0 fw-lighter" id="ql-editor"
                                    [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"
                                    [innerHTML]="data.vcardpro_data.summary">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="preview-body">
                    <div class="preview-contents">
                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.website">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-globe2"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Website</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" [href]="functions.forceUrl(data.vcardpro_data.website)"
                                            target="_blank">{{data.vcardpro_data.website}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.mobile">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-phone"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Mobile</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" href="tel:{{data.vcardpro_data.mobile}}"
                                            target="_blank">{{data.vcardpro_data.mobile}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="functions.isEmail(data.vcardpro_data.email)">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-send"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Email</b>
                                    <p class="mt-n1">
                                        <a [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                        'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }" [href]="'mailto:' + data.vcardpro_data.email"
                                            target="_blank">{{data.vcardpro_data.email}}</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight"
                            *ngIf="data.vcardpro_data.designation || data.vcardpro_data.company">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-briefcase"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">{{data.vcardpro_data.company}}</b>
                                    <div class="mt-n1">
                                        <p [ngStyle]="{
                                        'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                    }">{{data.vcardpro_data.designation}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex bd-highlight" *ngIf="data.vcardpro_data.location.address">
                            <div class="media-wrapper p-2 flex-shrink-1 bd-highlight">
                                <div class="media-logo d-flex flex-column justify-content-center"
                                    [ngStyle]="{'background-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 20%)'}">
                                    <i class=" bi bi-geo"
                                        [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                                </div>
                            </div>
                            <div class="media-contents-wrapper p-2 w-100 bd-highlight">
                                <div class="media-contents">
                                    <b [ngStyle]="{'color': data.vcardpro_data.colors.text_color}">Location</b>
                                    <p class="mt-n1">
                                        <a *ngIf="data.vcardpro_data.location.location_url; else noLinkToLocation"
                                            [ngStyle]="{
                                            'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                            'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                        }" [href]="functions.forceUrl(data.vcardpro_data.location.location_url)"
                                            target="_blank">{{data.vcardpro_data.location.address}}</a>
                                        <ng-template #noLinkToLocation>
                                            <span [ngStyle]="{
                                                'color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)', 
                                                'border-bottom-color': 'rgba(' + functions.hexToRgb(data.vcardpro_data.colors.text_color).join(',') + ', 50%)'
                                            }" target="_blank">{{data.vcardpro_data.location.address}}</span>
                                        </ng-template>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Social Media Section -->
                    <ng-container *ngIf="data.vcardpro_data.socials.length">
                        <app-shared-qr-customize-social-media-links [socials]="data.vcardpro_data.socials"
                            [event_socials]="event_socials_obs.asObservable()">
                        </app-shared-qr-customize-social-media-links>
                    </ng-container>
                </div>

            </div>
            <ng-container *ngTemplateOutlet="stickyFooterContent"></ng-container>
        </div>
    </ng-container>

    <ng-template #stickyFooterContent>
        <div class="d-flex flex-column bd-highlight sticky-footer-container">
            <ng-container
                *ngIf="![ThemeTypes.ThemeType7].includes(data.vcardpro_data.theme.type) && data.vcardpro_data.savable">
                <div class="mb-2">
                    <div title="Save contact" [class.disable-div]="!data.vcardpro_data.mobile?.trim()"
                        data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="left" class="save-button"
                        [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}">
                        <a (click)="qrGeneratorService.saveVCardProToDevice(data.vcardpro_data)">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14pt" height="14pt"
                                viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    [attr.fill]="data.vcardpro_data.colors.text_color" stroke="none">
                                    <path d="M1043 4955 c-192 -42 -353 -193 -420 -394 -11 -33 -17 -107 -20 -267
                            l-5 -222 -92 -4 c-142 -6 -201 -51 -201 -153 0 -102 57 -145 201 -153 l94 -5
                            0 -298 0 -299 -93 0 c-110 0 -150 -17 -185 -76 -40 -68 -19 -145 52 -198 22
                            -17 45 -22 127 -24 l99 -4 0 -298 0 -298 -99 -4 c-82 -2 -105 -7 -127 -24 -71
                            -53 -92 -130 -52 -198 35 -59 75 -76 185 -76 l93 0 0 -299 0 -298 -94 -5
                            c-144 -8 -201 -51 -201 -153 0 -102 59 -147 201 -153 l92 -4 4 -222 c5 -239 9
                            -260 73 -386 39 -77 153 -187 237 -228 132 -65 44 -62 1798 -62 1754 0 1668
                            -3 1800 62 89 43 204 159 248 250 65 134 62 15 62 2098 0 2080 3 1963 -61
                            2097 -43 91 -158 207 -249 251 -132 65 -45 62 -1805 61 -1308 0 -1611 -3
                            -1662 -14z m3279 -295 c63 -18 129 -70 162 -130 l31 -55 0 -1915 0 -1915 -31
                            -55 c-34 -60 -100 -113 -164 -130 -26 -7 -563 -9 -1632 -8 l-1593 3 -55 31
                            c-66 37 -113 101 -129 174 -6 28 -11 127 -11 219 l0 169 98 4 c111 5 149 20
                            181 71 26 43 28 117 4 159 -32 53 -74 71 -184 76 l-99 5 2 296 3 296 101 5
                            c111 6 141 18 174 73 28 45 26 115 -2 157 -38 54 -79 70 -185 70 l-93 0 0 300
                            0 300 93 0 c106 0 147 16 185 70 28 42 30 112 2 157 -33 55 -63 67 -174 73
                            l-101 5 -3 296 -2 296 99 5 c110 5 152 23 184 76 24 42 22 116 -4 159 -32 51
                            -70 66 -182 71 l-99 4 4 197 c3 197 3 198 33 257 32 62 94 114 159 133 45 13
                            3181 14 3228 1z" />
                                    <path d="M2770 4060 c-360 -44 -639 -346 -657 -710 -12 -227 64 -417 230 -577
                            98 -95 207 -156 347 -195 69 -19 282 -16 360 6 272 73 485 295 545 569 27 122
                            18 286 -22 400 -44 124 -85 189 -188 292 -76 77 -104 98 -185 137 -143 70
                            -286 96 -430 78z m234 -320 c226 -70 359 -329 287 -559 -66 -211 -285 -350
                            -496 -314 -363 62 -512 500 -257 758 121 123 298 167 466 115z" />
                                    <path d="M2685 2396 c-324 -54 -597 -217 -789 -473 -144 -193 -236 -456 -236
                                    -679 0 -99 14 -132 70 -167 l35 -22 1073 -3 c757 -2 1086 1 1114 9 51 13 93
                                    54 107 104 14 53 -3 225 -35 345 -114 434 -465 772 -904 871 -112 25 -329 33
                                    -435 15z m400 -315 c222 -57 433 -216 554 -416 37 -61 95 -207 105 -267 l7
                                    -38 -891 0 -890 0 6 28 c82 372 378 653 752 712 87 14 266 4 357 -19z" />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </ng-container>

            <div class="mb-2" *ngIf="data.vcardpro_data.show_qr_code">
                <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                    [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}" data-bs-toggle="tooltip"
                    data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                    title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                    <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.vcardpro_data.colors.text_color}"></i>
                </div>
            </div>

            <div class="mb-2" *ngIf="data.vcardpro_data.share_on">
                <div class="share-button" [ngStyle]="{'background-color': data.vcardpro_data.colors.secondary_color}">
                    <app-share-to-social [data]="social_data" [text_color]="data.vcardpro_data.colors.text_color"
                        [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
                </div>
            </div>

        </div>
    </ng-template>

</ng-container>