<div class="custom-input">
    <small>{{label}}</small>
    <div class="input-wrapper" [ngClass]="{'input-active': edit_mode_on}">
        <input [type]="input_type" [(ngModel)]="value" [placeholder]="placeholder" class="form-control text-truncate"
            [class.is-invalid]="!is_valid" [readonly]="!edit_mode_on">
        <div class="edit-options">
            <button class="btn btn-secondary btn-sm" (click)="acceptEdit()">
                <i class="bi bi-check"></i>
            </button>
            <button class="btn btn-secondary btn-sm" (click)="cancelEdit()">
                <i class="bi bi-x"></i>
            </button>
        </div>
    </div>
    <a *ngIf="!read_only" (click)="attemptChange()" [ngClass]="{'d-none': edit_mode_on}"><i
            class="bi bi-pencil me-1"></i>
        Edit</a>
</div>