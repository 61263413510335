<div class="click-count">
    <b *ngIf="total_count && !init_link_data; else visitsToQrs" [CountTo]="total_count" [from]="1" [duration]="1"></b>
    <ng-template #visitsToQrs>
        {{total_count}}
    </ng-template>
    <i class="bi bi-bar-chart bi-md ms-2"></i>
    <br />
    <small class="text-uppercase">TOTAL {{functionService.pluralize(stats_description.toUpperCase(),
        total_count)}}</small>
</div>

<div class="map">
    <div [id]="console_id"></div>
</div>