import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { APP_CONFIG } from 'src/app/modules/core/config/config';
import { CoreService } from 'src/app/modules/core/services/core.service';
import { BASE_64_ENCODED, FunctionsService } from 'src/app/modules/core/services/functions.service';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { IAppConfig } from '@app/store/models/config.interface';
import { UserLargeFileUploadModes } from 'shared-library';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';

declare const bootstrap: any

@Component({
    selector: 'app-shared-qr-change-banner-image',
    templateUrl: './shared-qr-change-banner-image.component.html',
    styleUrls: ['./shared-qr-change-banner-image.component.scss']
})
export class SharedQrChangeBannerImageComponent implements OnInit {

    @Input() data: string
    @Input() optional = false
    @Input() banner_mode = true
    @Input() maintain_aspect_ratio = true
    @Input() deleted: FormArray<FormControl<string>> = this.formBuilder.array([].map((d: string) => this.formBuilder.control(d)))
    image_destination: UserLargeFileUploadModes = this.coreService.isLoggedIn() ? UserLargeFileUploadModes.MAIN : UserLargeFileUploadModes.PUBLIC

    resolution = "640 x 360 px"
    instance_id = `${this.functions.randomNumber()}_${this.functions.randomString(12)}`

    @Output() _change = new EventEmitter<string>()
    @Output() _canvasStateIsOpen = new EventEmitter<boolean>()

    imageChangedEvent: any;
    @ViewChild('myFileInput') myFileInput: ElementRef;

    file_helper: { uploading: boolean; input_url: string; base64: BASE_64_ENCODED; file: File } = {
        uploading: false,
        input_url: "",
        base64: null,
        file: null
    }

    session_info: SessionInfo
    private subscriptions: Subscription[] = []
    modal_element: any;

    constructor(
        private formBuilder: FormBuilder,
        private coreService: CoreService,
        public readonly functions: FunctionsService,
        @Inject(APP_CONFIG) public readonly config: IAppConfig
    ) {
        this.subscriptions.push(this.coreService.session_dataObservable.subscribe(si => {
            this.session_info = si;
        }))
    }

    ngOnInit(): void {
        if (!this.banner_mode) {
            this.resolution = "200 x 200 px"
        }
        this.functions.initializeTooltip()
    }

    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe())
    }

    openCanvas(id: string) {
        const offcElement = document.getElementById(id)
        if (offcElement) {
            this.modal_element = new bootstrap.Offcanvas(offcElement, {
                keyboard: false,
            })
            offcElement.addEventListener('show.bs.offcanvas', () => this._canvasStateIsOpen.emit(true))
            offcElement.addEventListener('hidden.bs.offcanvas', () => this._canvasStateIsOpen.emit(false))
            this.modal_element.show()
        }

    }

    deleteImage() {
        this.deleted.push(this.formBuilder.control(this.data))
        this.data = ""
        this._change.emit(this.data)
    }

    fileChangeEvent(event: any): void {
        if (!event.target.files.length) {
            return
        }

        const file: File = event.target.files[0]
        let file_type = file.name.split(".").pop()
        if (!this.config.allowed_image_types.includes(file_type.toLowerCase())) {
            this.functions.showErrorMessage(`.${file_type} is not a supported image format, please select a different image`)
            return
        }
        if (file.size / 1024 / 1024 > this.config.max_file_upload_size) {
            this.functions.showErrorMessage(`File too large, file should be less than ${this.config.max_file_upload_size} MB`)
            return
        }
        this.imageChangedEvent = event;
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.file_helper.base64 = this.functions.prepBase64(event.base64);
        this.file_helper.input_url = event.base64;
        this.file_helper.file = this.imageChangedEvent.target.files[0]
    }

    openModal(id: string) {
        this.modal_element = new bootstrap.Modal(document.getElementById(id), {
            keyboard: false
        })
        this.modal_element.show()
    }

    async finishImageUpload(reset = false) {
        this.imageChangedEvent = null;
        this.myFileInput.nativeElement.value = '';
        if (reset) {
            this.file_helper.base64 = null
            this.file_helper.input_url = ""
            this.file_helper.file = null
            return
        }

        this.modal_element?.hide()

        if (this.file_helper.base64) {
            // Send to BE
            this.file_helper.uploading = true
            try {
                let url = await this.functions.uploadFile(this.file_helper.base64, `png`, this.image_destination, "image/png")
                this._change.emit(url)
            } catch (error) {
                this.functions.logToConsole(error)
            }
            this.file_helper.uploading = false
        }
    }

}
