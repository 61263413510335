<div class="preview-wrapper animated fadeIn" *ngIf="data?.successpage_data">
    <div class="preview-body">
        <div class="successpage" [ngStyle]="{'background-color': data.successpage_data.colors.primary_color}">
            <div class="successpage-header">
                <ng-container *ngIf="data.successpage_data.banner.show">
                    <div class="image-wrapper pb-2"
                        *ngIf="qrGeneratorService.initSuccessCategoryData().banner.url === data.successpage_data.banner.url; else isCustom">
                        <svg viewBox="0 0 470 382" class="lg">
                            <g id="Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Desktop-Copy-24" transform="translate(-3353.000000, -2454.000000)">
                                    <g id="Group-25-Copy" transform="translate(3350.801961, 2433.868603)">
                                        <g id="Group-12" [attr.fill]="data.successpage_data.colors.secondary_color"
                                            fill-opacity="0.1">
                                            <rect id="Rectangle"
                                                transform="translate(152.207658, 119.631397) rotate(-30.000000) translate(-152.207658, -119.631397) "
                                                x="8.20765814" y="64.6313972" width="288" height="110" rx="55"></rect>
                                            <rect id="Rectangle-Copy"
                                                transform="translate(152.207658, 251.631397) rotate(-30.000000) translate(-152.207658, -251.631397) "
                                                x="8.20765814" y="196.631397" width="288" height="110" rx="55"></rect>
                                            <rect id="Rectangle-Copy-2"
                                                transform="translate(315.456369, 288.631397) rotate(-30.000000) translate(-315.456369, -288.631397) "
                                                x="143.456369" y="233.631397" width="344" height="110" rx="55"></rect>
                                        </g>
                                        <path
                                            d="M352.661832,49.7076663 C348.208389,47.7677775 345.918827,42.7913643 347.341216,38.1431896 C347.450136,37.7958868 347.517395,37.4368488 347.541574,37.0736426 L347.541574,37.0736426 C347.686506,34.952714 346.417654,32.989869 344.425361,32.2530297 C342.433069,31.5161903 340.193927,32.1816219 338.926183,33.8872839 L338.926183,33.8872839 C338.711985,34.1771504 338.532653,34.4912667 338.391895,34.8231375 C336.453768,39.2806288 331.481874,41.5722719 326.837921,40.1485902 C326.490934,40.0395709 326.132222,39.9722512 325.769345,39.9480501 L325.769345,39.9480501 C323.633221,39.7936747 321.654636,41.0784311 320.924808,43.093758 C320.19498,45.1090848 320.891572,47.3643884 322.630404,48.6158371 L322.630404,48.6158371 C322.920008,48.8302301 323.233839,49.0097251 323.565408,49.1506106 C328.018851,51.0904994 330.308413,56.0669126 328.886024,60.7150873 C328.777104,61.06239 328.709846,61.4214281 328.685666,61.7846343 L328.685666,61.7846343 C328.514629,63.9359592 329.801848,65.9361669 331.829561,66.6699295 C333.857274,67.4036921 336.124837,66.6898429 337.367843,64.9264285 L337.367843,64.9264285 C337.582042,64.636562 337.761374,64.3224457 337.902131,63.9905749 C339.840258,59.5330836 344.812152,57.2414405 349.456105,58.6651222 C349.803093,58.7741416 350.161805,58.8414612 350.524681,58.8656623 L350.524681,58.8656623 C352.643683,59.0107256 354.604746,57.7407206 355.340916,55.7466169 C356.077086,53.7525133 355.412259,51.5113363 353.708146,50.2424398 L353.708146,50.2424398 C353.383724,50.0200195 353.032068,49.8402872 352.661832,49.7076663 L352.661832,49.7076663 Z"
                                            id="Shape" [attr.fill]="data.successpage_data.colors.secondary_color"
                                            fill-rule="nonzero"></path>
                                        <path
                                            d="M384.512153,112.635682 C381.331123,111.250047 379.695721,107.695466 380.711713,104.375341 C380.789513,104.127268 380.837555,103.870812 380.854826,103.611379 L380.854826,103.611379 C380.958349,102.09643 380.052026,100.694398 378.62896,100.168084 C377.205894,99.6417701 375.606507,100.117078 374.700975,101.335408 L374.700975,101.335408 C374.547976,101.542456 374.419882,101.766825 374.319341,102.003875 C372.934965,105.187798 369.383612,106.824686 366.066502,105.80777 C365.818654,105.729899 365.562432,105.681814 365.303234,105.664527 L365.303234,105.664527 C363.777431,105.554259 362.364156,106.471942 361.84285,107.911461 C361.321545,109.350981 361.819111,110.961912 363.061133,111.855804 L363.061133,111.855804 C363.267993,112.008941 363.492158,112.137152 363.728993,112.237785 C366.910024,113.623419 368.545425,117.178 367.529433,120.498125 C367.451633,120.746199 367.403591,121.002654 367.386321,121.262087 L367.386321,121.262087 C367.264151,122.798748 368.183593,124.227468 369.631959,124.751584 C371.080326,125.2757 372.700014,124.765808 373.587876,123.506226 L373.587876,123.506226 C373.740874,123.299178 373.868969,123.07481 373.96951,122.837759 C375.353886,119.653837 378.905239,118.016949 382.222348,119.033864 C382.470197,119.111735 382.726419,119.159821 382.985617,119.177107 L382.985617,119.177107 C384.49919,119.280724 385.899949,118.373577 386.425785,116.949218 C386.95162,115.524858 386.476744,113.924017 385.25952,113.017663 L385.25952,113.017663 C385.02779,112.858791 384.776607,112.730411 384.512153,112.635682 L384.512153,112.635682 Z"
                                            id="Shape-Copy" [attr.fill]="data.successpage_data.colors.secondary_color"
                                            fill-rule="nonzero"></path>
                                        <path
                                            d="M64.5121534,67.6356815 C61.3311226,66.2500467 59.6957213,62.6954658 60.7117132,59.375341 C60.7895135,59.1272676 60.8375552,58.8708119 60.854826,58.6113789 L60.854826,58.6113789 C60.9583486,57.0964299 60.052026,55.6943977 58.6289598,55.1680839 C57.2058937,54.6417701 55.6065068,55.1170784 54.7009753,56.3354084 L54.7009753,56.3354084 C54.5479765,56.5424559 54.4198822,56.7668247 54.3193411,57.0038753 C52.9349646,60.1877976 49.383612,61.8246855 46.0665025,60.80777 C45.8186544,60.7298991 45.5624315,60.6818136 45.3032342,60.6645271 L45.3032342,60.6645271 C43.7774308,60.554259 42.3641558,61.4719421 41.8428502,62.9114613 C41.3215446,64.3509805 41.8191106,65.9619117 43.0611335,66.8558035 L43.0611335,66.8558035 C43.2679929,67.0089414 43.492158,67.1371521 43.7289933,67.2377846 C46.9100241,68.6234195 48.5454254,72.1780003 47.5294334,75.4981251 C47.4516332,75.7461985 47.4035914,76.0026542 47.3863206,76.2620872 L47.3863206,76.2620872 C47.2641514,77.7987479 48.1835931,79.2274677 49.6319595,79.7515838 C51.0803258,80.2757 52.7000138,79.7658077 53.5878756,78.506226 L53.5878756,78.506226 C53.7408744,78.2991785 53.8689687,78.0748097 53.9695098,77.8377591 C55.3538863,74.6538367 58.9052389,73.0169488 62.2223484,74.0338644 C62.4701965,74.1117353 62.7264194,74.1598208 62.9856168,74.1771073 L62.9856168,74.1771073 C64.4991899,74.2807239 65.8999488,73.3735775 66.4257846,71.9492177 C66.9516204,70.524858 66.4767438,68.9240173 65.2595203,68.0176626 L65.2595203,68.0176626 C65.0277902,67.858791 64.7766073,67.7304108 64.5121534,67.6356815 L64.5121534,67.6356815 Z"
                                            id="Shape-Copy-2" [attr.fill]="data.successpage_data.colors.secondary_color"
                                            fill-rule="nonzero"></path>
                                        <path
                                            d="M20.022346,245.592491 C17.6047626,244.539408 16.3618576,241.837927 17.1340115,239.314632 C17.1931397,239.126096 17.2296514,238.93119 17.2427772,238.734021 L17.2427772,238.734021 C17.3214544,237.582659 16.6326492,236.517115 15.5511189,236.117117 C14.4695886,235.717118 13.2540546,236.078352 12.5658506,237.004283 L12.5658506,237.004283 C12.4495716,237.161639 12.3522199,237.33216 12.2758087,237.512318 C11.2236826,239.932099 8.52465455,241.176134 6.00365132,240.403278 C5.81528676,240.344096 5.62055741,240.307551 5.4235674,240.294413 L5.4235674,240.294413 C4.26395685,240.21061 3.18986785,240.908049 2.7936756,242.002083 C2.39748335,243.096118 2.77563349,244.320426 3.71957089,244.999783 L3.71957089,244.999783 C3.87678407,245.116168 4.04714951,245.213608 4.22714432,245.290089 C6.64472774,246.343172 7.88763273,249.044653 7.11547883,251.567948 C7.05635066,251.756484 7.01983891,251.95139 7.0067131,252.148559 L7.0067131,252.148559 C6.91386448,253.316421 7.61264017,254.402248 8.71339863,254.800576 C9.81415709,255.198905 11.04512,254.811387 11.7198949,253.854104 L11.7198949,253.854104 C11.836174,253.696748 11.9335257,253.526228 12.0099369,253.34607 C13.062063,250.926289 15.761091,249.682254 18.2820943,250.45511 C18.4704588,250.514292 18.6651882,250.550836 18.8621782,250.563974 L18.8621782,250.563974 C20.0124938,250.642723 21.0770705,249.953292 21.4767057,248.870778 C21.876341,247.788265 21.5154348,246.571626 20.5903448,245.882796 L20.5903448,245.882796 C20.41423,245.762054 20.223331,245.664485 20.022346,245.592491 L20.022346,245.592491 Z"
                                            id="Shape-Copy-3" [attr.fill]="data.successpage_data.colors.secondary_color"
                                            fill-rule="nonzero"></path>
                                        <g id="tick-copy" transform="translate(99.198039, 80.131397)"
                                            fill-rule="nonzero">
                                            <circle id="Oval" [attr.fill]="data.successpage_data.colors.secondary_color"
                                                cx="131" cy="131" r="131"></circle>
                                            <polygon id="Path" fill="#FFFFFF"
                                                points="200.826745 61 107.813987 153.77297 62.1732554 108.251582 39 131.361734 107.813987 200 224 84.1101519">
                                            </polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <ng-template #isCustom>
                        <img loading="lazy" [src]="data.successpage_data.banner.url" width="100%">
                    </ng-template>
                </ng-container>
            </div>
            <div class="successpage-body">
                <h5 [ngStyle]="{'color': data.successpage_data.colors.secondary_color}">
                    {{data.successpage_data.headings.main}}
                </h5>
                <small
                    [ngStyle]="{'color': data.successpage_data.colors.text_color}">{{data.successpage_data.headings.meta}}</small>

                <button class="btn btn-block btn-sm mt-3" *ngIf="data.successpage_data.cta.show"
                    [ngStyle]="{'color': '#FFFFFF', 'background-color': data.successpage_data.colors.secondary_color}">{{data.successpage_data.cta.title
                    === 'Custom' ? data.successpage_data.cta.custom_title :
                    data.successpage_data.cta.title}}</button>

                <div class="text-center website mt-3" *ngIf="functions.is_valid_url(data.successpage_data.website)">
                    <a class="mouse-pointer" [href]="functions.forceUrl(data.successpage_data.website)" target="_blank"
                        [ngStyle]="{'color': data.successpage_data.colors.secondary_color}">{{data.successpage_data.website}}</a>
                </div>
            </div>
        </div>
    </div>
</div>