<div class="preview-wrapper animated fadeIn" *ngIf="data && data.app_data"
    [ngStyle]="{'background-color': '-webkit-linear-gradient(to top,' + data.app_data.colors.secondary_color + ', ' + data.app_data.colors.primary_color + ')', 'background': 'linear-gradient(to top,' + data.app_data.colors.secondary_color + ', ' + data.app_data.colors.primary_color + ')'}">
    <div class="preview-body">
        <b [ngStyle]="{'color': data.app_data.colors.text_color}">{{data.app_data.app.name}}</b>
        <p [ngStyle]="{'color': data.app_data.colors.text_color}">{{data.app_data.company_name}}</p>
        <div class="my-4">
            <img loading="lazy" [src]="data.app_data.app.logo" width="50%">
        </div>
        <h6 [ngStyle]="{'color': data.app_data.colors.text_color}">{{data.app_data.headings.main}}</h6>
        <div class="pt-2">
            <small [ngStyle]="{'color': data.app_data.colors.text_color}">{{data.app_data.headings.meta}}</small>
        </div>
        <a *ngIf="data.app_data.cta.show" [href]="functions.forceUrl(data.app_data.cta.url)" target="_blank"
            class="btn btn-sm btn-round mt-3"
            [ngStyle]="{'border-color': data.app_data.colors.text_color, 'color': data.app_data.colors.text_color}">
            {{data.app_data.cta.title == 'Custom' ? data.app_data.cta.custom_title : data.app_data.cta.title}}
        </a>
        <div class="mt-4">
            <a [href]="functions.forceUrl(link.url)" *ngFor="let link of data.app_data.app.links" target="_blank">
                <img loading="lazy" [src]="link.logo" class="mb-1" width="150"></a>
        </div>
        <div class="mt-3">
            <a [href]="functions.forceUrl(data.app_data.website)" target="_blank"
                [ngStyle]="{'color': data.app_data.colors.text_color}">{{data.app_data.website}}</a>
        </div>
    </div>

    <div class="d-flex flex-column bd-highlight sticky-footer-container">
        <div class="mb-2" *ngIf="data.app_data.show_qr_code">
            <div class="share-button" data-bs-custom-class="qr-preview-tooltip"
                [ngStyle]="{'background-color': data.app_data.colors.primary_color}" data-bs-toggle="tooltip"
                data-bs-trigger="hover focus" data-bs-placement="left" data-bs-html="true"
                title="<img src='{{data.qr_url || config.dummy_qr_code }}' class='rounded' height='170' width='170'>">
                <i class="bi bi-qr-code-scan" [ngStyle]="{'color': data.app_data.colors.text_color}"></i>
            </div>
        </div>
        <div class="mb-2" *ngIf="data.app_data.share_on">
            <div class="share-button" [ngStyle]="{'background-color': data.app_data.colors.primary_color}">
                <app-share-to-social [data]="social_data" [text_color]="data.app_data.colors.text_color"
                    [event_additional_settings]="event_additional_settings_obs.asObservable()"></app-share-to-social>
            </div>
        </div>
    </div>
</div>