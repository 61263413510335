import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { CreditCardBillingAddressForm } from '@app/store/models/credit-card-billing-address.interface';

@Component({
  selector: 'app-shared-billing-address-form',
  templateUrl: './shared-billing-address-form.component.html',
  styleUrls: ['./shared-billing-address-form.component.scss']
})
export class SharedBillingAddressFormComponent {

  @Input() data: FormGroup<CreditCardBillingAddressForm>

  constructor(
    public functionService: FunctionsService
  ) {
  }

}
