<div class="main-wrapper">
  <div class="layout-sidebar">
    <div class="header">
      <a [routerLink]="session_info?.last_routes.new_home || '/'">
        <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
      </a>
      <h3>QR code-accessible web pages for all use cases</h3>
    </div>
  </div>
  <div class="layout-contents">
    <div class="form-contents">
      <ng-container [ngSwitch]="message_mode">
        <ng-container *ngSwitchCase="_messages.QR_IS_SCANNABLE">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <h2>🥳 Congratulations!</h2>
          </div>
          Dear valued user we would like to inform you that the QR code you scanned is fully functional
          and scannable. You can use any QR code scanner application to scan it and access the information
          that it
          contains. If you have any issues or questions, please feel free to reach out to us <a
            routerLink="/contact-us">here</a>. Thank you for your
          continued support!
          <div class="my-2">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i> Go
              Home</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="_messages.NEWSLETTERS_UNSUBSCRIPTION">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <h2>✅ Unsubscribed!</h2>
          </div>
          We're confirming that you've been successfully <b>unsubscribed</b> from our promotional emails list.
          <br>
          You will no longer receive marketing communications from us. If this was a mistake or you change
          your mind,
          you can resubscribe at any time by contacting our <a routerLink="/contact-us">support team</a>.
          <br>
          Thank you for your understanding, and we appreciate your past interest in our updates and offers.
          <div class="my-2">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i> Go
              Home</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="_messages.QR_IS_DISABLED">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <img loading="lazy" src="assets/qr-is-deactivated.png" height="150" class="my-4 d-block">
          </div>
          <figure>
            <blockquote class="blockquote">
              <h3>Page deactivated!</h3>
            </blockquote>
            <figcaption class="blockquote-footer">
              The QR Code is currently <b class="text-secondary">deactivated</b>. If you are the owner,
              please locate the code in
              the
              <b>Paused</b> tab if you wish to re-activate it.
            </figcaption>
          </figure>
          <div class="my-1">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i> Go
              Home</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="_messages.QR_VISITS_REACHED">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <img loading="lazy" src="assets/not-available.png" height="70" class="my-4 d-block dim-img">
          </div>

          <figure>
            <blockquote class="blockquote">
              <h3>Visit limit reached!</h3>
            </blockquote>
            <figcaption class="blockquote-footer">
              The visit limit to this QR Code has reached. If
              you are the owner,
              please head to your dashboard now to top up visits or consider changing your plan to
              accommodate the growing interest.
            </figcaption>
          </figure>
          <div class="my-1">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i>
              Go Home</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="_messages.QR_NOT_FOUND">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <img loading="lazy" src="assets/qr-is-deactivated.png" height="150" class="my-4 d-block">
          </div>
          <figure>
            <blockquote class="blockquote">
              <h3>Page not found!</h3>
            </blockquote>
            <figcaption class="blockquote-footer">
              It appears that the QR Code page you were looking for has been <b class="text-secondary">deleted</b>.
            </figcaption>
          </figure>
          <div class="my-1">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i> Go
              Home</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="_messages.DISCOUNT">
          <div class="form-header">
            <a [routerLink]="session_info?.last_routes.new_home || '/'" class="d-lg-none">
              <img loading="lazy" src="assets/logos/logo.png" height="30" class="mb-4">
            </a>
            <img loading="lazy" src="assets/marketing/discount.png" height="200" class="my-4 d-block">
          </div>
          <figure>
            <blockquote class="blockquote">
              <h6>No Current Discount Code Available!</h6>
            </blockquote>
            <figcaption class="blockquote-footer">
              Thank you for choosing <b class="text-secondary">brandqrcodes.com</b>. While we don't have a
              discount code right now, stay tuned for future savings through our newsletter, social media,
              and website updates. Your satisfaction is our priority!
            </figcaption>
          </figure>
          <div class="my-1">
            <a class="btn btn-primary mt-2 btn-sm" [routerLink]="session_info?.last_routes.new_home || '/'"><i
                class="bi bi-house me-2"></i> Go
              Home</a>
          </div>
        </ng-container>
        <p *ngSwitchDefault>
        </p>
      </ng-container>

    </div>
  </div>
</div>