import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import moment from 'moment';
import { Observable, map, takeWhile, timer } from 'rxjs';

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

    seconds = 0;
    days_left = "00"
    hours_left = "00"
    @Input() end_date: string;
    private format = "YYYY-MM-DD HH:mm:ss"
    @Output() reached = new EventEmitter<boolean>();

    timeRemaining$: Observable<number>;
    constructor(
        public functionService: FunctionsService
    ) { }

    ngOnInit(): void {
        this.parseDateFormat()
        this.seconds = this.calculateSecondsUntilExpiry()
        this.timeRemaining$ = timer(0, 1000).pipe(
            map(n => {
                this.calculateDaysUntilExpiry()
                if (n >= this.seconds) {
                    this.end_date = ""
                    this.reached.emit(true)
                }
                return (this.seconds - n) * 1000
            }),
            takeWhile(n => n >= 0),
        )
    }

    private parseDateFormat() {
        if (this.end_date && !this.end_date.includes(" ")) {
            // Doesnt include time
            this.end_date = moment.utc(this.end_date, this.format).endOf("day").format(this.format)
        }
    }

    private calculateSecondsUntilExpiry(): number {
        const expiryDate = moment.utc(this.end_date, this.format);
        const currentDate = moment.utc();
        this.calculateDaysUntilExpiry()
        return expiryDate.diff(currentDate, 'seconds');
    }

    private calculateDaysUntilExpiry() {
        const expiryDate = moment.utc(this.end_date, this.format);
        const currentDate = moment.utc();
        const days_left = expiryDate.diff(currentDate, 'days')
        const hours_left = expiryDate.diff(currentDate, 'hours') % 24
        this.days_left = days_left > 9 ? `${days_left}` : `0${days_left}`
        this.hours_left = hours_left > 9 ? `${hours_left}` : `0${hours_left}`
    }
}
