<div class="preview-wrapper" *ngIf="data?.bigsidebar_data">
    <div class="preview-body">
        <div class="bigsidebar" [ngStyle]="{'background-color': data.bigsidebar_data.colors.primary_color}">
            <div class="bigsidebar-header">
                <i class="bi bi-x-circle closer" [ngStyle]="{'color': data.bigsidebar_data.colors.secondary_color}"></i>
                <img loading="lazy" *ngIf="data.bigsidebar_data.banner.show" [src]="data.bigsidebar_data.banner.url"
                    width="100%">
            </div>
            <div class="bigsidebar-body">
                <h5 [ngStyle]="{'color': data.bigsidebar_data.colors.secondary_color}">
                    {{data.bigsidebar_data.headings.main}}
                </h5>
                <small
                    [ngStyle]="{'color': data.bigsidebar_data.colors.text_color}">{{data.bigsidebar_data.headings.meta}}</small>
                <ng-container *ngIf="data.bigsidebar_data.form.enable && data.bigsidebar_data.form.inputs.length">
                    <div class="mt-2" *ngFor="let input of data.bigsidebar_data.form.inputs">
                        <input [type]="input.type" [required]="input.required" [maxLength]="input.max_length"
                            class="form-control form-control-sm" [placeholder]="input.placeholder">
                    </div>
                    <button class="btn btn-block btn-sm mt-3"
                        [ngStyle]="{'color': '#FFFFFF', 'background-color': data.bigsidebar_data.colors.secondary_color}">{{
                        data.bigsidebar_data.form.cta_text}}</button>
                </ng-container>
                <button class="btn btn-block btn-sm mt-2" *ngIf="data.bigsidebar_data.cta.show"
                    [ngStyle]="{'color': '#FFFFFF', 'background-color': data.bigsidebar_data.colors.secondary_color}">{{data.bigsidebar_data.cta.title
                    === 'Custom' ? data.bigsidebar_data.cta.custom_title :
                    data.bigsidebar_data.cta.title}}</button>

                <div class="text-center mt-2" *ngIf="data.bigsidebar_data.no_thanks.show">
                    <small class="mouse-pointer"
                        [ngStyle]="{'color': data.bigsidebar_data.colors.text_color}">{{data.bigsidebar_data.no_thanks.title}}</small>
                </div>

                <div class="text-center website mt-3" *ngIf="functions.is_valid_url(data.bigsidebar_data.website)">
                    <a class="mouse-pointer" [href]="functions.forceUrl(data.bigsidebar_data.website)" target="_blank"
                        [ngStyle]="{'color': data.bigsidebar_data.colors.secondary_color}">{{data.bigsidebar_data.website}}</a>
                </div>
            </div>
        </div>
    </div>
</div>